import { useRoute } from "@/modules/router/index";
import { useContext } from "react";
import { getResource, RouteDataContext } from "../RouteDataContextProvider";

export const useRouteAdminAsset = () => {
    const { route } = useRoute();
    const context = useContext(RouteDataContext);

    return {
        adminAssetLoadable: getResource(
            "asset",
            "assetId",
            route.params?.assetId,
            context.resources,
        ),
    };
};

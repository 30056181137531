import { ActionButton } from "@/components/DesignSystem/Table/components/ActionButton/ActionButton";
import { useDic } from "@/components/Dic/useDic.hook";
import { useFetchPage } from "@/components/PagableTable/useFetchPage.hook";
import { AssetsTable } from "@/components/Permissions/UsersAssets/AssetsTable.component";
import { assetType } from "@/constants/assets.constants";
import { preferencesTypes } from "@/constants/preferencesTypes.constants";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React, { useCallback, useImperativeHandle } from "react";

const preferencesType = preferencesTypes.USER_ASSETS_INTEGRATIONS_TABLE;

export const IntegrationsTableContainer = React.memo(
    React.forwardRef(function IntegrationsTableContainer(props, ref) {
        const { accountId, onAssetClick, onSubAssetClick, userIds } = props;

        const { permissionsAssetsService } = useDic();
        const [tableProps, { reload }] = useFetchPage(
            (page, size, sort, filter) =>
                permissionsAssetsService.fetchIntegrations({
                    accountId,
                    page,
                    size,
                    sort,
                    filter,
                    userIds,
                }),
            [],
        );

        useImperativeHandle(ref, () => ({
            reload,
        }));

        const actionMenu = useCallback(
            record => (
                <ActionButton
                    wrapperProps={{ onClick: e => e.stopPropagation() }}
                    record={record}
                    items={[
                        {
                            title: t("general.permissions"),
                            onClick: record =>
                                onAssetClick(assetType.integration, record),
                        },
                        {
                            title: t("permissions-table.show-subassets"),
                            onClick: record =>
                                onSubAssetClick(assetType.integration, record),
                        },
                    ]}
                />
            ),
            [onAssetClick, onSubAssetClick],
        );

        return (
            <AssetsTable
                preferencesType={preferencesType}
                actionMenu={onSubAssetClick ? actionMenu : null}
                {...tableProps}
                assetType={assetType.integration}
                defaultSort={{
                    fieldName: "instanceName",
                    sortDir: "ascending",
                }}
                {...props}
            />
        );
    }),
);

IntegrationsTableContainer.propTypes = {
    accountId: PropTypes.number.isRequired,
    userIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    onAssetClick: PropTypes.func.isRequired,
    onSubAssetClick: PropTypes.func.isRequired,
};

import {
    ButtonMenu,
    Forms,
    Modal,
    UnityLayout,
} from "@/components/DesignSystem";
import { getLoadableSelectProps } from "@/components/Packages/PackageTableDefinitionPanel/components/ObjectTypeSelector/EntityNameSelector";
import {
    useAssignUserToAtlassianGroupResource,
    useAvailableGroupOptionsResource,
} from "@/components/Permissions/AccountUserAdminSettings/UserAdminSettingsTable/atlassian/loadables";
import { useSetValidatedInitialValues } from "@/components/hooks/useSetValidatedInitialValues.hook";
import { isLoading } from "@/modules/loadable";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React from "react";

export const AssignUserToAtlassianGroupModal = ({
    projectId,
    record,
    onClose,
    afterSuccess,
}) => {
    const visible = !!record;
    const { atlassianAccountId, username } = record ?? {};
    const assignUserToAtlassianGroupResource =
        useAssignUserToAtlassianGroupResource({
            projectId,
            atlassianAccountId,
            afterSuccess,
        });
    const { formId, handleSubmit, setValues, setTouched } = Forms.useForm({
        onSubmit: ({ values }) =>
            assignUserToAtlassianGroupResource.mutate(values),
    });
    useSetValidatedInitialValues(
        { initialValues: { username }, setValues, setTouched },
        [username],
    );
    const availableGroupOptionsResource = useAvailableGroupOptionsResource({
        canFetch: visible,
        projectId,
        atlassianAccountId,
    });
    const submitDisabled =
        isLoading(assignUserToAtlassianGroupResource) ||
        isLoading(availableGroupOptionsResource);

    return (
        <Modal visible={visible} onClose={onClose}>
            <UnityLayout>
                <UnityLayout.Header
                    size={3}
                    title={t("user-atlassian-groups.assign-modal.title")}
                />
                <UnityLayout.Content padding={[true, true]}>
                    <Forms.Form formId={formId} onSubmit={handleSubmit}>
                        <Forms.FieldGroup width="max" inputWidth="max">
                            <Forms.Fields.Input
                                // required
                                name="username"
                                label={t(
                                    "user-atlassian-groups.form.username.label",
                                )}
                                placeholder=""
                                disabled
                                allowClear={false}
                            />
                            <Forms.Fields.Select
                                required
                                name="groupName"
                                label={t(
                                    "user-atlassian-groups.form.group-name.label",
                                )}
                                allowClear={false}
                                showSearch
                                validator={Forms.pmValidators.isRequired}
                                {...getLoadableSelectProps(
                                    availableGroupOptionsResource.loadable,
                                )}
                            />
                        </Forms.FieldGroup>
                    </Forms.Form>
                </UnityLayout.Content>
                <UnityLayout.Footer
                    actionButtons={
                        <ButtonMenu
                            buttons={[
                                {
                                    label: t("general.save"),
                                    type: "primary",
                                    formId,
                                    disabled: submitDisabled,
                                },
                                {
                                    label: t("general.cancel"),
                                    onClick: onClose,
                                    type: "text",
                                },
                            ]}
                        />
                    }
                />
            </UnityLayout>
        </Modal>
    );
};

AssignUserToAtlassianGroupModal.propTypes = {
    projectId: PropTypes.number.isRequired,
    record: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    afterSuccess: PropTypes.func.isRequired,
};

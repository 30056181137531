import { useDic } from "@/components/Dic/useDic.hook";
import { useFullWidthTableLayout } from "@/components/hooks/useContentLayout.hook";
import { useSetBackButtonAction } from "@/components/PageLayout/useSetBackButtonAction.hook";
import { useSetPageDescription } from "@/components/PageLayout/useSetPageDescription.hook";
import {
    useAccountBusinessRolesResource,
    useAccountUserRightsResource,
    useAccountUserWorkflowRightsResource,
    useSaveAccountRights,
} from "@/components/Permissions/AccountUserAdminSettings/RightsDrawer/loadables";
import { doAfterDrawerClose } from "@/components/Permissions/AccountUserAdminSettings/RightsDrawer/PermissionsDrawer/PermissionsDrawer.container";
import { ENTITY_KIND } from "@/components/Permissions/AccountUserAdminSettings/RightsDrawer/PermissionsDrawer/sources.utils";
import { getDescription } from "@/components/Permissions/UsersAssets.admin.page";
import { UsersAssetsComponent } from "@/components/Permissions/UsersAssets/UsersAssets.component";
import { useRouteUserIds } from "@/components/Permissions/utils";
import { useRouteParams } from "@/modules/router";
import React, { useCallback, useRef } from "react";

export const UsersAssetsAccountPage = () => {
    const {
        locationRouterService,
        accountAppLocations: { permissionsAccountUsersLocation },
    } = useDic();
    const { accountId } = useRouteParams();
    const userIds = useRouteUserIds();

    useFullWidthTableLayout();
    useSetPageDescription(getDescription(userIds));

    const navigateToAccountsList = useCallback(
        () =>
            locationRouterService.navigate(permissionsAccountUsersLocation, {
                accountId,
            }),
        [accountId, locationRouterService, permissionsAccountUsersLocation],
    );
    useSetBackButtonAction(navigateToAccountsList);

    const rightsResource = useAccountUserRightsResource({
        accountId,
        userIds,
        canFetch: !!userIds?.length,
    });
    const workflowRightsResource = useAccountUserWorkflowRightsResource({
        accountId,
        userIds,
        canFetch: !!userIds?.length,
    });

    const assetsComponentRef = useRef();

    const afterSave = useCallback(() => {
        assetsComponentRef.current.hidePermissionsDrawer();
        doAfterDrawerClose(() => {
            rightsResource.reload();
            assetsComponentRef.current.reload();
        });
    }, [rightsResource]);

    const saveRightsResource = useSaveAccountRights({
        accountId,
        afterSave,
    });

    const businessRolesResource = useAccountBusinessRolesResource({
        accountId,
    });

    return (
        <>
            <UsersAssetsComponent
                ref={assetsComponentRef}
                accountId={accountId}
                entityKind={ENTITY_KIND.USER}
                selectedEntities={userIds}
                rightsResource={rightsResource}
                businessRolesResource={businessRolesResource}
                workflowRightsResource={workflowRightsResource}
                saveRightsResource={saveRightsResource}
                // onClose={noop}
                // visible={visible}
                // displaySuperAssets={true} //displaySuperAssets}
            />
        </>
    );
};

import { DebugLogs } from "@/components/EventLogs/DebugLogs";
import { RunHistoryLogs } from "@/components/EventLogs/RunHistoryLogs";
import { useFullWidthTableLayout } from "@/components/hooks/useContentLayout.hook";
import {
    HeaderTabs,
    useHeaderTabs,
} from "@/components/PageLayout/components/HeaderTabs.component";
import { useHideBackButton } from "@/components/PageLayout/useHideBackButton.hook";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import { t } from "@/translations";
import { buildQueryPart } from "@/utils/elk";
import PropTypes from "prop-types";
import React, { useReducer } from "react";

export const EO_LOGS_TABS = {
    RUN_HISTORY: "RUN_HISTORY",
    DEBUG_LOGS: "DEBUG_LOGS",
};

export const EO_LOGS_ENTITY_TYPE = {
    WORKFLOW: "WORKFLOW",
    SCHEDULER: "SCHEDULER",
    SIMPLE_EVENT_LISTENER: "SIMPLE_EVENT_LISTENER",
    LISTENER: "LISTENER", // same as SIMPLE_EVENT_LISTENER but just for logs https://pricefx.atlassian.net/browse/PFIM-8210?focusedCommentId=717248
};

export const EventLogsPage = () => {
    const { accountId, entityType, entityId } = useAccountAppParams();
    const { activeKey } = useHeaderTabs();
    const [reloadToken, reloadAll] = useReducer(s => ++s, 0);
    const initialQuery = [
        buildQueryPart("entityType.keyword", entityType),
        buildQueryPart("entityId", parseInt(entityId, 10)),
    ]
        .filter(Boolean)
        .join(" AND ");

    useFullWidthTableLayout();
    useHideBackButton();

    return (
        <HeaderTabs defaultActiveKey={EO_LOGS_TABS.RUN_HISTORY}>
            <HeaderTabs.TabPane
                tab={t("event-wf.logs.tab.run-history")}
                key={EO_LOGS_TABS.RUN_HISTORY}
            >
                <RunHistoryLogs
                    accountId={accountId}
                    initialQuery={initialQuery}
                    visible={activeKey === EO_LOGS_TABS.RUN_HISTORY}
                    reloadToken={reloadToken}
                    reloadAll={reloadAll}
                />
            </HeaderTabs.TabPane>
            <HeaderTabs.TabPane
                tab={t("event-wf.logs.tab.debug-logs")}
                key={EO_LOGS_TABS.DEBUG_LOGS}
            >
                <DebugLogs
                    accountId={accountId}
                    initialQuery={initialQuery}
                    visible={activeKey === EO_LOGS_TABS.DEBUG_LOGS}
                    reloadToken={reloadToken}
                    reloadAll={reloadAll}
                />
            </HeaderTabs.TabPane>
        </HeaderTabs>
    );
};

EventLogsPage.propTypes = {
    accountId: PropTypes.number.isRequired,
};

import { HEADER_WITH_BREADCRUMB_WITH_TAB_HEIGHT } from "@/components/DesignSystem/Table/constants";
import { useSyncRowSelection } from "@/components/DesignSystem/Table/hooks/useSyncRowSelection.hook";
import { useFetchPagePropTypes } from "@/components/PagableTable/useFetchPage.hook";
import { createColumns } from "@/components/Permissions/UsersAssets/AssetsTable.columns";
import { TableWithPreferencesManagement } from "@/components/TableWithPreferences/TableWithPreferencesManagement.container";
import { logger } from "@/modules/logger";
import PropTypes from "prop-types";
import React from "react";

export const createProjectIdSubsetFilter = ({ accountId }) => ({
    criteria: [
        {
            fieldName: "project.id",
            operator: "equals",
            value: accountId,
        },
    ],
    operator: "and",
    _constructor: "AdvancedCriteria",
});
export const AssetsTable = ({
    preferencesType,
    page = {},
    fetchPage,
    loading,
    onAssetClick,
    assetType,
    defaultSort = {
        fieldName: "name",
        sortDir: "ascending",
    },
    actionMenu,
    selectionContextMenu,
    selectionChange,
    selectedAssets,
    // hasAssetRights,
    // hasRights,
    // setHasRights,
    // fetchParams,
    testId = "admin-assets-table-" + assetType,
    readOnly = false,
    tableProps,
    ...rest
}) => {
    const rowSelection = useSyncRowSelection(
        selectedAssets[assetType],
        selectionChange,
        [assetType],
        _record => readOnly,
    );

    logger.debug({
        logGroupKey: ["PERMISSIONS", "AdminAssetsTable"],
        color: "deepskyblue",
        data: {
            preferencesType,
            page,
            fetchPage,
            loading,
            onAssetClick,
            assetType,
            defaultSort,
            actionMenu,
            selectionContextMenu,
            selectionChange,
            selectedAssets,
            rest,
            tableProps,
        },
    });

    const columns = createColumns(onAssetClick);

    return (
        <TableWithPreferencesManagement
            fixed
            restHeight={HEADER_WITH_BREADCRUMB_WITH_TAB_HEIGHT}
            rowKey="id"
            datasetSlicing="server"
            actionMenu={actionMenu}
            preferencesType={preferencesType}
            columns={columns[assetType]}
            defaultSort={defaultSort}
            page={page}
            fetchPage={fetchPage}
            loading={loading}
            rowSelection={rowSelection}
            selectionContextMenu={selectionContextMenu}
            dataTest={testId}
            {...tableProps}
            {...rest}
        />
    );
};

AssetsTable.propTypes = {
    ...useFetchPagePropTypes,
    onAssetClick: PropTypes.func,
    selectionChange: PropTypes.func.isRequired,
    selectedAssets: PropTypes.shape({
        project: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
        integration: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
        partition: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
    }).isRequired,
    assetType: PropTypes.oneOf(["project", "integration", "partition"])
        .isRequired,
    defaultSort: PropTypes.object,
};

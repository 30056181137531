import {
    Button,
    Container,
    Gap,
    H4,
    Tabs,
    Text,
} from "@/components/DesignSystem";
import { useSyncRowSelection } from "@/components/DesignSystem/Table/hooks/useSyncRowSelection.hook";
import styles from "@/components/Permissions/AccountUserAdminSettings/GroupAdminSettings/GroupAdminSettings.style.less";
import { columns } from "@/components/Permissions/AccountUserAdminSettings/GroupAdminSettings/GroupDrawerInfo.columns";
import { TableLocalFiltered } from "@/components/TableLocalFiltered";
import { t, T } from "@/translations";
import cx from "classnames";
import PropTypes from "prop-types";
import React, { useMemo, useState } from "react";

const TABS = {
    GROUPS: "groups",
};

export const GroupDrawerInfoComponent = ({
    name,
    description,
    users,
    onRemoveUser,
    removeUsersFromGroup,
    onAddUser,
}) => {
    const [selectedUsers, setSelectedUsers] = useState([]);
    const selectionContextMenu = useMemo(
        () => [
            {
                label: t("general.delete-users"),
                onClick: () => removeUsersFromGroup(selectedUsers),
            },
        ],
        [selectedUsers, removeUsersFromGroup],
    );
    const rowSelection = useSyncRowSelection(selectedUsers, setSelectedUsers);
    return (
        <Tabs defaultActiveKey={TABS.GROUPS}>
            <Tabs.TabPane
                tab={<T id="account-admin-groups-list.drawer.title" />}
                key={TABS.GROUPS}
            >
                <Container type="padding" size="sm">
                    <H4>{name}</H4>
                    <Text>{description}</Text>
                    <Gap size="huge" />
                    <TableLocalFiltered
                        className={cx(
                            styles.groupTable,
                            styles.negativeMarginSm,
                        )}
                        columns={columns(onRemoveUser)}
                        dataSource={users}
                        pagination={false}
                        rowKey="id"
                        rowSelection={rowSelection}
                        selectionContextMenu={selectionContextMenu}
                    />
                    <Button
                        onClick={onAddUser}
                        label={<T id="general.add-user" />}
                    />
                </Container>
            </Tabs.TabPane>
        </Tabs>
    );
};

GroupDrawerInfoComponent.propTypes = {
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    users: PropTypes.array.isRequired,
    onRemoveUser: PropTypes.func.isRequired,
    removeUsersFromGroup: PropTypes.func.isRequired,
    onAddUser: PropTypes.func.isRequired,
};

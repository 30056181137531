import { useDic } from "@/components/Dic/useDic.hook";
import { AssetsTable } from "@/components/Permissions/UsersAssets/AssetsTable.component";
import { assetType } from "@/constants/assets.constants";
import { preferencesTypes } from "@/constants/preferencesTypes.constants";
import { useQueryLoadable } from "@/modules/loadable";
import { getData } from "@/services/utils";
import PropTypes from "prop-types";
import React, { useImperativeHandle } from "react";

const preferencesType = preferencesTypes.USER_ASSETS_WORKFLOWS_TABLE;

export const useFetchWorkflowsQuery = ({ accountId, userIds }) => {
    const { permissionsAssetsService } = useDic();

    return useQueryLoadable(
        async () =>
            permissionsAssetsService
                .fetchWorkflows({
                    accountId,
                    userIds,
                })
                .then(getData),
        [accountId, permissionsAssetsService, userIds],
    );
};
export const WorkflowTable = React.memo(
    React.forwardRef((props, ref) => {
        const { accountId, userIds } = props;

        const workflowsQuery = useFetchWorkflowsQuery({ accountId, userIds });
        const dataSource = workflowsQuery.loadable.valueMaybe() || [];

        useImperativeHandle(ref, () => ({
            reload: workflowsQuery.reload,
        }));

        return (
            <AssetsTable
                preferencesType={preferencesType}
                assetType={assetType.workflow}
                defaultSort={{
                    fieldName: "workflowName",
                    sortDir: "ascending",
                }}
                datasetSlicing="local"
                dataSource={dataSource}
                {...props}
            />
        );
    }),
);

WorkflowTable.propTypes = {
    accountId: PropTypes.number.isRequired,
    userIds: PropTypes.arrayOf(PropTypes.number).isRequired,
};

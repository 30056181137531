import { defaultVendors } from "@/components/Mappers/MapperTableWithCustomization/MapperTableWithCustomization";
import { cloneDeep } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { validateDefinition } from "../../form/mapperValidation";

export const useValidatedMapperState = ({
    vendors = defaultVendors,
    entityName,
    entityType,
    initMapper,
    businessKey,
    inputOptionsMaybe,
    outputOptionsMaybe,
    tableData,
    stringFields,
}) => {
    const [mapper, setMapper] = useState(initMapper);
    const errors = useMemo(
        () =>
            validateDefinition(
                mapper || [],
                entityType,
                entityName,
                businessKey,
                tableData,
                inputOptionsMaybe,
                outputOptionsMaybe,
                vendors,
                stringFields,
            ),
        [
            mapper,
            entityType,
            entityName,
            businessKey,
            tableData,
            inputOptionsMaybe,
            outputOptionsMaybe,
            vendors,
            stringFields,
        ],
    );

    const initMapperRef = useRef(initMapper);
    useEffect(() => {
        if (initMapperRef.current !== initMapper) {
            console.log("useValidatedMapperState reinicializing", {
                "current mapper - to be overriden": cloneDeep(mapper),
                "previously initialized with": cloneDeep(initMapperRef.current),
                "new init mapper - to be used": cloneDeep(initMapper),
            });
            setMapper(initMapper);
            initMapperRef.current = initMapper;
        }
    }, [initMapper, mapper]);

    return {
        mapper, // actually maybeMapper
        setMapper,
        errors,
    };
};

import { useHideBackButton } from "@/components/PageLayout/useHideBackButton.hook";
import { AccountAssetAdminSettingsComponent } from "@/components/Permissions/AccountAssetAdminSettings/AccountAssetAdminSettings.component";
import { useFullWidthTableLayout } from "@/components/hooks/useContentLayout.hook";
import React from "react";

export function AssetGroupsAccountPage() {
    useFullWidthTableLayout();
    useHideBackButton();

    return <AccountAssetAdminSettingsComponent />;
}

import { useDic } from "@/components/Dic/useDic.hook";
import { useFullWidthTableLayout } from "@/components/hooks/useContentLayout.hook";
import { useSetBackButtonAction } from "@/components/PageLayout/useSetBackButtonAction.hook";
import {
    useAccountBusinessRolesResource,
    useAccountUserRightsResource,
    useAccountUserWorkflowRightsResource,
    useSaveAccountRights,
} from "@/components/Permissions/AccountUserAdminSettings/RightsDrawer/loadables";
import { doAfterDrawerClose } from "@/components/Permissions/AccountUserAdminSettings/RightsDrawer/PermissionsDrawer/PermissionsDrawer.container";
import { ENTITY_KIND } from "@/components/Permissions/AccountUserAdminSettings/RightsDrawer/PermissionsDrawer/sources.utils";
import { SubAssetsTable } from "@/components/Permissions/UsersAssets/SubAssetsTable.component";
import {
    joinUserIdsToUrlParam,
    useRouteUserIds,
} from "@/components/Permissions/utils";
import { useRouteParams } from "@/modules/router";
import React, { useCallback, useRef } from "react";

export const SubAssetsAccountPage = () => {
    const {
        locationRouterService,
        accountAppLocations: { permissionsAccountUsersAssetsLocation },
    } = useDic();
    const { accountId, assetId, assetType } = useRouteParams();
    const userIds = useRouteUserIds();

    useFullWidthTableLayout();

    const navigateToAssetsList = useCallback(
        () =>
            locationRouterService.navigate(
                permissionsAccountUsersAssetsLocation,
                {
                    userIds: joinUserIdsToUrlParam(userIds),
                    accountId,
                },
            ),
        [
            locationRouterService,
            permissionsAccountUsersAssetsLocation,
            userIds,
            accountId,
        ],
    );
    useSetBackButtonAction(navigateToAssetsList);

    const rightsResource = useAccountUserRightsResource({
        accountId,
        userIds,
        canFetch: !!userIds?.length,
    });
    const workflowRightsResource = useAccountUserWorkflowRightsResource({
        accountId,
        userIds,
        canFetch: !!userIds?.length,
    });

    const assetsComponentRef = useRef();

    const afterSave = useCallback(() => {
        assetsComponentRef.current.hidePermissionsDrawer();
        doAfterDrawerClose(() => {
            rightsResource.reload();
            assetsComponentRef.current.reload();
        });
    }, [rightsResource]);

    const saveRightsResource = useSaveAccountRights({
        accountId,
        afterSave,
    });

    const businessRolesResource = useAccountBusinessRolesResource({
        accountId,
    });

    return (
        <>
            <SubAssetsTable
                ref={assetsComponentRef}
                accountId={accountId}
                assetType={assetType}
                assetId={assetId}
                entityKind={ENTITY_KIND.USER}
                selectedEntities={userIds}
                rightsResource={rightsResource}
                businessRolesResource={businessRolesResource}
                workflowRightsResource={workflowRightsResource}
                saveRightsResource={saveRightsResource}
                // onClose={noop}
                // visible={visible}
                // displaySuperAssets={true} //displaySuperAssets}
            />
        </>
    );
};

import { Drawer, Tabs } from "@/components/DesignSystem";
import {
    LoadableRenderer,
    MutationResourcePropType,
    QueryResourcePropType,
    useComposeLoadablesMemoized,
} from "@/modules/loadable";
import { T } from "@/translations";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { useAdminAssetRolesResource } from "../../../../administrationApp/pages/AssetRolesPage/loadables";
import { useAdminBusinessRolesResource } from "../../../../administrationApp/pages/BusinessRolesPage/loadables";
import { AdminAssetsTableContainer } from "./AdminAssetsTable/AdminAssetsTable.container";
import { AssetRolesTable } from "./AssetRolesTable/AssetRolesTable.component";
import { useAssetRolesSelection } from "./hooks/useAssetRolesSelection.hook";
import { useAssetsSelection } from "./hooks/useAssetsSelection.hook";
import { useSuperAssetsSelection } from "./hooks/useSuperAssetsSelection.hook";
import { useGroupedPermissionsResource } from "./loadables";
import { PermissionsDrawerContainer } from "./PermissionsDrawer/PermissionsDrawer.container";
import {
    ADMIN_KIND,
    ASSET_KIND,
    assetMatch,
    assetRoleMatch,
    ENTITY_KIND,
    superAssetMatch,
} from "./PermissionsDrawer/sources.utils";
import { SuperAssetsTable } from "./SuperAssetsTable/SuperAssetsTable.component";

export const TABS = {
    ASSETS: ASSET_KIND.ASSET,
    ASSET_ROLES: ASSET_KIND.ASSET_ROLE,
    SUPER_ASSETS: ASSET_KIND.SUPER_ASSET,
};

export const VIEWS = {
    DEFAULT: null,
    PERMISSIONS: "PERMISSIONS",
};

export const AdminRightsDrawer = React.memo(function AdminRightsDrawer({
    assetsWithRightsFetchParams,
    entityKind,
    selectedEntities,
    rightsResource,
    workflowRightsResource,
    saveRightsResource,
    onClose,
    visible = true,
    displaySuperAssets = false,
}) {
    const [tab, setTab] = useState(TABS.ASSETS);
    const [view, setView] = useState(VIEWS.DEFAULT);
    const showPermissions = useCallback(
        () => setView(VIEWS.PERMISSIONS),
        [setView],
    );
    const {
        selectedAssets,
        onAssetsSelection,
        onAssetClick,
        noAssetsSelected,
        resetAssets,
    } = useAssetsSelection({ afterClick: showPermissions });
    const {
        selectedSuperAssets,
        onSuperAssetsSelection,
        onSuperAssetClick,
        noSuperAssetsSelected,
        resetSuperAssets,
    } = useSuperAssetsSelection(showPermissions);
    const {
        selectedAssetRoles,
        selectedAssetRoleIds,
        onAssetRolesSelection,
        onAssetRoleClick,
        noAssetRolesSelected,
        resetAssetRoles,
    } = useAssetRolesSelection(showPermissions);
    const [onlyAssetsWithRightsVisible, setOnlyAssetsWithRightsVisible] =
        useState(false);
    const onPermissionsDrawerClose = useCallback(
        () => setView(VIEWS.DEFAULT),
        [setView],
    );
    useEffect(() => {
        resetAssets();
        resetAssetRoles();
        resetSuperAssets();
    }, [
        visible,
        tab,
        resetAssets,
        onlyAssetsWithRightsVisible,
        resetSuperAssets,
        resetAssetRoles,
    ]);
    const onCloseAll = useCallback(() => {
        setView(VIEWS.DEFAULT);
        onClose();
    }, [onClose]);
    useEffect(() => () => setView(VIEWS.DEFAULT), [visible]);

    const groupedPermissionsResource = useGroupedPermissionsResource();
    const businessRolesResource = useAdminBusinessRolesResource();
    const assetRolesResource = useAdminAssetRolesResource();
    const loadables = useComposeLoadablesMemoized([
        groupedPermissionsResource.loadable,
        businessRolesResource.loadable,
        assetRolesResource.loadable,
        rightsResource.loadable,
        workflowRightsResource.loadable,
    ]);

    return (
        <Drawer
            width={640}
            cancelButtonProps={{ onClick: onClose }}
            okButtonProps={{
                onClick: showPermissions,
                disabled:
                    tab === TABS.ASSETS
                        ? noAssetsSelected
                        : tab === TABS.SUPER_ASSETS
                        ? noSuperAssetsSelected
                        : tab === TABS.ASSET_ROLES
                        ? noAssetRolesSelected
                        : true,
            }}
            onClose={onClose}
            visible={visible}
        >
            <LoadableRenderer
                loadable={loadables}
                hasValue={([
                    groupedPermissions,
                    businessRoles,
                    assetRoles,
                    rights,
                    workflowRights,
                    saveRights, // TODO
                ]) => (
                    <>
                        {/* <Tabs defaultActiveKey={tab} onChange={setTab}> */}
                        {/* TODO: This is workaround to temporarily fix issue https://pricefx.atlassian.net/browse/PFIM-6320 (2.) */}
                        <Tabs defaultActiveKey={null} onChange={setTab}>
                            <Tabs.TabPane
                                tab={<T id="global.assets" />}
                                key={TABS.ASSETS}
                            >
                                <AdminAssetsTableContainer
                                    onAssetClick={onAssetClick}
                                    selectionChange={onAssetsSelection}
                                    selectedAssets={selectedAssets}
                                    hasRights={onlyAssetsWithRightsVisible}
                                    setHasRights={
                                        setOnlyAssetsWithRightsVisible
                                    }
                                    assetsWithRightsFetchParams={
                                        assetsWithRightsFetchParams
                                    }
                                    hasAssetRights={(assetId, assetType) =>
                                        rights?.some(
                                            assetMatch({
                                                assetId,
                                                assetType,
                                            }),
                                        )
                                    }
                                />
                            </Tabs.TabPane>
                            <Tabs.TabPane
                                tab={<T id="global.asset-groups" />}
                                key={TABS.ASSET_ROLES}
                            >
                                <AssetRolesTable
                                    assetRoles={assetRoles}
                                    onAssetRoleClick={onAssetRoleClick}
                                    selectionChange={onAssetRolesSelection}
                                    selectedAssetRoleIds={selectedAssetRoleIds}
                                    hasRights={assetRoleId =>
                                        rights?.some(
                                            assetRoleMatch(assetRoleId),
                                        )
                                    }
                                />
                            </Tabs.TabPane>
                            {displaySuperAssets && (
                                <Tabs.TabPane
                                    tab={<T id="global.super-assets" />}
                                    key={TABS.SUPER_ASSETS}
                                >
                                    <SuperAssetsTable
                                        onSuperAssetClick={onSuperAssetClick}
                                        selectionChange={onSuperAssetsSelection}
                                        selectedSuperAssets={
                                            selectedSuperAssets
                                        }
                                        hasAnyRights={superAssetType =>
                                            rights?.some(
                                                superAssetMatch(superAssetType),
                                            )
                                        }
                                    />
                                </Tabs.TabPane>
                            )}
                        </Tabs>
                        <PermissionsDrawerContainer
                            selectedAssets={
                                tab === TABS.ASSETS
                                    ? selectedAssets
                                    : tab === TABS.ASSET_ROLES
                                    ? selectedAssetRoles
                                    : tab === TABS.SUPER_ASSETS
                                    ? selectedSuperAssets
                                    : null
                            }
                            selectedEntities={selectedEntities}
                            adminKind={ADMIN_KIND.GENERAL}
                            entityKind={entityKind}
                            assetKind={tab}
                            rights={rights}
                            workflowRights={workflowRights}
                            groupedPermissions={groupedPermissions}
                            onCancel={onCloseAll}
                            onClose={onPermissionsDrawerClose}
                            onSave={saveRightsResource.mutate}
                            visible={Boolean(view === VIEWS.PERMISSIONS)}
                            businessRoles={businessRoles}
                        />
                    </>
                )}
            />
        </Drawer>
    );
});

AdminRightsDrawer.propTypes = {
    assetsWithRightsFetchParams: PropTypes.shape({
        userIds: PropTypes.array,
        groupNames: PropTypes.array,
    }),
    rightsResource: QueryResourcePropType(PropTypes.array.isRequired),
    workflowRightsResource: QueryResourcePropType(PropTypes.array.isRequired),
    saveRightsResource: MutationResourcePropType(),
    entityKind: PropTypes.oneOf(Object.values(ENTITY_KIND)).isRequired,
    selectedEntities: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
        PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    ]),
    onClose: PropTypes.func.isRequired,
    visible: PropTypes.bool.isRequired,
    displaySuperAssets: PropTypes.bool,
};

import { useExportMutation } from "@/components/DesignSystem/Table/hooks/useExportCsv.hook";
import { useHideBackButton } from "@/components/PageLayout/useHideBackButton.hook";
import { useBreadcrumbButtons } from "@/components/hooks/useBreadcrumbButtons.hook";
import { useFullWidthTableLayout } from "@/components/hooks/useContentLayout.hook";
import { useVisibility } from "@/components/hooks/useVisibility.hook";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import React, { useCallback, useMemo, useRef } from "react";

import { InviteUserModalComponent } from "@/components/Permissions/AccountUserAdminSettings/UserAdminSettings/InviteUserModal.component";
import {
    useDeleteAccountUserResource,
    useInviteAccountUserResource,
} from "@/components/Permissions/AccountUserAdminSettings/UserAdminSettings/loadables";
import { UserAdminSettingsTableContainer } from "@/components/Permissions/AccountUserAdminSettings/UserAdminSettingsTable/UserAdminSettingsTable.container";

export const useGetExportAccessReportButton = ({
    exportUrl = `/api/projects/{projectId}/rights/users/export`,
    fileName = "access-report.csv",
    permissions,
}) => {
    const filtersRef = useRef();
    const { mutate: exportAccessReport } = useExportMutation({ fileName });
    const exportAccessReportButton = useMemo(
        () => ({
            label: "general.export-access-report",
            type: "secondary",
            permissions,
            onClick: () => {
                exportAccessReport({
                    exportUrl:
                        filtersRef.current.getExportUrlWithSort(exportUrl),
                    filters: filtersRef.current.filters || null,
                    noColumns: true,
                });
            },
        }),
        [exportAccessReport, exportUrl, permissions],
    );

    return {
        filtersRef,
        exportAccessReportButton,
    };
};

export const UsersAccountPage = () => {
    useFullWidthTableLayout();
    useHideBackButton();

    const { accountId } = useAccountAppParams();
    const inviteModal = useVisibility(false);
    const usersTableRef = useRef();

    const afterUsersMutated = useCallback(() => {
        inviteModal.hide();
        usersTableRef.current.reload();
    }, [inviteModal]);
    const inviteAccountUserResource = useInviteAccountUserResource({
        accountId,
        afterSuccess: afterUsersMutated,
    });
    const deleteAccountUserResource = useDeleteAccountUserResource({
        accountId,
        afterSuccess: afterUsersMutated,
    });

    const { filtersRef, exportAccessReportButton } =
        useGetExportAccessReportButton({
            exportUrl: `/api/projects/${accountId}/rights/users/export`,
        });
    useBreadcrumbButtons(
        () => [
            {
                label: "account-admin-users-list.button.invite-user",
                type: "primary",
                onClick: inviteModal.show,
            },
            exportAccessReportButton,
        ],
        [exportAccessReportButton, inviteModal.show],
    );

    return (
        <>
            <UserAdminSettingsTableContainer
                ref={usersTableRef}
                accountId={accountId}
                onUserDelete={deleteAccountUserResource.mutate}
                filtersRef={filtersRef}
            />
            <InviteUserModalComponent
                visible={inviteModal.visible}
                onCancel={inviteModal.hide}
                onOk={inviteAccountUserResource.mutate}
            />
        </>
    );
};

UsersAccountPage.propTypes = {};

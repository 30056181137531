import { isEmpty } from "lodash";
import { useMemo } from "react";
import { ParsedType } from "../Packages/PackageTableDefinitionPanel/constants";
import { TABS_VALUE_TYPES } from "./constants";

const defaultHideTabs = [];

export const useVisibleTabs = ({
    numberFields,
    stringFields,
    inputOptions,
    initialValues,
    hideTabs = defaultHideTabs,
}) => {
    const visibleTabs = useMemo(() => {
        if (!initialValues) return [];

        const outputType = initialValues.outputType || ParsedType.STRING;
        return [
            !isEmpty(inputOptions) && TABS_VALUE_TYPES.FIELD,
            !isEmpty(inputOptions) && TABS_VALUE_TYPES.EXPERT_OPTION,
            !isEmpty(numberFields) &&
                (outputType === ParsedType.NUMBER ||
                    outputType === ParsedType.INTEGER) &&
                TABS_VALUE_TYPES.FORMULA,
            !isEmpty(stringFields) &&
                outputType === ParsedType.STRING &&
                TABS_VALUE_TYPES.COMPOSED,
            TABS_VALUE_TYPES.GROOVY,
        ]
            .filter(Boolean)
            .filter(tab => !hideTabs.includes(tab));
    }, [numberFields, stringFields, inputOptions, initialValues, hideTabs]);

    console.log("[useVisibleTabs]", {
        numberFields,
        stringFields,
        inputOptions,
        initialValues,
        hideTabs,
        visibleTabs,
    });

    return visibleTabs;
};

import { idleLoadableProps } from "@/components/DataUploads/Wizard/loadables";
import { Forms } from "@/components/DesignSystem";
import { SELECT_GET_VALUE } from "@/components/DesignSystem/Forms/fields";
import {
    SourceFields,
    useLoadableOptionsValidatorMaybe,
} from "@/components/EventWorkflows/ListenerStepForm/SourceEventModal";
import {
    useEOTEntitiesOptionsQuery,
    useEOTypesOptionsQuery,
} from "@/components/EventWorkflows/loadables";
import { getLoadableSelectProps } from "@/components/Packages/PackageTableDefinitionPanel/components/ObjectTypeSelector/EntityNameSelector";
import { t } from "@/translations";
import React from "react";

// TODO: rename to Destination Fields
export const DestinationFields = ({
    accountId,
    isSingleSource,
    singleSource = {},
    destinationTypeName = DestinationFields.fieldNames.destinationType,
    destinationIdName = DestinationFields.fieldNames.destinationId,
    disabled,
}) => {
    const eoTypesQuery = useEOTypesOptionsQuery({ accountId });
    const destinationType = DestinationFields.useDestinationType({
        destinationTypeName,
    });
    const eotTargetEntitiesQuery = useEOTEntitiesOptionsQuery({
        accountId,
        eoType: destinationType,
        canFetch: !!destinationType,
    });
    const typeValidator = useLoadableOptionsValidatorMaybe({
        isRequired: true,
        loadable: eoTypesQuery.loadable,
    });
    const idValidator = useLoadableOptionsValidatorMaybe({
        isRequired: true,
        loadable: eotTargetEntitiesQuery.loadable,
    });

    if (isSingleSource) {
        return (
            <>
                <Forms.Fields.ReadOnlyValue
                    initialValue={
                        singleSource[SourceFields.fieldNames.sourceType]
                    }
                    name={destinationTypeName}
                    label={t("event-wf.listener-form.destinationType.label")}
                    {...getLoadableSelectProps(eoTypesQuery.loadable)}
                    getValue={SELECT_GET_VALUE}
                />
                <Forms.Fields.ReadOnlyValue
                    initialValue={
                        singleSource[SourceFields.fieldNames.sourceId]
                    }
                    name={destinationIdName}
                    label={t("event-wf.listener-form.destinationId.label")}
                    {...getLoadableSelectProps(eotTargetEntitiesQuery.loadable)}
                    getValue={SELECT_GET_VALUE}
                />
            </>
        );
    }

    return (
        <>
            <Forms.Fields.Select
                initialValue={singleSource[SourceFields.fieldNames.sourceType]}
                disabled={disabled}
                name={destinationTypeName}
                label={t("event-wf.listener-form.destinationType.label")}
                required
                validator={Forms.validators.failOnFirst([
                    Forms.pmValidators.isRequired,
                    typeValidator,
                ])}
                {...getLoadableSelectProps(eoTypesQuery.loadable)}
            />
            <Forms.Fields.Select
                initialValue={singleSource[SourceFields.fieldNames.sourceId]}
                disabled={disabled}
                name={destinationIdName}
                label={t("event-wf.listener-form.destinationId.label")}
                required
                validator={Forms.validators.failOnFirst([
                    Forms.pmValidators.isRequired,
                    idValidator,
                ])}
                {...getLoadableSelectProps(eotTargetEntitiesQuery.loadable)}
                {...idleLoadableProps(
                    eotTargetEntitiesQuery.loadable,
                    "Please, select type",
                )}
            />
        </>
    );
};
DestinationFields.fieldNames = {
    destinationType: "destinationType",
    destinationId: "destinationId",
};
DestinationFields.useDestinationType = ({
    formId,
    destinationTypeName = DestinationFields.fieldNames.destinationType,
} = {}) => {
    return Forms.useFieldValue({
        formId: Forms.useFormId(formId),
        name: destinationTypeName,
    });
};
DestinationFields.useDestinationId = ({
    formId,
    destinationIdName = DestinationFields.fieldNames.destinationId,
} = {}) => {
    return Forms.useFieldValue({
        formId: Forms.useFormId(formId),
        name: destinationIdName,
    });
};

import { useConvertersQuery } from "@/components/CalculatedFieldTable/loadables";
import {
    ButtonMenu,
    Gap,
    Label,
    Link,
    Modal,
    Skeleton,
    UnityLayout,
} from "@/components/DesignSystem";
import { MAPPER_DIRECTION } from "@/components/ISVMapping/steps/General.step";
import {
    CellPreview,
    isPreviewDisabled,
} from "@/components/Mappers/MapperTableWithCustomization/components/CellPreview.component";
import { getExampleResult } from "@/components/Mappers/MapperTableWithCustomization/components/MapperRow.component";
import { InputType } from "@/components/Packages/PackageTableDefinitionPanel/constants";
import { T, t } from "@/translations";
import { map, pick, prop } from "lodash/fp";
import PropTypes from "prop-types";
import React, { useCallback, useMemo } from "react";
import { ConverterExpressionComponent } from "../ConvertorExpression/ConverterExpression.component";
import {
    FieldCustomizationTabsComponent,
    useFieldCustomizationTabsState,
} from "./FieldCustomizationTabs.component";
import { useGetTableExampleDataMap } from "./useGetTableExampleDataMap";
import { useParseTableExampleData } from "./useParseTableExampleData";

export const mapInputTypeToUsedEditorTab = inputType => {
    switch (inputType) {
        case InputType.BODY:
            return t("formula-editor.tab.converter");
        case InputType.COMPOSED:
            return t("formula-editor.tab.multiple-field");
        case InputType.GROOVY:
            return t("formula-editor.tab.groovy");
        case InputType.FORMULA:
            return t("formula-editor.tab.formula");
        case InputType.SIMPLE:
        case InputType.CONSTANT:
        case InputType.HEADER:
        case InputType.PROPERTY:
            return t("formula-editor.tab.expert-options");
        default:
            return undefined;
    }
};

export const isTabStateInvalid = activeTabState =>
    activeTabState.isValid === false || activeTabState.error;

export const FieldCustomizationModal = ({
    visible,
    onSave,
    onCancel,
    tableExampleData,
    initialValues,
    withConverterExpression,
    inputOptions: passedInputOptions,
    hideTabs,
    parserConfig,
    previewDisabled,
    direction = MAPPER_DIRECTION.UPLOAD,
    withComposedFromInputOptions,
}) => {
    // TODO: lift up to parent component?
    const { inputOptions, numberFields, stringFields } =
        useParseTableExampleData({
            inputOptions: passedInputOptions,
            tableExampleData,
        });
    const tableExampleDataMap = useGetTableExampleDataMap({
        tableExampleData,
    });
    const inputStringFields = useMemo(
        () => map(prop("value"), inputOptions),
        [inputOptions],
    );
    const {
        activeTabState,
        setActiveTabConverterState,
        ...fieldCustomizationTabs
    } = useFieldCustomizationTabsState({
        hideTabs,
        initialValues,
        inputOptions,
        numberFields,
        stringFields: withComposedFromInputOptions
            ? inputStringFields
            : stringFields,
    });

    const field = useMemo(() => {
        const pickedProps = !withConverterExpression
            ? ["input", "inputType", "formulaMapping"]
            : [
                  "input",
                  "inputType",
                  "formulaMapping",
                  "converterExpression",
                  "converterExpressionReadOnly",
              ];
        return pick(pickedProps, activeTabState);
    }, [activeTabState, withConverterExpression]);

    const onSaveClick = useCallback(
        () => onSave({ ...field, id: initialValues?.id }),
        [onSave, field, initialValues?.id],
    );
    const submitDisabled =
        !activeTabState?.input || isTabStateInvalid(activeTabState);

    const converters =
        useConvertersQuery({ canFetch: true }).loadable.valueMaybe() ?? [];

    const currentMapperItem = {
        ...initialValues,
        ...activeTabState,
    };

    return (
        <Modal visible={visible} onClose={onCancel}>
            <UnityLayout>
                <UnityLayout.Header
                    size={3}
                    title={t("advanced-field-editor.title")}
                />
                <UnityLayout.Content padding={[false, true]}>
                    {visible ? (
                        <>
                            <FieldCustomizationTabsComponent
                                activeTabState={activeTabState}
                                isPreviewDisabled={previewDisabled}
                                {...fieldCustomizationTabs}
                            />
                            {withConverterExpression && (
                                <>
                                    <Gap size="medium" />
                                    <Label
                                        label={t(
                                            "general.converter-expression",
                                        )}
                                    />
                                    <ConverterExpressionComponent
                                        converters={converters}
                                        value={
                                            activeTabState.converterExpression
                                        }
                                        onChange={value =>
                                            setActiveTabConverterState({
                                                readOnly: false,
                                                value,
                                            })
                                        }
                                        converterExpressionReadOnly={
                                            activeTabState.converterExpressionReadOnly
                                        }
                                    />
                                    <Gap size="small" />
                                    {!previewDisabled &&
                                        !isPreviewDisabled(
                                            currentMapperItem,
                                        ) && (
                                            <>
                                                <Label
                                                    label={t(
                                                        "advanced-field-editor.output-preview.label",
                                                    )}
                                                />

                                                <CellPreview
                                                    parserConfig={parserConfig}
                                                    mapperItem={
                                                        currentMapperItem
                                                    }
                                                    getExampleRow={getExampleResult(
                                                        tableExampleDataMap,
                                                    )}
                                                    direction={direction}
                                                />
                                            </>
                                        )}
                                </>
                            )}
                            <Link
                                href="https://pricefx.atlassian.net/wiki/display/PM/Advanced+Field+Editor"
                                targetBlank
                                displayIcon="always"
                            >
                                <T id="advanced-field-editor.documentation" />
                            </Link>
                        </>
                    ) : (
                        <Skeleton title="Loading" />
                    )}
                </UnityLayout.Content>
                <UnityLayout.Footer
                    actionButtons={
                        <ButtonMenu
                            buttons={[
                                {
                                    label: t("general.save"),
                                    onClick: onSaveClick,
                                    type: "primary",
                                    disabled: submitDisabled,
                                },
                                {
                                    label: t("general.cancel"),
                                    onClick: onCancel,
                                    type: "text",
                                    "data-test": "button-cancel",
                                },
                            ]}
                        />
                    }
                />
            </UnityLayout>
        </Modal>
    );
};

FieldCustomizationModal.propTypes = {
    tableExampleData: PropTypes.object,
    initialValues: PropTypes.object,
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    visible: PropTypes.bool.isRequired,
    withConverterExpression: PropTypes.bool,
    converters: PropTypes.object,
    parserConfig: PropTypes.object.isRequired,
};

import { ActionButton } from "@/components/DesignSystem/Table/components/ActionButton/ActionButton";
import { useBreadcrumbButton } from "@/components/hooks/useBreadcrumbButton.hook";
import { useFullWidthTableLayout } from "@/components/hooks/useContentLayout.hook";
import { useDetailDrawerState } from "@/components/hooks/useDetailDrawerState.hook";
import { useHideBackButton } from "@/components/PageLayout/useHideBackButton.hook";
import { columns } from "@/components/Permissions/AtlassianGroups/atlassianGroups.columns";
import { GroupFormModal } from "@/components/Permissions/AtlassianGroups/GroupFormModal";
import {
    useAtlassianGroupsResource,
    useDeleteAtlassianGroupResource,
} from "@/components/Permissions/AtlassianGroups/loadables";
import { TableWithPreferencesManagement } from "@/components/TableWithPreferences/TableWithPreferencesManagement.container";
import { preferencesTypes } from "@/constants/preferencesTypes.constants";
import { LoadableRenderer } from "@/modules/loadable";
import { VisibleWhenHasPermission } from "@/security/authorization";
import { ATLASSIAN_GROUPS_MANAGEMENT_PERMISSIONS } from "@/security/permission.utils";
import { t } from "@/translations";
import React, { useCallback } from "react";

const preferencesType = preferencesTypes.ATLASSIAN_GROUPS_TABLE;

export const AtlassianGroupsAdminPage = () => {
    useFullWidthTableLayout();
    useHideBackButton();

    const groupFormModal = useDetailDrawerState();

    useBreadcrumbButton(
        {
            label: "group-list.button.create-group",
            onClick: groupFormModal.show.bind(null, {}),
        },
        [],
    );

    const atlassianGroupsResource = useAtlassianGroupsResource();
    const afterSuccess = useCallback(() => {
        groupFormModal.hide();
        atlassianGroupsResource.reload();
    }, []);
    const deleteAtlassianGroupResource = useDeleteAtlassianGroupResource({
        afterSuccess,
    });

    const actionMenu = useCallback(
        record => (
            <VisibleWhenHasPermission
                permission={ATLASSIAN_GROUPS_MANAGEMENT_PERMISSIONS}
            >
                <ActionButton
                    wrapperProps={{ onClick: e => e.stopPropagation() }}
                    record={record}
                    items={[
                        {
                            title: t("general.tooltip.edit"),
                            onClick: groupFormModal.show,
                        },
                        {
                            title: t("general.modal.delete.title", {
                                type: "Group",
                            }),
                            confirmMessage: t("general.modal.delete.message", {
                                name: record.name,
                                type: "Atlassian Group",
                            }),
                            onConfirm: deleteAtlassianGroupResource.mutate,
                            color: "red",
                        },
                    ]}
                />
            </VisibleWhenHasPermission>
        ),
        [groupFormModal.show, deleteAtlassianGroupResource.mutate],
    );

    return (
        <VisibleWhenHasPermission
            permission={ATLASSIAN_GROUPS_MANAGEMENT_PERMISSIONS}
        >
            <LoadableRenderer
                loadable={atlassianGroupsResource.loadable}
                hasValue={dataSource => (
                    <TableWithPreferencesManagement
                        actionMenu={actionMenu}
                        columns={columns}
                        dataSource={dataSource}
                        fixed
                        rowKey="name"
                        datasetSlicing="local"
                        preferencesType={preferencesType}
                        exportUrl="/api/admin/atlassian/groups/export"
                    />
                )}
            />
            <GroupFormModal
                record={groupFormModal.record}
                onClose={groupFormModal.hide}
                afterSuccess={afterSuccess}
            />
        </VisibleWhenHasPermission>
    );
};

AtlassianGroupsAdminPage.propTypes = {};

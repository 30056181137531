import { ActionButton } from "@/components/DesignSystem/Table/components/ActionButton/ActionButton";
import { useDic } from "@/components/Dic/useDic.hook";
import { useFetchPage } from "@/components/PagableTable/useFetchPage.hook";
import { AssetsTable } from "@/components/Permissions/UsersAssets/AssetsTable.component";
import { assetType } from "@/constants/assets.constants";
import { preferencesTypes } from "@/constants/preferencesTypes.constants";
import { logger } from "@/modules/logger";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React, { useCallback, useImperativeHandle } from "react";

const preferencesType = preferencesTypes.USER_ASSETS_PARTITIONS_TABLE;

export const PartitionsTableContainer = React.memo(
    React.forwardRef(function PartitionsTableContainer(props, ref) {
        const { permissionsAssetsService } = useDic();
        const { accountId, userIds, onAssetClick, onSubAssetClick } = props;

        const [tableProps, { reload }] = useFetchPage(
            (page, size, sort, filter) =>
                permissionsAssetsService.fetchPartitions({
                    accountId,
                    page,
                    size,
                    sort,
                    filter,
                    userIds,
                }),
            [],
        );

        useImperativeHandle(ref, () => ({
            reload,
        }));

        logger.debug({
            logGroupKey: ["PERMISSIONS", "PartitionsTableContainer"],
            color: "deepskyblue",
            data: {
                permissionsAssetsService,
                tableProps,
            },
        });

        const actionMenu = useCallback(
            record => (
                <ActionButton
                    wrapperProps={{ onClick: e => e.stopPropagation() }}
                    record={record}
                    items={[
                        {
                            title: t("general.permissions"),
                            onClick: record =>
                                onAssetClick(assetType.partition, record),
                        },
                        {
                            title: t("permissions-table.show-subassets"),
                            onClick: record =>
                                onSubAssetClick(assetType.partition, record),
                        },
                    ]}
                />
            ),
            [],
        );

        return (
            <AssetsTable
                preferencesType={preferencesType}
                actionMenu={onSubAssetClick ? actionMenu : null}
                {...tableProps}
                assetType={assetType.partition}
                defaultSort={{
                    fieldName: "name",
                    sortDir: "ascending",
                }}
                {...props}
            />
        );
    }),
);

PartitionsTableContainer.propTypes = {
    accountId: PropTypes.number.isRequired,
    userIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    onAssetClick: PropTypes.func.isRequired,
    onSubAssetClick: PropTypes.func.isRequired,
};

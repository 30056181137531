import { useDic } from "@/components/Dic/useDic.hook";
import ElasticSearchPanel from "@/components/Integrations/Dashboard/Tabs/ElasticSearchPanel";
import { EntityType } from "@/constants/CommonConstats";
import { EVENT_WF_DEBUG_LOG_LIST } from "@/constants/sessionState.constants";
import { getData } from "@/services/utils";
import PropTypes from "prop-types";
import React from "react";

const DEFAULT_COLUMNS = { "@timestamp": true, message: true, sourceName: true };

export const DebugLogs = ({
    accountId,
    initialQuery,
    visible,
    reloadToken,
    reloadAll,
}) => {
    const { axiosService } = useDic();
    const fetchData = params =>
        axiosService
            .post(`/api/accounts/${accountId}/event-workflow-logs`, params)
            .then(getData);

    return (
        <ElasticSearchPanel
            fetchData={fetchData}
            defaultColumns={DEFAULT_COLUMNS}
            entityId={accountId}
            entityType={EntityType.PROJECT}
            sessionStateName={EVENT_WF_DEBUG_LOG_LIST}
            initialQuery={initialQuery}
            visible={visible}
            reloadToken={reloadToken}
            reloadAll={reloadAll}
        />
    );
};

DebugLogs.propTypes = {
    accountId: PropTypes.number.isRequired,
    initialQuery: PropTypes.string,
};

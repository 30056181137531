import { InputType } from "../Packages/PackageTableDefinitionPanel/constants";

export const TABS_VALUE_TYPES = {
    FIELD: "FIELD",
    EXPERT_OPTION: "EXPERT_OPTION",
    FORMULA: "FORMULA",
    COMPOSED: "COMPOSED",
    GROOVY: "GROOVY",
};

// prettier-ignore
export const TABS_BY_INPUT_TYPE = {
    [InputType.BODY]:     TABS_VALUE_TYPES.FIELD,
    [InputType.FORMULA]:  TABS_VALUE_TYPES.FORMULA,
    [InputType.COMPOSED]: TABS_VALUE_TYPES.COMPOSED,
    [InputType.GROOVY]:   TABS_VALUE_TYPES.GROOVY,
    [InputType.HEADER]:   TABS_VALUE_TYPES.EXPERT_OPTION,
    [InputType.PROPERTY]: TABS_VALUE_TYPES.EXPERT_OPTION,
    [InputType.SIMPLE]:   TABS_VALUE_TYPES.EXPERT_OPTION,
    [InputType.CONSTANT]: TABS_VALUE_TYPES.EXPERT_OPTION,
};

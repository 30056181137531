import { TABS_VALUE_TYPES } from "@/components/CalculatedFieldTable/constants";
import { FieldCustomizationModal } from "@/components/CalculatedFieldTable/FieldCustomization.modal";
import { Alert, Button, Forms, Gap } from "@/components/DesignSystem";
import { FieldValidationResult } from "@/components/DesignSystem/Forms/components";
import { useVisibility } from "@/components/hooks/useVisibility.hook";
import { ConverterButton } from "@/components/Mappers/MapperTableWithCustomization/components/ConverterButton";
import { DeleteRowButton } from "@/components/Mappers/MapperTableWithCustomization/components/DeleteRowButton";
import { ParsedType } from "@/components/Packages/PackageTableDefinitionPanel/constants";
import { filter, find, get, pipe } from "lodash/fp";
import PropTypes from "prop-types";
import React, { useCallback, useMemo, useState } from "react";

const LAYOUT = [{}, {}, {}, {}, {}, { width: 32 }];

export const Hidden = props => {
    return <Forms.Fields.Input {...props} label={""} hidden={true} />;
};

const NewRow = ({ add, canAdd }) => {
    const [alertVisible, setAlertVisible] = useState(false);
    const onClick = useCallback(() => {
        if (canAdd) add();
        else setAlertVisible(true);
    }, [add, canAdd]);

    return (
        <>
            {alertVisible && (
                <>
                    <Gap size="small" />
                    <Alert
                        message="Before add new row one of the existing rows needs to be deleted"
                        type="warning"
                        showIcon
                        closable
                        afterClose={() => setAlertVisible(false)}
                    />
                </>
            )}
            <Gap size="small" />
            <Button onClick={onClick} label={"+ Add Row"} />
        </>
    );
};
NewRow.propTypes = {
    add: PropTypes.func.isRequired,
    canAdd: PropTypes.bool,
};

const renderAlert = msg => (!msg ? null : <Alert type="error" message={msg} />);
const ListValidationResult = ({
    formId,
    name,
    renderMessage = renderAlert,
}) => {
    const translate = useCallback(
        pipe(
            filter(Forms.isError),
            find({ name }),
            get("value"),
            renderMessage,
        ),
        [name, renderMessage],
    );
    return (
        <FieldValidationResult
            formId={formId}
            name={name}
            translate={translate}
            touched
        />
    );
};

const emptyArray = [];

const InputAddon = ({ formId, rowId, onClick, fieldProps }) => {
    const rowValues = Forms.useFieldValue({
        formId,
        name: rowId,
    });
    const getFieldName = name => fieldProps(name).name;
    const onAddonClick = e => {
        e.stopPropagation();
        onClick(rowValues, getFieldName);
    };

    return (
        <div
            onClick={onAddonClick}
            style={{
                margin: "-11px",
                padding: "11px",
                cursor: "pointer",
            }}
        >
            <ConverterButton
                inputType={rowValues?.inputType}
                converter={rowValues?.converterExpression}
            />
        </div>
    );
};

export const hideTabs = [TABS_VALUE_TYPES.FORMULA];

export const ExportMapperList = ({
    formId,
    name,
    initialValues,
    setValues,
    options,
    readOnly,
}) => {
    const listValues = Forms.useFieldValue({ formId, name }) ?? emptyArray;
    const unusedOptions = useMemo(() => {
        const usedValues = listValues
            .map(row => row?.input)
            .filter(value => value !== undefined);
        const unusedOptions = options.filter(
            ({ value }) => !usedValues.includes(value),
        );
        return unusedOptions;
    }, [listValues, options]);
    const onInputChange = useCallback(
        ({ name, value }) => {
            if (value) {
                const outputName = name.replace(/input$/, "output");
                setValues({ [outputName]: value });
            }
        },
        [setValues],
    );
    const outputValidator = useMemo(() => Forms.pmValidators.notBlank(), []);
    const listValidator = useMemo(
        () => async value =>
            (await value).length
                ? Forms.success()
                : Forms.error("At least one row is required"),
        [],
    );

    const [customizeField, setCustomizeField] = useState();
    const fieldCustomizationModal = useVisibility();

    const onInputAddonClick = useCallback(
        (rowValues, getFieldName) => {
            setCustomizeField({
                outputType: ParsedType.STRING,
                ...rowValues,
                getFieldName,
            });
            fieldCustomizationModal.show();
        },
        [fieldCustomizationModal.show],
    );

    const onCustomizeField = ({
        input,
        inputType,
        converterExpression,
        converterExpressionReadOnly,
    }) => {
        setValues({
            [customizeField.getFieldName("input")]: input,
            [customizeField.getFieldName("inputType")]: inputType,
            ...(converterExpression !== customizeField.converterExpression
                ? {
                      [customizeField.getFieldName("converterExpression")]:
                          converterExpression,
                      //   [customizeField.getFieldName(
                      //       "converterExpressionReadOnly"
                      //   )]: converterExpressionReadOnly
                  }
                : {}),
        });

        fieldCustomizationModal.hide();
        setCustomizeField(undefined);
    };
    const tableExampleData = useMemo(
        () => ({
            columns: options
                .filter(({ type }) => type === ParsedType.STRING)
                .map(({ value, type }) => ({
                    name: value,
                    type,
                    // parsedValues,
                })),
        }),
        [options],
    );

    return (
        <>
            <Forms.List
                formId={formId}
                name={name}
                initialValue={initialValues}
                validator={listValidator}
            >
                {({ rows, rowIds, add, remove, move }) => (
                    <Forms.FieldGrid
                        layout={LAYOUT}
                        draggableIds={rowIds}
                        onDrop={move}
                    >
                        {rows.map(({ id, fieldProps }) => (
                            <Forms.FieldGrid.Row draggableId={id} key={id}>
                                <Hidden {...fieldProps("id")} />
                                <Hidden
                                    {...fieldProps("inputType")}
                                    initialValue="body"
                                />
                                <Hidden
                                    {...fieldProps("converterExpression")}
                                />
                                <Forms.Fields.Cascader
                                    {...fieldProps("input")}
                                    inputWidth="max"
                                    formId={formId}
                                    id={id}
                                    label={"Pricefx Columns"}
                                    options={unusedOptions}
                                    required
                                    validator={Forms.pmValidators.isRequired}
                                    onChange={onInputChange}
                                    disabled={readOnly}
                                    from={value => value?.[0]}
                                    addonAfter={
                                        <InputAddon
                                            formId={formId}
                                            rowId={id}
                                            onClick={onInputAddonClick}
                                            fieldProps={fieldProps}
                                        />
                                    }
                                />
                                <Forms.Fields.Input
                                    {...fieldProps("output")}
                                    label={"Export CSV File Columns"}
                                    validator={outputValidator}
                                    disabled={readOnly}
                                />
                                <Forms.UIField name="actions">
                                    {!readOnly && (
                                        <DeleteRowButton
                                            disabled={readOnly}
                                            onClick={() => remove(id)}
                                        />
                                    )}
                                </Forms.UIField>
                            </Forms.FieldGrid.Row>
                        ))}
                        <ListValidationResult formId={formId} name={name} />
                        {!readOnly && (
                            <NewRow
                                add={add}
                                canAdd
                                // canAdd={!!unusedOptions.length && fields.length < options.length}
                            />
                        )}
                    </Forms.FieldGrid>
                )}
            </Forms.List>
            <FieldCustomizationModal
                visible={fieldCustomizationModal.visible}
                onSave={onCustomizeField}
                onCancel={fieldCustomizationModal.hide}
                hideTabs={hideTabs}
                inputOptions={options}
                initialValues={customizeField}
                tableExampleData={tableExampleData}
                withConverterExpression
                previewDisabled
            />
        </>
    );
};

ExportMapperList.propTypes = {
    formId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    initialValues: PropTypes.array,
    setValues: PropTypes.func.isRequired,
    options: PropTypes.array.isRequired,
    readOnly: PropTypes.bool,
};

import { Checkbox } from "@/components/DesignSystem";
import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { useDic } from "@/components/Dic/useDic.hook";
import { useFullWidthTableLayout } from "@/components/hooks/useContentLayout.hook";
import { useAvailableInfraVersions } from "@/components/IntegrationManagerVersions/loadables";
import { TableWithPreferencesManagement } from "@/components/TableWithPreferences/TableWithPreferencesManagement.container";
import { CLOUD_PROVIDERS } from "@/constants/cloudProviders.constants";
import { preferencesTypes } from "@/constants/preferencesTypes.constants";
import {
    isLoading,
    useMutationLoadableWithNotification,
    useQueryLoadable,
} from "@/modules/loadable";
import { useFeatureFlag } from "@/security/hooks/useFeatureFlag.hook";
import { getData } from "@/services/utils";
import { t } from "@/translations";
import { editableCellRenderer } from "@pricefx/unity-components";
import React from "react";

const preferencesType =
    preferencesTypes.INTEGRATION_MANAGER_VERSIONS_LIST_TABLE;

export function IntegrationManagerVersionsPage() {
    useFullWidthTableLayout();
    const isGCPEnabled = useFeatureFlag("im-gcp-provider");
    const { imVersionsService } = useDic();
    const query = useQueryLoadable(
        async () => imVersionsService.fetchVersions().then(getData),
        [],
    );
    const mutation = useMutationLoadableWithNotification(
        async record =>
            imVersionsService.updateVersion(record).then(query.reload),
        [],
    );

    const infraVersions = useAvailableInfraVersions();
    const infraAwsVersionsOptions = (infraVersions.loadable.valueMaybe() ??
        [])?.[CLOUD_PROVIDERS.AWS]?.map(version => ({
        label: version,
        value: version,
    }));
    const infraGcpVersionsOptions = (infraVersions.loadable.valueMaybe() ??
        [])?.[CLOUD_PROVIDERS.GCP]?.map(version => ({
        label: version,
        value: version,
    }));

    const columns = [
        {
            type: fieldTypes.TEXT,
            label: t("integration-manager-versions.label.name"),
            name: "name",
        },
        {
            type: fieldTypes.TEXT,
            editable: true,
            canFilter: false,
            label: t(
                "integration-manager-versions.label.bound-infra-version-aws",
            ),
            name: "boundInfraVersionAws",
            options: infraAwsVersionsOptions,
            render: editableCellRenderer((_, name, value, record) => {
                imVersionsService
                    .updateVersion({ ...record, [name]: value })
                    .then(query.reload);
            }),
        },
        {
            type: fieldTypes.TEXT,
            editable: true,
            visible: isGCPEnabled,
            canFilter: false,
            label: t(
                "integration-manager-versions.label.bound-infra-version-gcp",
            ),
            name: "boundInfraVersionGcp",
            options: infraGcpVersionsOptions,
            render: editableCellRenderer((_, name, value, record) => {
                imVersionsService
                    .updateVersion({ ...record, [name]: value })
                    .then(query.reload);
            }),
        },
        {
            name: "transition",
            canFilter: false,
            canSort: false,
            label: t("integration-manager-versions.label.is-transition"),
            render: (text, record) => {
                return (
                    <Checkbox
                        value={record.transition}
                        onChange={e => {
                            mutation.mutate({
                                ...record,
                                transition: e.value,
                            });
                        }}
                    />
                );
            },
        },
        {
            name: "allowedUpgradeAws",
            canFilter: false,
            canSort: false,
            label: t("integration-manager-versions.label.allowed-upgrade-aws"),
            render: (text, record) => {
                return (
                    <Checkbox
                        value={record.allowedUpgradeAws}
                        onChange={e => {
                            mutation.mutate({
                                ...record,
                                allowedUpgradeAws: e.value,
                            });
                        }}
                    />
                );
            },
        },
        {
            name: "allowedNewAws",
            canFilter: false,
            canSort: false,
            label: t("integration-manager-versions.label.allowed-new-aws"),
            render: (text, record) => {
                return (
                    <Checkbox
                        value={record.allowedNewAws}
                        onChange={e => {
                            mutation.mutate({
                                ...record,
                                allowedNewAws: e.value,
                            });
                        }}
                    />
                );
            },
        },
        {
            name: "allowedUpgradeGcp",
            canFilter: false,
            canSort: false,
            label: t("integration-manager-versions.label.allowed-upgrade-gcp"),
            render: (text, record) => {
                return (
                    <Checkbox
                        value={record.allowedUpgradeGcp}
                        onChange={e => {
                            mutation.mutate({
                                ...record,
                                allowedUpgradeGcp: e.value,
                            });
                        }}
                    />
                );
            },
        },
        {
            name: "allowedNewGcp",
            canFilter: false,
            canSort: false,
            label: t("integration-manager-versions.label.allowed-new-gcp"),
            render: (text, record) => {
                return (
                    <Checkbox
                        value={record.allowedNewGcp}
                        onChange={e => {
                            mutation.mutate({
                                ...record,
                                allowedNewGcp: e.value,
                            });
                        }}
                    />
                );
            },
        },
    ];

    return (
        <TableWithPreferencesManagement
            fixed
            columns={columns}
            dataSource={query.loadable.valueMaybe() ?? []}
            rowKey="name"
            loading={isLoading(query) || isLoading(mutation)}
            datasetSlicing="local"
            preferencesType={preferencesType}
        />
    );
}

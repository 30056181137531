import concat from "lodash/fp/concat";
import fromPairs from "lodash/fp/fromPairs";
import groupBy from "lodash/fp/groupBy";
import keyBy from "lodash/fp/keyBy";
import map from "lodash/fp/map";
import pipe from "lodash/fp/pipe";
import { toLetters } from "../Packages/PackageTableDefinitionPanel/components/PackageMandatoryFields/PackageMandatoryFields.utils";
import {
    extractFormulaSymbols,
    renameFormulaSymbols,
} from "./FormulaTab/FormulaTab.utils";

const FORMULA_ERRORS = {
    UNKNOWN_SOURCE_FIELD: "UNKNOWN_SOURCE_FIELD",
    MISSING_FIELD: "MISSING_FIELD",
};

const humanizeError = ({ error, key, sourceField }) => {
    switch (error) {
        case FORMULA_ERRORS.UNKNOWN_SOURCE_FIELD:
            return `${key} not found in source fields`;
        case FORMULA_ERRORS.MISSING_FIELD:
            return `"${sourceField}" denoted by "${key}" not found in inputs`;
        default:
            return `Unknown error occured during ${key} (${sourceField}) processing`;
    }
};

export const conformFormulaToMapping = ({
    input,
    formulaMapping,
    previousFormulaMapping,
}) => {
    if (!input || !previousFormulaMapping)
        return {
            input,
            error: "",
            formulaMapping,
            missingMapping: [],
            previousMapping: undefined,
        };

    const usedSymbols = extractFormulaSymbols(input);
    const oldSymbolsMap = keyBy("key", previousFormulaMapping);
    const newSymbolsByFieldMap = keyBy("sourceField", formulaMapping);

    // TODO: old to new symbols map (more transparent generation of unique new keys for errors)
    let nextKeyIndex = formulaMapping.length;

    const { errors = [], changes = [] } = pipe(
        map(oldKey => {
            if (!oldKey)
                throw new Error("Bad implementation, missing formula symbol");

            const sourceField = oldSymbolsMap[oldKey]?.sourceField;

            if (!sourceField)
                throw new Error(`Unknown field for formula symbol ${oldKey}`);
            // should not happen, invalid api state
            // return {
            //     error: FORMULA_ERRORS.UNKNOWN_SOURCE_FIELD,
            //     key: toLetters(nextKeyIndex++),
            //     oldKey
            // };
            if (newSymbolsByFieldMap[sourceField])
                return {
                    error: null,
                    key: newSymbolsByFieldMap[sourceField].key,
                    oldKey,
                };
            return {
                error: FORMULA_ERRORS.MISSING_FIELD,
                key: toLetters(nextKeyIndex++),
                oldKey,
                sourceField,
            };
        }),
        groupBy(({ error, key, oldKey }) =>
            error ? "errors" : key !== oldKey ? "changes" : "unchanged",
        ),
    )(usedSymbols);

    const renameMap = pipe(
        concat(errors),
        map(({ key, oldKey }) => [oldKey, key]),
        fromPairs,
    )(changes);

    try {
        const newFormula = renameFormulaSymbols(input, renameMap);

        if (errors.length) {
            return {
                input: newFormula,
                error: errors.map(humanizeError).join(". "),
                formulaMapping,
                missingMapping: pipe(
                    // filter(["error", FORMULA_ERRORS.MISSING_FIELD]),
                    map(({ key, sourceField }) => ({ key, sourceField })),
                )(errors),
                previousMapping: undefined,
            };
        }
        return {
            input: newFormula,
            error: "",
            formulaMapping,
            missingMapping: [],
            previousMapping: undefined,
        };
    } catch (e) {
        console.error("initFormula error:", e);
        return {
            input: input,
            error: e.message,
            formulaMapping,
            missingMapping: [],
            previousMapping: previousFormulaMapping,
        };
    }
};

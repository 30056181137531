import { Button, ButtonGroup, Forms, Gap, P } from "@/components/DesignSystem";
import { parseEnvs } from "@/components/Integrations/InstanceGroupEdit/InstanceGroupEditForm";
import { NewProvisionedInstanceFormComponent } from "@/components/Integrations/NewProvisionedInstanceForm/NewProvisionedInstanceForm.component";
import { useEnvironmentsResource } from "@/components/Integrations/NewProvisionedInstanceForm/loadables";
import { useCreateNewInstancePossibleQuery } from "@/components/Integrations/loadables";
import { useAlertEffect } from "@/components/PageLayout/useAlertEffect.hook";
import { useSetValidatedInitialValues } from "@/components/hooks/useSetValidatedInitialValues.hook";
import { InstanceServerType } from "@/constants/CommonConstats";
import { hasValue } from "@/modules/loadable";
import { t } from "@/translations";
import { useFieldValue } from "@pricefx/unity-components/dist/es/components/Forms/hooks";
import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { NewManualInstanceFormComponent } from "./NewManualInstanceForm.component";
const { useForm, Form, Fields, SubmitButton } = Forms;

const serverTypeOptions = [
    {
        label: t("instance-form.label.type.provisioned"),
        value: InstanceServerType.PROVISIONED,
    },
    {
        label: t("instance-form.label.type.manual"),
        value: InstanceServerType.MANUAL,
    },
];

export const NewInstanceForm = ({ onCancel, onSubmit }) => {
    const integrationTypesResource = useEnvironmentsResource();

    const handleInstanceSubmit = useCallback(
        ({ values }) => {
            const parsedEnvs = parseEnvs(values.environments);

            return onSubmit({
                values: {
                    ...values,
                    environments: parsedEnvs,
                },
                successMessage:
                    values.serverType === InstanceServerType.PROVISIONED
                        ? t("new-instance.message.created-provisioned")
                        : t("new-instance.message.created-manual"),
            });
        },
        [onSubmit],
    );

    const { formId, handleSubmit, setValues, setTouched, getBag } = useForm({
        onSubmit: handleInstanceSubmit,
    });

    const cloudProvider = useFieldValue({
        formId,
        name: "cloudProvider",
    });
    const region = useFieldValue({
        formId,
        name: "region",
    });

    const createNewInstancePossibleQuery = useCreateNewInstancePossibleQuery(
        cloudProvider,
        region,
    );
    useAlertEffect(
        hasValue(createNewInstancePossibleQuery) &&
            !createNewInstancePossibleQuery.loadable.valueMaybe(),
        () => ({
            type: "warning",
            message: t("new-instance.alert.not-enough-space"),
        }),
    );

    const serverType = useFieldValue({
        formId,
        name: "serverType",
    });

    useSetValidatedInitialValues(
        {
            initialValues: { serverType: InstanceServerType.PROVISIONED },
            setValues,
            setTouched,
        },
        [],
    );

    function handleServerTypeChange({ value }) {
        setValues({ serverType: value });
    }

    return (
        <>
            <P>{t("instance-form.perex")}</P>
            <P>{t("instance-form.perex-note")}</P>
            <Gap size="large" />
            <Form formId={formId} onSubmit={handleSubmit}>
                <Fields.Radio
                    name="serverType"
                    label={t("instance-form.label.server-type")}
                    options={serverTypeOptions}
                    onChange={handleServerTypeChange}
                    inputWidth="max"
                />
                {serverType === InstanceServerType.PROVISIONED && (
                    <NewProvisionedInstanceFormComponent
                        formId={formId}
                        getBag={getBag}
                        setValues={setValues}
                    />
                )}
                {serverType === InstanceServerType.MANUAL && (
                    <NewManualInstanceFormComponent
                        integrationTypesResource={integrationTypesResource}
                    />
                )}
                <ButtonGroup>
                    <SubmitButton
                        label={t("partition-request.button.submit")}
                        type="primary"
                    />
                    <Button
                        type="text"
                        onClick={onCancel}
                        label={t("general.cancel")}
                    />
                </ButtonGroup>
            </Form>
        </>
    );
};

NewInstanceForm.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

import { CustomerPartitionCredentialsModal } from "@/components/CustomerPartitionCredentials";
import { CopyPartitionModalFlow } from "@/components/CustomerPartitions/components/CopyPartition.modal";
import {
    useDeletePartitionMutation,
    useEngSupportUserInfoQuery,
} from "@/components/CustomerPartitions/loadables";
import { Button, ButtonGroup } from "@/components/DesignSystem";
import { ActionButton } from "@/components/DesignSystem/Table/components/ActionButton/ActionButton";
import { useDic } from "@/components/Dic/useDic.hook";
import { mapLoadableItems } from "@/components/Integrations/InstancesList";
import { EntityType } from "@/components/Integrations/IntegrationsVersionHistory.overview.page";
import { useFetchPage } from "@/components/PagableTable/useFetchPage.hook";
import { DefaultPartitionButton } from "@/components/PartitionNameColumnRenderer/PartitionNameColumnRenderer";
import { RequestTicketProperty } from "@/components/Partitions/RequestTicketProperty";
import { useRemoteLoginUsersQuery } from "@/components/RemoteLogin/loadables";
import { RequestEngSupportUserModal } from "@/components/RequestEngSupportUser/RequestEngSupportUserModal";
import { CreateSupportUserModal } from "@/components/SupportUser/CreateSupportUserModal";
import { TableWithPreferencesManagement } from "@/components/TableWithPreferences/TableWithPreferencesManagement.container";
import { useDetailDrawerState } from "@/components/hooks/useDetailDrawerState.hook";
import { useIncludesRightsForPartition } from "@/components/hooks/useIncludesRightsForPartition.hook";
import { useValueVisibility } from "@/components/hooks/useValueVisibility.hook";
import { preferencesTypes } from "@/constants/preferencesTypes.constants";
import {
    getCustomerPartitionsListRemoteLoginTrackName,
    getCustomerPartitionsListTrackName,
} from "@/mixpanel/buttonNames";
import {
    SecurityContext,
    isAuthorizedByPermissionOr,
} from "@/security/authorization";
import { useFeatureFlag } from "@/security/hooks/useFeatureFlag.hook";
import { useUserBasicInfo } from "@/security/hooks/useUserBasicInfo.hook";
import {
    ACCOUNT_PARTITION_EDIT_PERMISSIONS,
    ACCOUNT_PARTITION_PERMISSIONS,
    ACCOUNT_PARTITION_PROVISIONING_PERMISSIONS,
} from "@/security/permission.utils";
import { t } from "@/translations";
import { getSsoInfoAndLoginToPFX } from "@/utils/loginToPfx";
import { isProductionPartition } from "@/utils/partitionUtils";
import { isEmpty } from "lodash";
import map from "lodash/map";
import PropTypes from "prop-types";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { createColumns } from "./CustomerPartitionsList.columns";

const preferencesType = preferencesTypes.CUSTOMER_PARTITIONS_LIST_TABLE;

const internalDomains = ["@pricefx.com", "@pricefx.eu"];

const mapRemoteLoginActionItems = (record, usersList, onRemoteLogin) =>
    map(usersList, user => ({
        title: t("partition-list.remote-login.title", { user: user }),
        onClick: () => onRemoteLogin(record, user),
        track: { name: getCustomerPartitionsListRemoteLoginTrackName(user) },
    }));

const CustomerPartitionsList = ({ accountId }) => {
    const { mixpanelService, ssoService } = useDic();
    const { locationRouterService, accountAppLocations } = useDic();
    const {
        partitionEditLocation,
        partitionCloneLocation,
        partitionMoveLocation,
        partitionGeneralAdminLocation,
    } = accountAppLocations;
    const basicInfo = useUserBasicInfo();
    const username = `${basicInfo.userInfo.user.username}`;
    const isInternalUser = internalDomains.some(domain =>
        username.endsWith(domain),
    );

    const changeViewToGeneralAdmin = useCallback(
        partitionId =>
            locationRouterService.navigate(partitionGeneralAdminLocation, {
                accountId,
                partitionId,
            }),
        [accountId, locationRouterService, partitionGeneralAdminLocation],
    );
    const changeViewToClone = useCallback(
        partitionId =>
            locationRouterService.navigate(partitionCloneLocation, {
                accountId,
                partitionId,
            }),
        [accountId, locationRouterService, partitionCloneLocation],
    );
    const changeViewToMove = useCallback(
        partitionId =>
            locationRouterService.navigate(partitionMoveLocation, {
                accountId,
                partitionId,
            }),
        [accountId, locationRouterService, partitionMoveLocation],
    );

    const supportUserInfo = useValueVisibility();
    const copyPartitionModal = useValueVisibility();
    const requestEngSupportUserModal = useValueVisibility();

    const [tableProps, { reload }] = useFetchPage(
        (page, size, sort = "serialNumber,asc", filter) =>
            projectPartitionApiService.fetchPartitionPageable(
                accountId,
                page,
                size,
                sort,
                filter,
            ),
        [accountId],
    );

    const securityContext = useContext(SecurityContext);

    const authorizedForRemoteLogin = isAuthorizedByPermissionOr(
        ["loginAs_root", "loginAs_pricefx-support"],
        securityContext.permissions,
    );
    const remoteLoginUsersResource = useRemoteLoginUsersQuery({
        hasPermission: authorizedForRemoteLogin,
    });
    const usersList = useMemo(
        () => remoteLoginUsersResource.loadable.valueMaybe() ?? [],
        [remoteLoginUsersResource.loadable],
    );

    const hasReadRights = useIncludesRightsForPartition(
        ACCOUNT_PARTITION_PERMISSIONS,
    );
    const hasEditRights = useIncludesRightsForPartition(
        ACCOUNT_PARTITION_EDIT_PERMISSIONS,
    );
    const hasProvisioningPartitionRight = useIncludesRightsForPartition(
        ACCOUNT_PARTITION_PROVISIONING_PERMISSIONS,
    );
    const hasCreatedStatus = record => record.status === "Created";

    const { remoteLoginService, projectPartitionApiService } = useDic();

    const handleRemoteLoginClick = useCallback(
        (record, user) => {
            remoteLoginService.doRemoteLogin(
                record.baseUrl,
                record.partitionName,
                user,
            );
        },
        [remoteLoginService],
    );

    const fetchRequestTicketUrl = (partitionId, ticketType) =>
        projectPartitionApiService.fetchRequestTicketUrl(
            accountId,
            partitionId,
            ticketType,
        );

    const partitionPanel = useDetailDrawerState();
    const columns = useMemo(
        () => createColumns(hasReadRights, hasCreatedStatus),
        [hasReadRights],
    );

    const canDeletePartition = useCallback(
        record =>
            hasProvisioningPartitionRight(record.accountId, record.id) &&
            hasCreatedStatus(record) &&
            !isProductionPartition(record),
        [hasProvisioningPartitionRight],
    );

    const canCloneAndMovePartition = useCallback(
        record =>
            hasProvisioningPartitionRight(record.accountId, record.id) &&
            hasCreatedStatus(record),
        [hasProvisioningPartitionRight],
    );

    const canBeDeletedDirectly = record =>
        record.partitionType !== "Production";

    const partitionCredentialsModal = useDetailDrawerState();

    const deleteMutation = useDeletePartitionMutation({ afterSuccess: reload });

    const isCopyPartitionFeatureEnabled = useFeatureFlag("copy-partition");

    const [recordToCheck, setRecordToCheck] = useState(null);
    const engSupportUserInfoResource = useEngSupportUserInfoQuery(
        recordToCheck?.id,
        !isNaN(recordToCheck?.id),
    );

    const mapMenuItems = useCallback(
        record => maybeEngSupportUserInfo =>
            [
                {
                    title: t("general.show-detail"),
                    onClick: partitionPanel.show,
                },
                {
                    title: t("general.show-logs"),
                    onClick: () => {
                        window.open(record.logUrl, "_blank", "noopener");
                    },
                    visible: isInternalUser,
                    disabled: !record?.logUrl,
                    track: {
                        name: getCustomerPartitionsListTrackName("ShowLogs"),
                    },
                },
                {
                    title: t("partitions-list.actions.edit-partition"),
                    onClick: () =>
                        locationRouterService.navigate(partitionEditLocation, {
                            accountId,
                            partitionId: record.id,
                        }),
                    visible:
                        hasCreatedStatus(record) &&
                        hasEditRights(record.accountId, record.id),
                    track: {
                        name: getCustomerPartitionsListTrackName("Edit"),
                    },
                },
                {
                    visible: canCloneAndMovePartition(record),
                    title: t("partitions-list.actions.clone-partition"),
                    onClick: record => changeViewToClone(record.id),
                    track: {
                        name: getCustomerPartitionsListTrackName("Clone"),
                    },
                },
                {
                    visible:
                        canCloneAndMovePartition(record) &&
                        !record.moveRequestExists,
                    title: t("partitions-list.actions.move-partition"),
                    onClick: record => changeViewToMove(record.id),
                    // onClick: moveModal.show,
                    track: {
                        name: getCustomerPartitionsListTrackName("Move"),
                    },
                },
                {
                    visible:
                        isCopyPartitionFeatureEnabled &&
                        hasCreatedStatus(record),
                    title: t("partitions-list.actions.copy-partition"),
                    onClick: copyPartitionModal.show,
                    track: {
                        name: getCustomerPartitionsListTrackName("Copy"),
                    },
                },

                {
                    visible:
                        hasEditRights(record.accountId, record.id) &&
                        hasCreatedStatus(record),
                    title: t("partitions-list.actions.manage-credentials"),
                    onClick: partitionCredentialsModal.show,
                },
                ...(authorizedForRemoteLogin && hasCreatedStatus(record)
                    ? mapRemoteLoginActionItems(
                          record,
                          usersList,
                          handleRemoteLoginClick,
                      )
                    : []),
                hasEditRights(record.accountId, record.id) &&
                    hasCreatedStatus(record) && {
                        title: t(
                            "partitions-list.actions.create-general-admin",
                        ),
                        onClick: record => changeViewToGeneralAdmin(record.id),
                        track: {
                            name: getCustomerPartitionsListTrackName(
                                "CreateGeneralAdmin",
                            ),
                        },
                    },
                {
                    visible:
                        hasEditRights(record.accountId, record.id) &&
                        hasCreatedStatus(record),
                    title: t("partitions-list.actions.create-support-user"),
                    onClick: record => {
                        supportUserInfo.show({
                            accountId: record.accountId,
                            partitionId: record.id,
                        });
                    },
                    track: {
                        name: getCustomerPartitionsListTrackName(
                            "CreateSupportUser",
                        ),
                    },
                },
                {
                    title: t(
                        "partitions-list.actions.request-eng-support-user",
                    ),
                    onClick: record => {
                        requestEngSupportUserModal.show({
                            partitionId: record.id,
                        });
                    },
                    visible: (() => {
                        return (
                            hasCreatedStatus(record) &&
                            maybeEngSupportUserInfo.canCreateNew
                        );
                    })(),
                    track: {
                        name: getCustomerPartitionsListTrackName(
                            "RequestEngSupportUser",
                        ),
                    },
                },
                {
                    title: t(
                        "partitions-list.actions.remote-login-as-eng-support-user",
                    ),
                    onClick: () =>
                        getSsoInfoAndLoginToPFX({
                            pfxLoginInfo: maybeEngSupportUserInfo.pfxLoginInfo,
                            ssoService,
                            mixpanelService,
                        }),
                    visible: (() => {
                        return (
                            hasCreatedStatus(record) &&
                            maybeEngSupportUserInfo.canUse &&
                            !isEmpty(maybeEngSupportUserInfo.pfxLoginInfo)
                        );
                    })(),
                    track: {
                        name: getCustomerPartitionsListTrackName(
                            "RemoteLoginAsEngSupportUser",
                        ),
                    },
                },
                {
                    title: t("general.version-history"),
                    onClick: () => {
                        locationRouterService.navigate(
                            accountAppLocations.partitionsVersionHistoryLocation,
                            {
                                entityId: record.id,
                                entityType: EntityType.PARTITION,
                            },
                        );
                    },
                },
                {
                    visible: canDeletePartition(record),
                    title: t("customer-partitions-list.modal.delete.title"),
                    confirmMessage: t(
                        canBeDeletedDirectly(record)
                            ? "customer-partitions-list.modal.delete.message"
                            : "customer-partitions-list.modal.request-deletion.message",
                        {
                            partitionId: record.serialNumber,
                        },
                    ),
                    onConfirm: ({ accountId, id }) =>
                        deleteMutation.mutate({
                            accountId: accountId,
                            partitionId: id,
                        }),
                    color: "red",
                    track: {
                        name: getCustomerPartitionsListTrackName("Delete"),
                    },
                },
            ],
        [
            partitionPanel.show,
            isInternalUser,
            hasEditRights,
            canCloneAndMovePartition,
            isCopyPartitionFeatureEnabled,
            copyPartitionModal.show,
            partitionCredentialsModal.show,
            authorizedForRemoteLogin,
            usersList,
            handleRemoteLoginClick,
            canDeletePartition,
            locationRouterService,
            partitionEditLocation,
            accountId,
            changeViewToClone,
            changeViewToMove,
            changeViewToGeneralAdmin,
            supportUserInfo,
            requestEngSupportUserModal,
            ssoService,
            mixpanelService,
            accountAppLocations.partitionsVersionHistoryLocation,
            deleteMutation,
        ],
    );

    const actionMenu = useCallback(
        record => {
            const props = {
                items: mapLoadableItems(
                    mapMenuItems(record),
                    engSupportUserInfoResource,
                ),
                wrapperProps: {
                    onClick: () => {
                        setRecordToCheck(record);
                    },
                },
            };

            return <ActionButton record={record} {...props} />;
        },
        [mapMenuItems, engSupportUserInfoResource],
    );

    if (tableProps?.error) {
        return (
            <p>
                <FormattedMessage id="partition-list.loading.error" />
            </p>
        );
    }

    return (
        <>
            <TableWithPreferencesManagement
                defaultSort={{
                    fieldName: "serialNumber",
                    sortDir: "ascending",
                }}
                actionMenu={actionMenu}
                columns={columns}
                fixed
                rowKey="id"
                datasetSlicing="server"
                preferencesType={preferencesType}
                exportUrl={`/api/projects/${accountId}/partition-assets/pageable/export`}
                panelControl={partitionPanel}
                renderPanelTitle={record => record?.serialNumber}
                renderPanelExtra={record => (
                    <>
                        <RequestTicketProperty
                            record={record}
                            fetchRequestTicketUrl={fetchRequestTicketUrl}
                        />
                        <ButtonGroup>
                            <Button
                                visible={hasCreatedStatus(record)}
                                label={t("general.edit")}
                                type="primary"
                                onClick={() => {
                                    partitionPanel.hide();
                                    locationRouterService.navigate(
                                        partitionEditLocation,
                                        {
                                            accountId,
                                            partitionId: record.id,
                                        },
                                    );
                                }}
                            />
                            <DefaultPartitionButton
                                accountId={record.accountId}
                                partitionId={record.id}
                                hasRight={
                                    hasCreatedStatus(record) &&
                                    hasReadRights(record.accountId, record.id)
                                }
                            />
                        </ButtonGroup>
                    </>
                )}
                {...tableProps}
            />
            {copyPartitionModal.visible && (
                <CopyPartitionModalFlow
                    accountId={accountId}
                    visible={copyPartitionModal.visible}
                    sourceCluster={copyPartitionModal.value?.clusterName}
                    sourceClusterLink={copyPartitionModal.value?.baseUrl}
                    partitionId={copyPartitionModal.value?.id}
                    serialNumber={copyPartitionModal.value?.serialNumber}
                    onClose={copyPartitionModal.hide}
                    onSuccess={() => {
                        copyPartitionModal.hide();
                        reload();
                    }}
                />
            )}
            <CreateSupportUserModal
                visible={supportUserInfo.visible}
                accountId={supportUserInfo.value?.accountId}
                partitionId={supportUserInfo.value?.partitionId}
                onClose={supportUserInfo.hide}
            />
            <CustomerPartitionCredentialsModal
                visible={!!partitionCredentialsModal.record}
                accountId={accountId}
                partitionId={partitionCredentialsModal.record?.id}
                onCancel={partitionCredentialsModal.hide}
            />
            <RequestEngSupportUserModal
                visible={requestEngSupportUserModal.visible}
                partitionId={requestEngSupportUserModal.value?.partitionId}
                onClose={requestEngSupportUserModal.hide}
            />
        </>
    );
};

CustomerPartitionsList.propTypes = {
    accountId: PropTypes.number.isRequired,
};

export default CustomerPartitionsList;

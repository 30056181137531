import { useDic } from "@/components/Dic/useDic.hook";
import { useHideBackButton } from "@/components/PageLayout/useHideBackButton.hook";
import { GroupAdminSettingsComponent } from "@/components/Permissions/AccountUserAdminSettings/GroupAdminSettings/GroupAdminSettings.component";
import {
    useAccountGroupsResource,
    useAddGroupResource,
    useDeleteGroupResource,
    useEditGroupResource,
    useInviteUsersResource,
    useRemoveUserFromGroupResource,
    useRemoveUsersFromGroupResource,
} from "@/components/Permissions/AccountUserAdminSettings/GroupAdminSettings/loadables";
import { useAccountUsersResource } from "@/components/Permissions/AccountUserAdminSettings/UserAdminSettings/loadables";
import { useBreadcrumbButton } from "@/components/hooks/useBreadcrumbButton.hook";
import { useFullWidthTableLayout } from "@/components/hooks/useContentLayout.hook";
import { useVisibility } from "@/components/hooks/useVisibility.hook";
import {
    LoadableRenderer,
    useComposeLoadablesMemoized,
} from "@/modules/loadable";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import React, { useCallback, useMemo } from "react";

export const UserGroupsAccountPage = () => {
    useFullWidthTableLayout();
    useHideBackButton();

    const { accountId } = useAccountAppParams();
    const {
        hide: hideModal,
        show: showModal,
        visible: modalVisible,
    } = useVisibility(false);
    const accountUsersResource = useAccountUsersResource({ accountId });
    const accountGroupsResource = useAccountGroupsResource({ accountId });

    const afterGroupMutated = useCallback(() => {
        hideModal();
        accountGroupsResource.reload();
    }, [hideModal, accountGroupsResource]);
    const reloadAll = useCallback(() => {
        accountGroupsResource.reload();
        accountUsersResource.reload();
    }, [accountUsersResource, accountGroupsResource]);

    const groupMutationParams = useMemo(
        () => ({
            accountId,
            afterSuccess: afterGroupMutated,
        }),
        [accountId, afterGroupMutated],
    );
    const deleteGroupResource = useDeleteGroupResource(groupMutationParams);
    const addGroupResource = useAddGroupResource(groupMutationParams);
    const editGroupResource = useEditGroupResource(groupMutationParams);

    const rAll = useMemo(
        () => ({
            accountId,
            afterSuccess: reloadAll,
        }),
        [accountId, reloadAll],
    );
    const removeUserFromGroupResource = useRemoveUserFromGroupResource(rAll);
    const removeUsersFromGroupResource = useRemoveUsersFromGroupResource(rAll);
    const inviteUsersResource = useInviteUsersResource(rAll);

    const { adminApiService } = useDic();
    const fetchGroupDetail = id =>
        adminApiService.fetchProjectGroupDetail(accountId, id);

    const loadables = useComposeLoadablesMemoized([
        accountUsersResource.loadable,
        accountGroupsResource.loadable,
    ]);

    useBreadcrumbButton(
        {
            label: "account-admin-group-list.button.create-group",
            onClick: showModal,
        },
        [showModal],
    );

    return (
        <LoadableRenderer
            loadable={loadables}
            hasValue={([users, groups]) => (
                <GroupAdminSettingsComponent
                    projectUsers={users}
                    inviteUsers={inviteUsersResource.mutate}
                    groups={groups}
                    onModalCancel={hideModal}
                    onGroupAdd={addGroupResource.mutate}
                    onGroupDelete={deleteGroupResource.mutate}
                    fetchGroupDetail={fetchGroupDetail}
                    onRemoveUserFromGroup={removeUserFromGroupResource.mutate}
                    onRemoveUsersFromGroup={removeUsersFromGroupResource.mutate}
                    visibleCreateGroupModal={modalVisible}
                    onGroupEdit={editGroupResource.mutate}
                    accountId={accountId}
                />
            )}
        />
    );
};

UserGroupsAccountPage.propTypes = {};

import { message } from "@/components/DesignSystem";
import { ActionButton } from "@/components/DesignSystem/Table/components/ActionButton/ActionButton";
import { useSyncRowSelection } from "@/components/DesignSystem/Table/hooks/useSyncRowSelection.hook";
import { useDic } from "@/components/Dic/useDic.hook";
import { useFetchPage } from "@/components/PagableTable/useFetchPage.hook";
import { useHideBackButton } from "@/components/PageLayout/useHideBackButton.hook";
import { isPricefxUsername } from "@/components/Permissions/AccountUserAdminSettings/RightsDrawer/loadables";
import { useGetExportAccessReportButton } from "@/components/Permissions/Users.account.page";
import { EditUserModal } from "@/components/Permissions/Users/EditUserModal";
import { UserGlobalPermissionsDrawerContainer } from "@/components/Permissions/Users/GlobalPermissions/UserGlobalPermissionsDrawerContainer";
import { NewUserModal } from "@/components/Permissions/Users/NewUserModal";
import { SsoUsernameModal } from "@/components/Permissions/Users/SsoUsernameModal";
import { createColumns } from "@/components/Permissions/Users/UsersList.columns";
import {
    useDeleteUserResource,
    useRemoveAllPermissionsMutation,
    useResetPasswordResource,
} from "@/components/Permissions/Users/loadables";
import { joinUserIdsToUrlParam } from "@/components/Permissions/utils";
import { TableWithPreferencesManagement } from "@/components/TableWithPreferences/TableWithPreferencesManagement.container";
import { useBreadcrumbButtons } from "@/components/hooks/useBreadcrumbButtons.hook";
import { useFullWidthTableLayout } from "@/components/hooks/useContentLayout.hook";
import { preferencesTypes } from "@/constants/preferencesTypes.constants";
import { VisibleWhenHasPermission } from "@/security/authorization";
import { useHasGlobalPermission } from "@/security/hooks/useHasGlobalPermission.hook";
import {
    USERS_MANAGEMENT_PERMISSIONS,
    USER_MANAGEMENT_EDIT_PERMISSION,
} from "@/security/permission.utils";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React, { useCallback, useMemo, useState } from "react";

const VIEWS = {
    DEFAULT: "default",
    GLOBAL_PERMISSIONS: "global_permissions",
    ASSETS_PERMISSIONS: "assets_permissions",
    NEW_USER: "new_user",
    EDIT_USER: "edit_user",
};

const preferencesType = preferencesTypes.ADMIN_USERS_LIST_TABLE;

export const UsersAdminPage = () => {
    const {
        locationRouterService,
        administrationAppLocations: { permissionsAdminUsersAccountsLocation },
        userAdminService,
    } = useDic();
    useFullWidthTableLayout();
    useHideBackButton();

    const [tableProps, { reload }] = useFetchPage(
        (page, size, sort, filter) =>
            userAdminService.fetchUsers(page, size, sort, filter),
        [],
    );
    const [view, setView] = useState(VIEWS.DEFAULT);

    const { filtersRef, exportAccessReportButton } =
        useGetExportAccessReportButton({
            exportUrl: `/api/admin/rights/users/export`,
            fileName: "admin-access-report.csv",
            permissions: ["permission.power.user"],
        });
    useBreadcrumbButtons(
        () => [
            {
                type: "primary",
                label: "user-list.title.create-user",
                onClick: () => setView(VIEWS.NEW_USER),
                permissions: [USER_MANAGEMENT_EDIT_PERMISSION],
            },
            exportAccessReportButton,
        ],
        [exportAccessReportButton],
    );

    const [selectedUsers, setSelectedUsers] = useState([]);

    const navigateToUsersAccounts = useCallback(
        userIds => {
            locationRouterService.navigate(
                permissionsAdminUsersAccountsLocation,
                {
                    userIds: joinUserIdsToUrlParam(userIds),
                },
            );
        },
        [locationRouterService, permissionsAdminUsersAccountsLocation],
    );

    const multipleIdsPermissions = useCallback(
        users => {
            // setSelectedUsers(users);
            // setView(VIEWS.ASSETS_PERMISSIONS);
            navigateToUsersAccounts(users.map(user => user.id));
        },
        [navigateToUsersAccounts],
    );
    // const singleRecordPermissions = useCallback(
    //     user => {
    //         // multipleIdsPermissions([user]);
    //         navigateToAssets([user]);
    //     },
    //     [multipleIdsPermissions],
    // );
    const showGlobalPermissions = useCallback(user => {
        setSelectedUsers([user]);
        setView(VIEWS.GLOBAL_PERMISSIONS);
    }, []);

    const setSelectedUserByUserId = userIds => {
        const users = userIds.map(
            id =>
                selectedUsers.find(u => u.id === id) ||
                tableProps?.page?.content?.find(u => u.id === id),
        );
        setSelectedUsers(users);
    };

    const selectedUsersIds = useMemo(
        () => selectedUsers.map(user => user.id),
        [selectedUsers],
    );

    const onCancel = useCallback(() => setView(VIEWS.DEFAULT), []);
    const deleteUserResource = useDeleteUserResource({
        afterSuccess: reload,
    });
    const removeAllPermissionsMutation = useRemoveAllPermissionsMutation({
        afterSuccess: reload,
    });
    const resetPasswordResource = useResetPasswordResource();
    const onEdit = useCallback(user => {
        setSelectedUsers([user]);
        setView(VIEWS.EDIT_USER);
    }, []);
    const isPowerUser = useHasGlobalPermission("permission.power.user");

    const selectionContextMenu = useMemo(
        () => [
            {
                label: t("general.mass-permissions"),
                onClick: () => multipleIdsPermissions(selectedUsers),
            },
        ],
        [selectedUsers, multipleIdsPermissions],
    );
    const rowSelection = useSyncRowSelection(
        selectedUsersIds,
        setSelectedUserByUserId,
    );

    const displaySuperAssets = useMemo(
        () => selectedUsers.every(user => isPricefxUsername(user?.username)),
        [selectedUsers],
    );

    const isUserInvited = record => record.status === "INVITED";

    const resendActivation = useCallback(
        record => {
            userAdminService
                .resendActivation(record.id)
                .then(() => {
                    message.success(
                        t("user-detail.resend-activation-email-ok"),
                    );
                })
                .catch(() => {
                    message.error(
                        t("user-detail.resend-activation-email-failed"),
                    );
                });
        },
        [userAdminService],
    );

    const actionMenu = useCallback(
        record => (
            <VisibleWhenHasPermission
                permission={USER_MANAGEMENT_EDIT_PERMISSION}
            >
                <ActionButton
                    wrapperProps={{ onClick: e => e.stopPropagation() }}
                    record={record}
                    items={[
                        {
                            title: t("general.tooltip.edit"),
                            onClick: onEdit,
                            visible: !isUserInvited(record),
                        },
                        {
                            title: t("user-detail.modal.sso-username.title"),
                            customModal: <SsoUsernameModal record={record} />,
                            permission: ["user_mgmt.users.edit"],
                        },
                        {
                            title: t("general.permission-management"),
                            onClick: record =>
                                navigateToUsersAccounts([record.id]),
                            permission: ["permission.power.user"],
                        },
                        {
                            title: t("general.global-permissions"),
                            onClick: showGlobalPermissions,
                            permission: [USER_MANAGEMENT_EDIT_PERMISSION],
                        },
                        {
                            title: t("user-detail.resend-activation-email"),
                            onClick: () => resendActivation(record),
                            visible:
                                isUserInvited(record) &&
                                !isPricefxUsername(record.username),
                            permission: [USER_MANAGEMENT_EDIT_PERMISSION],
                        },
                        {
                            title: t("user-detail.password.button.reset"),
                            confirmMessage: t(
                                "user-detail.password.description",
                            ),
                            confirmButtonTitle: t(
                                "user-detail.password.button.reset",
                            ),
                            // onConfirm: resetPasswordResource.mutate
                            // TODO remove workaround below https://pricefx.atlassian.net/browse/PFIM-4294
                            onConfirm: record => {
                                resetPasswordResource.mutate(record);
                            },
                            visible: !isUserInvited(record),
                        },
                        {
                            title: t("general.modal.remove-permissions.title"),
                            confirm: {
                                message: t(
                                    "general.modal.remove-permissions.message",
                                    {
                                        name: record.username,
                                        type: "User",
                                    },
                                ),
                                okText: t("general.remove"),
                                onConfirm: removeAllPermissionsMutation.mutate,
                            },
                            color: "red",
                        },
                        {
                            title: t("general.modal.delete.title", {
                                type: "User",
                            }),
                            confirmMessage: t("general.modal.delete.message", {
                                name: record.username,
                                type: "User",
                            }),
                            onConfirm: deleteUserResource.mutate,
                            color: "red",
                        },
                    ]}
                />
            </VisibleWhenHasPermission>
        ),
        [
            onEdit,
            showGlobalPermissions,
            deleteUserResource.mutate,
            navigateToUsersAccounts,
            resendActivation,
            resetPasswordResource,
        ],
    );

    const columns = useMemo(() => createColumns(), []);

    return (
        <VisibleWhenHasPermission permission={USERS_MANAGEMENT_PERMISSIONS}>
            <TableWithPreferencesManagement
                actionMenu={actionMenu}
                defaultSort={{
                    fieldName: "username",
                    sortDir: "ascending",
                }}
                columns={columns}
                fixed
                rowKey="id"
                rowSelection={!isPowerUser ? undefined : rowSelection}
                selectionContextMenu={
                    !isPowerUser ? undefined : selectionContextMenu
                }
                datasetSlicing="server"
                preferencesType={preferencesType}
                exportUrl={"/api/admin/users/table/export"}
                filtersRef={filtersRef}
                {...tableProps}
            />
            <UserGlobalPermissionsDrawerContainer
                userId={selectedUsersIds[0]}
                visible={view === VIEWS.GLOBAL_PERMISSIONS}
                onCancel={onCancel}
                onSaved={onCancel}
            />

            <NewUserModal
                visible={view === VIEWS.NEW_USER}
                onClose={onCancel}
                reloadUsers={reload}
            />
            <EditUserModal
                visible={view === VIEWS.EDIT_USER}
                onClose={onCancel}
                user={selectedUsers?.[0]}
                reloadUsers={reload}
            />
        </VisibleWhenHasPermission>
    );
};

UsersAdminPage.propTypes = {
    setButtonData: PropTypes.func.isRequired,
    cleanButtonData: PropTypes.func.isRequired,
};

import { Drawer, Tabs } from "@/components/DesignSystem";
import { AssetRolesTable } from "@/components/Permissions/AccountUserAdminSettings/RightsDrawer/AssetRolesTable/AssetRolesTable.component";
import { AssetsTable } from "@/components/Permissions/AccountUserAdminSettings/RightsDrawer/AssetsTable/AssetsTable.component";
import { useAssetRolesSelection } from "@/components/Permissions/AccountUserAdminSettings/RightsDrawer/hooks/useAssetRolesSelection.hook";
import { useAssetsSelection } from "@/components/Permissions/AccountUserAdminSettings/RightsDrawer/hooks/useAssetsSelection.hook";
import {
    useAccountAssetRolesResource,
    useAccountBusinessRolesResource,
    useGroupedPermissionsResource,
} from "@/components/Permissions/AccountUserAdminSettings/RightsDrawer/loadables";
import { PermissionsDrawerContainer } from "@/components/Permissions/AccountUserAdminSettings/RightsDrawer/PermissionsDrawer/PermissionsDrawer.container";
import {
    ADMIN_KIND,
    ASSET_KIND,
    assetMatch,
    assetRoleMatch,
    ENTITY_KIND,
} from "@/components/Permissions/AccountUserAdminSettings/RightsDrawer/PermissionsDrawer/sources.utils";
import {
    LoadableRenderer,
    MutationResourcePropType,
    QueryResourcePropType,
    useComposeLoadablesMemoized,
} from "@/modules/loadable";
import { T } from "@/translations";
import { size } from "lodash/fp";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";

export const TABS = {
    ASSETS: ASSET_KIND.ASSET,
    ASSET_ROLES: ASSET_KIND.ASSET_ROLE,
};

export const VIEWS = {
    DEFAULT: null,
    PERMISSIONS: "PERMISSIONS",
};

export const createAccountAssets = ({ project, partitions, integrations }) => ({
    id: project.id,
    name: project.name,
    type: project.type,
    territory: project.territory,
    createdBy: project.createdBy,
    partitions: partitions,
    integrations: integrations,
});

export const AccountRightsDrawer = React.memo(function AccountRightsDrawer({
    accountId,
    entityKind,
    selectedEntities,
    rightsResource,
    workflowRightsResource,
    saveRightsResource,
    onClose,
    visible = true,
}) {
    const [tab, setTab] = useState(TABS.ASSETS);
    const [view, setView] = useState(VIEWS.DEFAULT);
    const showPermissions = useCallback(
        () => setView(VIEWS.PERMISSIONS),
        [setView],
    );
    const {
        selectedAssets,
        onAssetsSelection,
        onAssetClick,
        noAssetsSelected,
        resetAssets,
    } = useAssetsSelection({ afterClick: showPermissions });
    const {
        selectedAssetRoles,
        selectedAssetRoleIds,
        onAssetRolesSelection,
        onAssetRoleClick,
        noAssetRolesSelected,
        resetAssetRoles,
    } = useAssetRolesSelection(showPermissions);

    const onPermissionsDrawerClose = useCallback(
        () => setView(VIEWS.DEFAULT),
        [setView],
    );

    useEffect(() => {
        resetAssets();
        resetAssetRoles();
    }, [visible, tab, resetAssets, resetAssetRoles]);
    const onCloseAll = useCallback(() => {
        setView(VIEWS.DEFAULT);
        onClose();
    }, [onClose]);
    useEffect(() => () => setView(VIEWS.DEFAULT), [visible]);

    const groupedPermissionsResource = useGroupedPermissionsResource();
    const businessRolesResource = useAccountBusinessRolesResource({
        accountId,
    });
    const accountAssetRolesResource = useAccountAssetRolesResource({
        accountId,
        canFetch: visible,
    });

    const loadables = useComposeLoadablesMemoized([
        groupedPermissionsResource.loadable,
        businessRolesResource.loadable,
        accountAssetRolesResource.loadable,
        rightsResource.loadable,
        workflowRightsResource.loadable,
    ]);

    return (
        <Drawer
            width={630}
            cancelButtonProps={{ onClick: onClose }}
            okButtonProps={{
                onClick: showPermissions,
                disabled:
                    tab === TABS.ASSETS
                        ? noAssetsSelected
                        : noAssetRolesSelected,
            }}
            onClose={onClose}
            visible={visible}
        >
            <LoadableRenderer
                loadable={loadables}
                hasValue={([
                    groupedPermissions,
                    businessRoles,
                    accountAssetRoles,
                    rights,
                    workflowRights,
                    saveRights, // TODO
                ]) => (
                    <>
                        <Tabs defaultActiveKey={tab} onChange={setTab}>
                            <Tabs.TabPane
                                tab={<T id="global.assets" />}
                                key={TABS.ASSETS}
                            >
                                <AssetsTable
                                    assetType="project"
                                    assets={[
                                        createAccountAssets(accountAssetRoles),
                                    ]}
                                    onAssetClick={onAssetClick}
                                    selectionChange={onAssetsSelection}
                                    selectedAssets={selectedAssets}
                                    hasRights={(assetId, assetType) =>
                                        rights?.some(
                                            assetMatch({
                                                assetId,
                                                assetType,
                                            }),
                                        )
                                    }
                                />
                            </Tabs.TabPane>

                            <Tabs.TabPane
                                tab={<T id="global.asset-groups" />}
                                key={TABS.ASSET_ROLES}
                            >
                                <AssetRolesTable
                                    assetRoles={accountAssetRoles.assetRoles}
                                    onAssetRoleClick={onAssetRoleClick}
                                    selectionChange={onAssetRolesSelection}
                                    selectedAssetRoleIds={selectedAssetRoleIds}
                                    hasRights={assetRoleId =>
                                        rights?.some(
                                            assetRoleMatch(assetRoleId),
                                        )
                                    }
                                />
                            </Tabs.TabPane>
                        </Tabs>
                        <PermissionsDrawerContainer
                            title="Asset Permissions"
                            description={
                                selectedAssets > 1
                                    ? `${size(
                                          selectedAssets,
                                      )} selected accounts`
                                    : ""
                            }
                            selectedAssets={
                                tab === TABS.ASSETS
                                    ? selectedAssets
                                    : tab === TABS.ASSET_ROLES
                                    ? selectedAssetRoles
                                    : null
                            }
                            selectedEntities={selectedEntities}
                            adminKind={ADMIN_KIND.PROJECT}
                            entityKind={entityKind}
                            assetKind={tab}
                            rights={rights}
                            workflowRights={workflowRights}
                            groupedPermissions={groupedPermissions}
                            businessRoles={businessRoles}
                            onCancel={onCloseAll}
                            onClose={onPermissionsDrawerClose}
                            onSave={saveRightsResource.mutate}
                            visible={Boolean(view === VIEWS.PERMISSIONS)}
                        />
                    </>
                )}
            />
        </Drawer>
    );
});

AccountRightsDrawer.propTypes = {
    rightsResource: QueryResourcePropType(PropTypes.array.isRequired),
    workflowRightsResource: QueryResourcePropType(PropTypes.array.isRequired),
    saveRightsResource: MutationResourcePropType(),
    entityKind: PropTypes.oneOf(Object.values(ENTITY_KIND)).isRequired,
    selectedEntities: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
        PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    ]),
    accountId: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired,
    visible: PropTypes.bool.isRequired,
};

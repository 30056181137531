import { Button, Forms } from "@/components/DesignSystem";
import { useSetValidatedInitialValues } from "@/components/hooks/useSetValidatedInitialValues.hook";
import {
    useElkTagsQuery,
    useSaveElkTagsMutation,
} from "@/components/Integrations/loadables";
import { getLoadableSelectProps } from "@/components/Packages/PackageTableDefinitionPanel/components/ObjectTypeSelector/EntityNameSelector";
import { isLoading, useMapLoadableMemoized } from "@/modules/loadable";
import { t } from "@/translations";
import { map } from "lodash/fp";
import PropTypes from "prop-types";
import React from "react";

const MAX_COMBINED_TAGS_LENGTH = 255;
const MAX_TAGS_COUNT = 100;

export const ElkTags = ({ instanceId }) => {
    const elkTagsQuery = useElkTagsQuery({ instanceId });
    const elkTagsOptionsLoadable = useMapLoadableMemoized(
        elkTagsQuery.loadable,
        map(value => ({ value, label: value })),
    );
    const saveElkTagsMutation = useSaveElkTagsMutation({
        instanceId,
        afterSuccess: elkTagsQuery.reload,
    });
    const { formId, setValues, setTouched, handleSubmit } = Forms.useForm({
        onSubmit: ({ values: { integrationTags } }) =>
            saveElkTagsMutation.mutate(integrationTags),
    });
    const tagsValidator = Forms.validators.failOnFirst([
        (value = []) =>
            value.join(",").length <= MAX_COMBINED_TAGS_LENGTH
                ? Forms.success()
                : Forms.error(
                      t("elk-tags.validations.combined-length", {
                          maxCombinedLength: MAX_COMBINED_TAGS_LENGTH,
                          length: value.join(",").length,
                      }),
                  ),
        Forms.pmValidators.createMaxLengthValidation(
            MAX_TAGS_COUNT,
            "general.validation.max-array-length",
        ),
    ]);
    useSetValidatedInitialValues(
        {
            initialValues: {
                integrationTags: elkTagsQuery.loadable.valueMaybe() ?? [],
            },
            setValues,
            setTouched,
        },
        [elkTagsQuery.loadable.valueMaybe()],
    );

    return (
        <Forms.Form formId={formId} onSubmit={handleSubmit}>
            <Forms.Fields.Select
                label={t("elk-tags.integration-tags.label")}
                data-test="integration-tags-title"
                placeholder={t("general.select")}
                name="integrationTags"
                mode="tags"
                validator={tagsValidator}
                {...getLoadableSelectProps(elkTagsOptionsLoadable)}
            />
            <Forms.SubmitButton
                disabled={
                    isLoading(elkTagsQuery) || isLoading(saveElkTagsMutation)
                }
            >
                <Button
                    type="primary"
                    label={t("general.save")}
                    data-test="save-button"
                    onClick={e => {
                        e.stopPropagation();
                        handleSubmit(e);
                    }}
                />
            </Forms.SubmitButton>
        </Forms.Form>
    );
};

ElkTags.propTypes = { instanceId: PropTypes.number.isRequired };

import React from "react";
import { VENDOR } from "@/components/Mappers/MapperTableWithCustomization/constants";
import { defaultVendors } from "@/components/Mappers/MapperTableWithCustomization/MapperTableWithCustomization";
import {
    REQUIRED_BY_ENTITY_TYPE,
    hasSubFilter,
} from "@/constants/SupportedTableImportTypes";
import { t } from "@/translations";
import filter from "lodash/filter";
import isEmpty from "lodash/isEmpty";
import keys from "lodash/keys";
import {
    initFormula,
    validateComposed,
} from "../../CalculatedFieldTable/FieldCustomizationTabs.component";
import {
    convertToNumbersForFormula,
    getHeadersOfStringType,
} from "../../Packages/PackageTableDefinitionPanel/components/PackageMandatoryFields/PackageMandatoryFields.utils";
import {
    InputType,
    ParsedType,
} from "../../Packages/PackageTableDefinitionPanel/constants";

export const validateDefinition = (
    definition,
    entityType,
    entityName,
    businessKey,
    tableData,
    inputOptionsMaybe,
    outputOptionsMaybe,
    vendors = defaultVendors,
    stringFields = getHeadersOfStringType(tableData),
    formulaMapping = convertToNumbersForFormula(tableData),
) => {
    const inputErrors = [];
    let generalErrors = [];

    const [_, outputVendor] = vendors;

    const inputOptionsValues = inputOptionsMaybe?.map(({ value }) => value);
    const isValidInputValue = value =>
        !inputOptionsValues || inputOptionsValues.includes(value);
    const outputOptionsValues = outputOptionsMaybe?.map(({ value }) => value);
    const isValidOutputValue = value =>
        !outputOptionsValues || outputOptionsValues.includes(value);

    const jsonDefinition =
        typeof definition === "string" ? JSON.parse(definition) : definition;

    if (hasSubFilter(entityType) && !entityName) {
        generalErrors.push(
            t("data-upload.mapper-table.validation.entity-name.empty"),
        );
    }

    const mandatoryFields = (outputOptionsMaybe ?? [])
        .filter(field => field.mandatory)
        .map(field => field.value);
    const usedMandatoryFields = definition.map(field => field.output);

    if (!mandatoryFields.every(field => usedMandatoryFields.includes(field))) {
        generalErrors.push(
            `${t(
                "data-upload.mapper-table.validation.mandatory-fields",
            )}: ${mandatoryFields
                .filter(field => !usedMandatoryFields.includes(field))
                .join(", ")}`,
        );
    }

    if (entityType && definition) {
        generalErrors = generalErrors.concat(
            validateMapperWithEntityType(
                jsonDefinition,
                entityType,
                businessKey,
                outputVendor,
            ),
        );
    }

    jsonDefinition.forEach(item => {
        const itemErrors = {};

        if (isEmpty(item.input)) {
            itemErrors.input = t(
                "data-upload.mapper-table.validation-undefined-input",
            );
        }

        if ([InputType.BODY].includes(item.inputType)) {
            if (!isValidInputValue(item.input)) {
                itemErrors.input = t(
                    "data-upload.mapper-table.validation-not-found-in-options",
                );
            }
        }
        // https://pricefx.atlassian.net/browse/PFIM-3700
        // else if (
        //     item.inputType?.toLowerCase() === "body" &&
        //     containsDuplicates(jsonDefinition, { input: item.input })
        // ) {
        //     itemErrors.input = "Input name must be unique"; // converter? other input types in mapper?
        // }

        if (isEmpty(item.output)) {
            itemErrors.output = t(
                "data-upload.mapper-table.validation-undefined-output",
            );
        } else if (
            containsDuplicates(jsonDefinition, { output: item.output })
        ) {
            itemErrors.output = t(
                "data-upload.mapper-table.validation-not-unique-output",
            );
        } else if (!isValidOutputValue(item.output)) {
            itemErrors.output = t(
                "data-upload.mapper-table.validation-not-found-in-options",
            );
        }

        if (isEmpty(item.inputType)) {
            itemErrors.inputType = t(
                "data-upload.mapper-table.validation-undefined-input-type",
            );
        }

        if (item.inputType === InputType.FORMULA) {
            const { error } = initFormula({
                input: item.input,
                formulaMapping,
                previousFormulaMapping: item.formulaMapping,
            });

            if (
                item.outputType !== ParsedType.NUMBER &&
                item.outputType !== ParsedType.INTEGER
            )
                itemErrors.inputType =
                    "Formula is valid only for number output";
            else if (error) itemErrors.inputType = error;
        }

        if (item.inputType === InputType.COMPOSED) {
            const error = validateComposed(item.input, stringFields);
            if (error) itemErrors.input = error;
        }

        if (
            item.inputType === InputType.COMPOSED &&
            item.outputType !== ParsedType.STRING
        ) {
            itemErrors.inputType =
                "Composed type is valid only for string output";
        }

        if (keys(itemErrors).length > 0) {
            itemErrors.id = item.id;
            inputErrors.push(itemErrors);
        }
    });

    return {
        inputs: inputErrors,
        general: generalErrors,
        valid: inputErrors.length === 0 && generalErrors.length === 0,
    };
};

const containsDuplicates = (definition, criterium) => {
    return filter(definition, criterium).length > 1;
};

const fieldNameToTSKey = {
    sku: "mapper.validation.missing-sku",
    customerId: "mapper.validation.missing-customer-id",
    sellerId: "mapper.validation.missing-seller-id",
    key2: "mapper.validation.missing-key2",
};

export const validateMapperWithEntityType = (
    definition,
    entityType,
    businessKey = {},
    outputVendor,
) => {
    const errors = [];

    if (outputVendor !== VENDOR.PRICEFX) return errors;

    REQUIRED_BY_ENTITY_TYPE[entityType]?.forEach(name => {
        if (!containsOutput(definition, name)) {
            const tsKey = fieldNameToTSKey[name] ?? `${name} required`;
            errors.push(t(tsKey));
        }
    });

    if (entityType === "CX" || entityType === "PX" || entityType === "SX") {
        if (!containsOutputOutsideOf(definition, businessKey)) {
            errors.push(
                t("mapper.validation.must-have-output-outside-of-business-key"),
            );
        }
    }

    return errors;
};

const containsOutputOutsideOf = (definition, outputs) => {
    return definition.find(function (item) {
        if (!outputs[item.output]) {
            return item.output;
        }
    });
};

const containsOutput = (definition, output) => {
    return definition.find(function (item) {
        return item.output === output;
    });
};

import { PERMISSION_TYPES } from "@/components/Permissions/AccountUserAdminSettings/RightsDrawer/PermissionsDrawer/PermissionsTable/PermissionsTables.component";
import { SUPER_ASSET_TYPES } from "@/components/Permissions/AccountUserAdminSettings/RightsDrawer/SuperAssetsTable/SuperAssetsTable.component";
import "lodash.product";
import entries from "lodash/fp/entries";
import fromPairs from "lodash/fp/fromPairs";
import isMatch from "lodash/fp/isMatch";
import isNil from "lodash/fp/isNil";
import map from "lodash/fp/map";
import matchesProperty from "lodash/fp/matchesProperty";
import overEvery from "lodash/fp/overEvery";
import overSome from "lodash/fp/overSome";
import pipe from "lodash/fp/pipe";
import reverse from "lodash/fp/reverse";

export const PERMISSION_TYPE_TO_SUPER_ASSET_TYPE = {
    [PERMISSION_TYPES.PROJECT]: SUPER_ASSET_TYPES.ALL_ACCOUNTS,
    [PERMISSION_TYPES.INTEGRATION]: SUPER_ASSET_TYPES.ALL_INTEGRATIONS,
    [PERMISSION_TYPES.PARTITION]: SUPER_ASSET_TYPES.ALL_PARTITIONS,
};
export const ADMIN_KIND = {
    PROJECT: "project admin",
    GENERAL: "general admin",
};
export const ENTITY_KIND = {
    USER: "user",
    GROUP: "user group",
};
export const ASSET_KIND = {
    ASSET: "asset",
    ASSET_ROLE: "asset role",
    SUPER_ASSET: "super asset",
};
export const PERMISSION_KIND = {
    PERMISSION: "permission",
    BUSINESS_ROLE: "business role",
};
export const UNKNOWN = "unknown";

const matchAdminKind = record =>
    record.admin ? ADMIN_KIND.GENERAL : ADMIN_KIND.PROJECT;
const matchEntityKind = record => {
    if (record.groupId || record.groupName) return ENTITY_KIND.GROUP; // TODO: consider separating adminGroup and accountGroup
    if (record.userId) return ENTITY_KIND.USER;

    throw new Error("Unknown entity kind" + JSON.stringify(record));
};
const matchAssetKind = record => {
    if (record.assetRoleId) return ASSET_KIND.ASSET_ROLE;
    if (record.superAssetType) return ASSET_KIND.SUPER_ASSET;
    if (record.assetId) return ASSET_KIND.ASSET;

    throw new Error("Unknown asset kind" + JSON.stringify(record));
};
const matchPermissionKind = record => {
    if (record.businessRoleId) return PERMISSION_KIND.BUSINESS_ROLE;
    if (record.permissionName) return PERMISSION_KIND.PERMISSION;
};

// applySpec
export const recordSource = record => ({
    adminKind: matchAdminKind(record),
    entityKind: matchEntityKind(record),
    assetKind: matchAssetKind(record),
    permissionKind: matchPermissionKind(record),
});

export const INTERSPERSER = "/";

export const serialize = source => {
    const tuple = [
        source.adminKind,
        source.entityKind,
        source.assetKind,
        source.permissionKind,
    ];
    return tuple.join(INTERSPERSER);
};

export const deserialize = source => {
    const [adminKind, entityKind, assetKind, permissionKind] =
        source.split(INTERSPERSER);
    return { adminKind, entityKind, assetKind, permissionKind };
};

export const SUPER_ASSET_TYPE_TO_ASSET_TYPE = pipe(
    entries,
    map(reverse),
    fromPairs,
)(PERMISSION_TYPE_TO_SUPER_ASSET_TYPE);

export const addSource = record => ({
    ...record,
    source: recordSource(record),
});

export const isEditable = editableSources => record =>
    editableSources.some(editableSource =>
        isMatch(editableSource, record.source),
    )
        ? "editable"
        : "readOnly";

export const getPermissionsTooltip = sources => quantifier =>
    !sources.length
        ? ""
        : `Right granted by ${sources
              .map(s => s.replaceAll(INTERSPERSER, " > "))
              .join(" + ")} for ${quantifier}.`;

const isSuperAsset = record => !isNil(record.superAssetType);

export const assetMatch = ({ assetId, assetType }) =>
    overEvery([
        matchesProperty("assetType", assetType.toUpperCase()),
        overSome([isSuperAsset, matchesProperty("assetId", `${assetId}`)]),
    ]); // assetId on api record is string (historical reasons)
export const assetRoleMatch = assetRoleId =>
    matchesProperty("assetRoleId", assetRoleId);
export const superAssetMatch = superAssetType =>
    matchesProperty("superAssetType", superAssetType);
const userMatch = userId => matchesProperty("userId", userId);
// TODO: consider splitting ENTITY_KIND.GROUP -> PROJECT_GROUP + ADMIN_GROUP
const groupMatch = groupId => matchesProperty("groupId", groupId);
const adminGroupMatch = groupName => matchesProperty("groupName", groupName);

// prettier-ignore
export const getEntityRecordFilter = source => {
    if (isMatch({ entityKind: ENTITY_KIND.USER }, source))
        return userMatch;
    if (isMatch({ entityKind: ENTITY_KIND.GROUP, adminKind: ADMIN_KIND.GENERAL }, source))
        return adminGroupMatch;
    if (isMatch({ entityKind: ENTITY_KIND.GROUP, adminKind: ADMIN_KIND.PROJECT }, source))
        return groupMatch;
    throw new Error("Missing entity kind record filter")
};

export const getAssetRecordFilter = assetKind => {
    switch (assetKind) {
        case ASSET_KIND.ASSET:
            return assetMatch;
        case ASSET_KIND.ASSET_ROLE:
            return assetRoleMatch;
        case ASSET_KIND.SUPER_ASSET:
            return superAssetMatch;
        default:
            throw new Error("Missing asset kind record filter");
    }
};

export const expandSource = source =>
    []
        .concat(source)
        .concat(
            source.adminKind === ADMIN_KIND.GENERAL
                ? { ...source, adminKind: ADMIN_KIND.PROJECT }
                : [],
        );

import PropTypes from "prop-types";
import React, { useCallback } from "react";

import { GlobalPermissionsDrawer } from "@/components/Permissions/Users/GlobalPermissions/GlobalPermissionsDrawer";
import {
    useGlobalPermissions,
    useGroupUserGlobalPermissions,
    useSaveUserGlobalPermissions,
    useUserGlobalPermissions,
} from "@/components/Permissions/Users/GlobalPermissions/loadableResources";
import { t } from "@/translations";

export function UserGlobalPermissionsDrawerContainer({
    userId,
    visible,
    onCancel,
    onSaved,
}) {
    const globalPermissionsResource = useGlobalPermissions();
    const userGlobalPermissionsResource = useUserGlobalPermissions(
        userId,
        visible,
    );
    const groupUserGlobalPermissionsResource = useGroupUserGlobalPermissions(
        userId,
        visible,
    );
    const onPermissionsSaved = useCallback(() => {
        userGlobalPermissionsResource.reload();
        onSaved();
    }, [onSaved, userGlobalPermissionsResource.reload]);
    const savePermissionsResource = useSaveUserGlobalPermissions(
        userId,
        onPermissionsSaved,
    );

    return (
        <GlobalPermissionsDrawer
            permissionsListResource={globalPermissionsResource}
            editablePermissionsResource={userGlobalPermissionsResource}
            readOnlyPermissionsResource={groupUserGlobalPermissionsResource}
            savePermissionsResource={savePermissionsResource}
            visible={visible}
            onCancel={onCancel}
            title={t("general.global-permissions")}
        />
    );
}

UserGlobalPermissionsDrawerContainer.propTypes = {
    userId: PropTypes.number,
    visible: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    onSaved: PropTypes.func.isRequired,
};

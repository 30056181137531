import {
    ButtonMenu,
    Forms,
    Modal,
    UnityLayout,
} from "@/components/DesignSystem";
import { getLoadableSelectProps } from "@/components/Packages/PackageTableDefinitionPanel/components/ObjectTypeSelector/EntityNameSelector";
import { createPickerTableColumns } from "@/components/Permissions/AtlassianGroups/createPickerTableColumns";
import {
    useAdminAccountOptionsResource,
    useAtlassianGroupDetail,
    useAtlassianUsersResource,
    usePostAtlassianGroupResource,
} from "@/components/Permissions/AtlassianGroups/loadables";
import {
    LoadableRenderer,
    isLoading,
    useComposeLoadablesMemoized,
} from "@/modules/loadable";
import { t } from "@/translations";
import capitalize from "lodash/fp/capitalize";
import defaultTo from "lodash/fp/defaultTo";
import pipe from "lodash/fp/pipe";
import update from "lodash/fp/update";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";

const notBlankValidator = Forms.pmValidators.notBlank();

const suffix = suffix => str => str + suffix;
const prefix = prefix => str => prefix + str;

const GROUP_TYPE_OPTIONS = [
    { value: "CUSTOMER", label: "Customer" },
    { value: "PARTNER", label: "Partner" },
];

export const GroupFormModal = ({ record, onClose, afterSuccess }) => {
    const visible = !!record;
    const [namePrefix, setNamePrefix] = useState("_");
    const setNamePrefixFromType = useCallback(
        pipe(defaultTo(""), capitalize, suffix("_"), setNamePrefix),
        [],
    );
    useEffect(() => {
        !visible && setNamePrefixFromType("");
    }, [visible, setNamePrefixFromType]);

    const { formId, handleSubmit, setValues, setTouched } = Forms.useForm({
        onSubmit: ({ values }) => {
            const group = isEdit
                ? values
                : update("name", prefix(namePrefix), values);
            return postAtlassianGroupResource.mutate(group);
        },
    });
    const { loadableGroupDetail, isEdit } = useAtlassianGroupDetail({
        record,
        setValues,
        setTouched,
    });
    const postAtlassianGroupResource = usePostAtlassianGroupResource({
        isEdit,
        afterSuccess,
    });
    const accountOptionsResource = useAdminAccountOptionsResource({
        canFetch: visible,
    });
    const atlassianUsersResource = useAtlassianUsersResource({
        canFetch: visible,
    });
    const loadables = useComposeLoadablesMemoized([
        loadableGroupDetail,
        atlassianUsersResource.loadable,
    ]);
    const submitDisabled = isLoading(postAtlassianGroupResource);

    return (
        <Modal visible={visible} onClose={onClose}>
            <UnityLayout>
                <UnityLayout.Header
                    size={3}
                    title={t(
                        isEdit
                            ? "atlassian-groups.modal.title.edit"
                            : "atlassian-groups.modal.title.create",
                    )}
                />
                <UnityLayout.Content padding={[false, true]}>
                    <LoadableRenderer
                        loadable={loadables}
                        hasValue={([groupDetail, atlassianUsers]) => (
                            <Forms.Form formId={formId} onSubmit={handleSubmit}>
                                <Forms.FieldGroup width="max" inputWidth="max">
                                    <Forms.Fields.Input
                                        required
                                        name="name"
                                        label={t(
                                            "atlassian-groups.form.name.label",
                                        )}
                                        placeholder={t(
                                            "atlassian-groups.form.name.placeholder",
                                        )}
                                        addonBefore={
                                            isEdit ? undefined : namePrefix
                                        }
                                        validator={notBlankValidator}
                                        disabled={isEdit}
                                        allowClear={false}
                                    />
                                    <Forms.Fields.Select
                                        required
                                        name="projectId"
                                        label={t(
                                            "atlassian-groups.form.account.label",
                                        )}
                                        placeholder={t(
                                            "atlassian-groups.form.account.placeholder",
                                        )}
                                        allowClear={false}
                                        showSearch
                                        validator={
                                            Forms.pmValidators.isRequired
                                        }
                                        {...getLoadableSelectProps(
                                            accountOptionsResource.loadable,
                                        )}
                                    />
                                    <Forms.Fields.Input
                                        name="description"
                                        label={t(
                                            "atlassian-groups.form.description.label",
                                        )}
                                        placeholder={t(
                                            "atlassian-groups.form.description.placeholder",
                                        )}
                                    />
                                    <Forms.Fields.Select
                                        required
                                        name="type"
                                        label={t(
                                            "atlassian-groups.form.type.label",
                                        )}
                                        placeholder={t(
                                            "atlassian-groups.form.type.placeholder",
                                        )}
                                        allowClear={false}
                                        options={GROUP_TYPE_OPTIONS}
                                        showSearch
                                        validator={
                                            Forms.pmValidators.isRequired
                                        }
                                        disabled={isEdit}
                                        onChange={({ value }) => {
                                            setNamePrefixFromType(value);
                                        }}
                                    />
                                    <Forms.Fields.EntityIdsPickerTable
                                        name="userAccountIds"
                                        entities={atlassianUsers}
                                        idKey="accountId"
                                        createColumns={createPickerTableColumns}
                                        title={t(
                                            "atlassian-groups.form.userAccountIds.label",
                                        )}
                                        addButtonLabel={t("general.add-user")}
                                        addModalTitle={t(
                                            "user-detail.groups.add.title",
                                        )}
                                    />
                                </Forms.FieldGroup>
                            </Forms.Form>
                        )}
                    />
                </UnityLayout.Content>
                <UnityLayout.Footer
                    actionButtons={
                        <ButtonMenu
                            buttons={[
                                {
                                    label: t("general.save"),
                                    type: "primary",
                                    formId,
                                    disabled: submitDisabled,
                                },
                                {
                                    label: t("general.cancel"),
                                    onClick: onClose,
                                    type: "text",
                                },
                            ]}
                        />
                    }
                />
            </UnityLayout>
        </Modal>
    );
};

GroupFormModal.propTypes = {
    record: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    afterSuccess: PropTypes.func.isRequired,
};

import { assetType } from "@/constants/assets.constants";
import { defaultQsOptions } from "@/services/constants";
import { buildPageableUrl } from "@/utils/urlUtils";
import qs from "qs";
import { axiosService } from "../axios";
const getBaseUrl = projectId => `/api/projects/${projectId}/asset-permissions/`;

const mapAssetTypeToUrl = at => {
    switch (at) {
        case assetType.partition:
            return "partitions";
        case assetType.integration:
            return "integrations";
        case assetType.dataUpload:
            return "data-uploads";
        case assetType.workflow:
            return "workflows";
        case assetType.scheduler:
            return "schedulers";
        case assetType.assetGroup:
            return "asset-groups";
        default:
            throw new Error(`Unknown asset type: ${at}`);
    }
};

const accountFilterMapping = {
    accountPermissions: "permissionsFilter",
    permissions: "subPermissionsFilter",
};

const assetFilterMapping = {
    permissions: "permissionsFilter",
    subPermissions: "subPermissionsFilter",
};

const extractPermissionValue = (columnName, filter) => {
    if (!filter?.criteria) return undefined;
    const subPermissionCriteria = filter.criteria.find(
        c => c.fieldName === columnName,
    );
    return subPermissionCriteria?.value ?? null;
};

const extractPermissions = (mapping, { filter, ...rest }) => {
    if (filter?.criteria) {
        const { criteria } = filter;
        const restCriteria = criteria.filter(
            c => !Object.keys(mapping).includes(c.fieldName),
        );
        return {
            ...Object.keys(mapping).reduce(
                (acc, columnName) => ({
                    ...acc,
                    [mapping[columnName]]: extractPermissionValue(
                        columnName,
                        filter,
                    ),
                }),
                {},
            ),
            filter: restCriteria.length
                ? { ...filter, criteria: restCriteria }
                : undefined,
            ...rest,
        };
    }
    return rest;
};

export const permissionsAssetsService = {
    fetchProjects({ page, size, sort, filter, userIds = [] } = {}) {
        return axiosService.post(
            buildPageableUrl("/api/admin/projects", page, size, sort),
            extractPermissions(accountFilterMapping, {
                filter,
                userIds,
            }),
        );
    },
    fetchPartitions({
        accountId,
        page,
        size,
        sort,
        filter,
        userIds = [],
    } = {}) {
        return axiosService.post(
            buildPageableUrl(
                getBaseUrl(accountId) + "partitions",
                page,
                size,
                sort,
            ),
            extractPermissions(assetFilterMapping, {
                filter,
                userIds,
            }),
        );
    },
    fetchSubAssets({
        isLocalFiltered = false,
        accountId,
        assetId,
        assetType,
        parentAssetType,
        page,
        size,
        sort,
        filter,
        userIds = [],
    } = {}) {
        const url = `${getBaseUrl(
            accountId,
        )}${parentAssetType}s/${assetId}/${mapAssetTypeToUrl(assetType)}`;
        return axiosService.post(
            isLocalFiltered ? url : buildPageableUrl(url, page, size, sort),
            extractPermissions(assetFilterMapping, {
                filter,
                userIds,
            }),
        );
    },

    fetchIntegrations({
        accountId,
        page,
        size,
        sort,
        filter,
        userIds = [],
    } = {}) {
        return axiosService.post(
            buildPageableUrl(
                getBaseUrl(accountId) + "integrations",
                page,
                size,
                sort,
            ),
            extractPermissions(assetFilterMapping, {
                filter,
                userIds,
            }),
        );
    },

    fetchDataUploads({
        accountId,
        page,
        size,
        sort,
        filter,
        userIds = [],
    } = {}) {
        return axiosService.post(
            buildPageableUrl(
                getBaseUrl(accountId) + "data-uploads",
                page,
                size,
                sort,
            ),
            extractPermissions(assetFilterMapping, {
                filter,
                userIds,
            }),
        );
    },
    fetchWorkflows({ accountId, userIds = [] } = {}) {
        return axiosService.post(getBaseUrl(accountId) + "workflows", {
            userIds,
        });
    },
    fetchSchedulers({ accountId, userIds = [] } = {}) {
        return axiosService.post(getBaseUrl(accountId) + "schedulers", {
            userIds,
        });
    },
    fetchAssetGroups({ accountId, userIds = [] } = {}) {
        return axiosService.post(getBaseUrl(accountId) + "asset-groups", {
            userIds,
        });
    },
    fetchParentEntityName({ accountId, entityType, entityId } = {}) {
        const queryStr = qs.stringify(
            { type: entityType, entityId },
            defaultQsOptions,
        );
        return axiosService.get(
            getBaseUrl(accountId) + `parentEntityName${queryStr}`,
        );
    },
};

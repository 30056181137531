import { Button, Forms } from "@/components/DesignSystem";
import {
    isPastDate,
    isValidExpirationDateValidator,
} from "@/components/Integrations/EditInstanceForm";
import { EnvironmentStatusComponent } from "@/components/Integrations/NewProvisionedInstanceForm/EnvironmentStatusComponent";
import { Hidden } from "@/components/Mappers/ExportMapper/ExportMapperList";
import { DeleteRowButton } from "@/components/Mappers/MapperTableWithCustomization/components/DeleteRowButton";
import { getLoadableSelectProps } from "@/components/Packages/PackageTableDefinitionPanel/components/ObjectTypeSelector/EntityNameSelector";
import { isLoading } from "@/modules/loadable";
import { t } from "@/translations";
import { map } from "lodash/fp";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { ReactComponent as ArrowUp } from "@pricefx/unity-components/src/icons/unicons/arrow-up.svg";
import { isNewer } from "@/utils/versionUtils";

export const IM_ENV_TIER_DIVIDER_STRING = " / ";

const uniqueEnvNameValidator = async (value, getBag) => {
    const { values } = await getBag();

    return Object.values(values.environments).filter(e => e.envName === value)
        .length > 1
        ? Forms.error(t("general.validation.duplicated-value"))
        : Forms.success();
};

const { isRequired } = Forms.pmValidators;

const toOptions = map(({ name }) => ({
    value: name,
    label: name,
}));

const isCustomTier = ({
    tiers,
    tierValue,
    initialValueCpu,
    initialValueMem,
}) => {
    if ((!initialValueCpu || !initialValueMem) && !tierValue) {
        return false;
    }

    const [cpuToCheck, memToCheck] = tierValue
        ? tierValue.split(IM_ENV_TIER_DIVIDER_STRING)
        : [initialValueCpu, initialValueMem];
    // Check if this combination exists in standard tiers
    return !tiers.some(
        ({ cpuSize, memSize }) =>
            cpuSize === cpuToCheck && memSize === memToCheck,
    );
};

const toTierOptions = ({
    tiers,
    initialValueCpu,
    initialValueMem,
    includeCustomTier,
}) => {
    const tierOptions = tiers.map(({ cpuSize, memSize }) => ({
        value: `${cpuSize}${IM_ENV_TIER_DIVIDER_STRING}${memSize}`,
        label: `${cpuSize}${IM_ENV_TIER_DIVIDER_STRING}${memSize}`,
    }));

    const customTierValue =
        initialValueCpu && initialValueMem
            ? `${initialValueCpu}${IM_ENV_TIER_DIVIDER_STRING}${initialValueMem}`
            : null;

    const hasCustomTier = isCustomTier({
        tiers,
        initialValueCpu,
        initialValueMem,
    });

    if (includeCustomTier && customTierValue && hasCustomTier) {
        const customTierOption = {
            value: customTierValue,
            label: `${customTierValue} (Custom)`,
            style: { color: "#e00031" },
        };
        return [customTierOption, ...tierOptions];
    }

    return tierOptions;
};

const getDefaultTierValue = tiers => {
    const defaultTier = tiers.find(tier => tier.isDefault);
    return defaultTier
        ? `${defaultTier.cpuSize}${IM_ENV_TIER_DIVIDER_STRING}${defaultTier.memSize}`
        : "";
};

const canDeleteEnv = field =>
    field("canDeleteEnv")?.initialValue === undefined ||
    field("canDeleteEnv")?.initialValue;

export const isEnvironmentUpgradeable = ({
    version,
    integrationHighestVersion,
    status,
}) => status === "MATCHED" && isNewer(integrationHighestVersion, version);

export const ProvisionedInstanceEnvironmentRow = ({
    canSetAdvancedOptions,
    disableEnvsEdit,
    environmentOptionsLoadable,
    fieldProps,
    hasPermissionToSetExpiration,
    id,
    initialValuesByName,
    instanceGroupName,
    instancesDeleteMutation,
    instancesRestartMutation,
    integrationVersionsOptions,
    isEnvironmentUpgradeable,
    isNewInstance,
    newEnvVersion,
    onUpdate,
    remove,
    rows,
    setModifiedExistingEnvs,
    storages,
    tiers,
}) => {
    const isExisting = initialValuesByName[id]?.["isExisting"];
    const instanceId = initialValuesByName[id]?.["instanceId"];
    const status = initialValuesByName[id]?.["status"];
    const envName = initialValuesByName[id]?.["envName"];
    const forceDeletable = initialValuesByName[id]?.["forceDeletable"];
    const initialValueCpu = initialValuesByName[id]?.["cpu"];
    const initialValueMem = initialValuesByName[id]?.["mem"];
    const initialValueStorage = initialValuesByName[id]?.["storage"];
    const initialValueVersion = initialValuesByName[id]?.["version"];
    const storageValue = Forms.useFieldValue({
        name: fieldProps("storage").name,
    });
    const tierValue = Forms.useFieldValue({
        name: fieldProps("tier").name,
    });

    const isStorageChanged = initialValueStorage !== storageValue;
    const tierOptions = toTierOptions({
        tiers,
        initialValueCpu,
        initialValueMem,
        includeCustomTier: !isStorageChanged,
    });

    const hasCustomTier = isCustomTier({
        tiers,
        initialValueCpu,
        initialValueMem,
    });

    const isCustomTierSelected = isCustomTier({
        tiers,
        tierValue,
    });

    useEffect(() => {
        if (isExisting && hasCustomTier) {
            setModifiedExistingEnvs(prevEnvs => ({
                ...prevEnvs,
                [id]: isStorageChanged && isCustomTierSelected,
            }));
        }
    }, [
        hasCustomTier,
        id,
        isCustomTierSelected,
        isExisting,
        isStorageChanged,
        setModifiedExistingEnvs,
    ]);

    return (
        <Forms.FieldGrid.Row key={id}>
            <Hidden {...fieldProps("isExisting")} />
            <Forms.Fields.Select
                label={t("instance-form.label.environment")}
                {...fieldProps("envName")}
                required
                validator={Forms.validators.failOnFirst([
                    isRequired,
                    uniqueEnvNameValidator,
                ])}
                {...getLoadableSelectProps(environmentOptionsLoadable)}
                disabled={disableEnvsEdit || isExisting}
                allowClear={false}
            />
            <Forms.Fields.Select
                required
                label={t("instance-form.label.tier")}
                {...fieldProps("tier")}
                options={tierOptions}
                validator={Forms.validators.failOnFirst([
                    Forms.pmValidators.isRequired,
                    Forms.pmValidators.createAllowedValuesValidator(
                        tierOptions.map(item => item.value) ?? [],
                        t("general.error.unsupported-value"),
                    ),
                ])}
                disabled={
                    !canSetAdvancedOptions || (disableEnvsEdit && isExisting)
                }
                initialValue={getDefaultTierValue(tiers)}
                allowClear={false}
            />
            <Forms.Fields.Select
                required
                label={t("instance-form.label.storage")}
                {...fieldProps("storage")}
                options={toOptions(storages)}
                validator={isRequired}
                disabled={
                    !canSetAdvancedOptions || (disableEnvsEdit && isExisting)
                }
                initialValue={storages[0]?.name}
                allowClear={false}
            />
            <Forms.Fields.DatePicker
                label={t("instance-form.label.expiration")}
                {...fieldProps("expiration")}
                disabled={
                    !canSetAdvancedOptions ||
                    isExisting ||
                    !hasPermissionToSetExpiration
                }
                disabledDate={isPastDate}
                validator={Forms.pmValidators.noValueOr(
                    isValidExpirationDateValidator,
                )}
            />
            {isNewInstance ? (
                <Forms.Fields.ReadOnlyValue
                    label={t("instance-form.label.version")}
                    {...fieldProps("version")}
                    defaultValue={newEnvVersion}
                />
            ) : (
                <Forms.Fields.Select
                    required
                    {...fieldProps("version")}
                    label={t("instance-form.label.version")}
                    showSearch
                    validator={
                        !isExisting
                            ? Forms.validators.failOnFirst([
                                  Forms.pmValidators.isRequired,
                                  !isLoading(integrationVersionsOptions) &&
                                      Forms.pmValidators.createAllowedValuesValidator(
                                          integrationVersionsOptions
                                              .valueMaybe()
                                              ?.map(item => item.value) ?? [],
                                          t("general.error.unsupported-value"),
                                      ),
                              ])
                            : undefined
                    }
                    disabled={isExisting}
                    allowClear={false}
                    fieldSuffix={
                        isEnvironmentUpgradeable({
                            version: initialValueVersion,
                            status,
                        }) && (
                            <Button
                                onClick={() => onUpdate({ instanceId })}
                                icon={ArrowUp}
                                type="tertiary"
                                tooltip={t("instance-form.button.upgrade")}
                            />
                        )
                    }
                    {...getLoadableSelectProps(integrationVersionsOptions)}
                />
            )}
            <Forms.UIField name="notes" label={t("instance-form.label.notes")}>
                <EnvironmentStatusComponent
                    status={status}
                    envName={envName}
                    instanceId={instanceId}
                    instanceGroupName={instanceGroupName}
                    forceDeletable={forceDeletable}
                    instancesRestartMutation={instancesRestartMutation}
                    instancesDeleteMutation={instancesDeleteMutation}
                />
            </Forms.UIField>
            <Forms.UIField name="delete">
                <DeleteRowButton
                    danger
                    onClick={() => remove(id)}
                    disabled={rows.length <= 1 || !canDeleteEnv(fieldProps)}
                />
            </Forms.UIField>
        </Forms.FieldGrid.Row>
    );
};

ProvisionedInstanceEnvironmentRow.propTypes = {
    canSetAdvancedOptions: PropTypes.bool,
    disableEnvsEdit: PropTypes.bool,
    environmentOptionsLoadable: PropTypes.object.isRequired,
    fieldProps: PropTypes.func.isRequired,
    getRowBag: PropTypes.func.isRequired,
    hasPermissionToSetExpiration: PropTypes.bool,
    id: PropTypes.string.isRequired,
    initialValuesByName: PropTypes.object.isRequired,
    instanceGroupName: PropTypes.string,
    instancesDeleteMutation: PropTypes.func.isRequired,
    instancesRestartMutation: PropTypes.func.isRequired,
    integrationVersionsOptions: PropTypes.object.isRequired,
    isEnvironmentUpgradeable: PropTypes.func.isRequired,
    isNewInstance: PropTypes.bool,
    newEnvVersion: PropTypes.string,
    onUpdate: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
    rows: PropTypes.array.isRequired,
    setModifiedExistingEnvs: PropTypes.func.isRequired,
    storages: PropTypes.array.isRequired,
    tiers: PropTypes.array.isRequired,
};

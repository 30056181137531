import { useSyncRowSelection } from "@/components/DesignSystem/Table/hooks/useSyncRowSelection.hook";
import { createColumns } from "@/components/Permissions/UsersAssets/AssetsTable.columns";
import { TableLocalFiltered } from "@/components/TableLocalFiltered";
import PropTypes from "prop-types";
import React, { useCallback, useMemo } from "react";

const TABLE_PROPS = {
    fixed: true,
    fixedHeight: 325,
};

// @deorecated - will be removed after groups migration
export const AssetsTable = React.memo(function AssetsTable({
    assets,
    assetType,
    selectionChange,
    onAssetClick,
    selectedAssets,
    hasRights,
    tableProps,
}) {
    const rowSelection = useSyncRowSelection(
        selectedAssets[assetType],
        selectionChange,
        [assetType],
    );

    // entityType missing for projects, investigate
    const rowHighlights = useCallback(
        ({ entityType, id }) => (hasRights?.(id, assetType) ? "green" : false),
        [assetType, hasRights],
    );

    const columns = createColumns(onAssetClick);
    const expandedRowRenderProps = useMemo(
        () =>
            assetType === "project"
                ? {
                      pmExpandable: {
                          expandedRowHeight: 670,
                          expandedRowRender: record => (
                              <div
                                  style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      width: "100%",
                                  }}
                              >
                                  <AssetsTable
                                      assetType="partition"
                                      assets={record.partitions}
                                      onAssetClick={onAssetClick}
                                      selectionChange={selectionChange}
                                      selectedAssets={selectedAssets}
                                      hasRights={hasRights}
                                      tableProps={TABLE_PROPS}
                                  />
                                  <AssetsTable
                                      assetType="integration"
                                      assets={record.integrations}
                                      onAssetClick={onAssetClick}
                                      selectionChange={selectionChange}
                                      selectedAssets={selectedAssets}
                                      hasRights={hasRights}
                                      tableProps={TABLE_PROPS}
                                  />
                              </div>
                          ),
                      },
                  }
                : {},
        [assetType, hasRights, onAssetClick, selectedAssets, selectionChange],
    );

    return (
        <TableLocalFiltered
            hasColumnAutofit
            hasColumnResizing
            dataSource={assets}
            rowKey="id"
            columns={columns[assetType]}
            rowSelection={rowSelection}
            rowHighlights={rowHighlights}
            {...expandedRowRenderProps}
            {...tableProps}
        />
    );
});

AssetsTable.propTypes = {
    assets: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
    assetType: PropTypes.oneOf(["project", "partition", "integration"])
        .isRequired,
    selectionChange: PropTypes.func.isRequired,
    onAssetClick: PropTypes.func.isRequired,
    selectedAssets: PropTypes.shape({
        project: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
        partition: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
        integration: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
    }).isRequired,
    hasRights: PropTypes.func,
};

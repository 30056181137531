import { Alert, Gap, Link, Popover } from "@/components/DesignSystem";
import { t } from "@/translations";
import React from "react";
import PropTypes from "prop-types";

export const EnvironmentStatusComponent = ({
    status,
    envName,
    instanceGroupName,
    instanceId,
    forceDeletable,
    instancesRestartMutation,
    instancesDeleteMutation,
}) => {
    switch (status) {
        case "DELETE_FAILED":
        case "DELETE_REQUESTED":
        case "REQUESTED":
        case "MISSING_IN_GIT":
            return (
                <Popover
                    title={t("instance-form.status.delete-failed")}
                    content={
                        <>
                            {t(
                                "instance-form.status.delete-failed.popover.text",
                            )}
                            {forceDeletable && (
                                <>
                                    {t(
                                        "instance-form.status.delete-failed.popover.text2",
                                    )}
                                    <Gap size="medium" />
                                    {
                                        <Link
                                            danger
                                            onClick={() =>
                                                instancesDeleteMutation.mutate({
                                                    instanceId,
                                                    queryParams: {
                                                        forceDelete: true,
                                                    },
                                                })
                                            }
                                        >
                                            {t(
                                                "instance-list.menu.force-delete",
                                            )}
                                        </Link>
                                    }
                                </>
                            )}
                        </>
                    }
                    overlayStyle={{ width: "230px" }}
                >
                    {" "}
                    <Alert
                        type="warning"
                        message={t("instance-form.status.delete-failed")}
                    />
                </Popover>
            );
        case "MISSING_IN_PLATFORM":
            return (
                <Popover
                    title={t("instance-form.status.missing-in-git")}
                    content={
                        <>
                            {t(
                                "instance-form.status.missing-in-git.popover.text",
                            )}
                            <Gap size="medium" />
                            {
                                <Link
                                    onClick={() =>
                                        instancesRestartMutation.mutate({
                                            instanceName: `${instanceGroupName}-${envName}`,
                                            type: envName,
                                        })
                                    }
                                >
                                    {t(
                                        "instance-form.status.missing-in-git.popover.link",
                                    )}
                                </Link>
                            }
                        </>
                    }
                    overlayStyle={{ width: "230px" }}
                >
                    {" "}
                    <Alert
                        type="warning"
                        message={t("instance-form.status.missing-in-git")}
                    />
                </Popover>
            );
        default:
            return null;
    }
};

EnvironmentStatusComponent.propTypes = {
    status: PropTypes.string.isRequired,
    envName: PropTypes.string.isRequired,
    instanceGroupName: PropTypes.string.isRequired,
    instanceId: PropTypes.string.isRequired,
    forceDeletable: PropTypes.bool,
    instancesRestartMutation: PropTypes.shape({
        mutate: PropTypes.func.isRequired,
    }).isRequired,
    instancesDeleteMutation: PropTypes.shape({
        mutate: PropTypes.func.isRequired,
    }).isRequired,
};

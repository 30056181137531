import { Button, Forms, Gap } from "@/components/DesignSystem";
import { DeleteRowButton } from "@/components/Mappers/MapperTableWithCustomization/components/DeleteRowButton";
import { ReactComponent as Plus } from "@pricefx/unity-components/src/icons/unicons/plus.svg";
import PropTypes from "prop-types";
import React from "react";

const LAYOUT = [{}, {}, { width: 32 }];

export const EventFilterList = ({ formId, name, initialValue, disabled }) => {
    return (
        <Forms.FieldGrid layout={LAYOUT}>
            <Forms.List formId={formId} name={name} initialValue={initialValue}>
                {({ rows, add, remove }) => (
                    <>
                        {rows.map(({ id, fieldProps }) => (
                            <Forms.FieldGrid.Row key={id}>
                                <Forms.Fields.Input
                                    {...fieldProps("jsonPath")}
                                    label={"Parameter"}
                                    validator={Forms.validators.failOnFirst([
                                        Forms.pmValidators.isRequired,
                                        Forms.pmValidators.notBlank(),
                                        Forms.pmValidators.isJsonPath({
                                            tsKey: "general.validation.is-json-path",
                                        }),
                                    ])}
                                />
                                <Forms.Fields.Input
                                    {...fieldProps("value")}
                                    label={"Value"}
                                    validator={Forms.pmValidators.isRequired}
                                />
                                <Forms.UIField name="actions">
                                    <DeleteRowButton
                                        disabled={disabled}
                                        onClick={() => remove(id)}
                                    />
                                </Forms.UIField>
                            </Forms.FieldGrid.Row>
                        ))}
                        <Gap size="small" />
                        <Button
                            disabled={disabled}
                            size="small"
                            icon={Plus}
                            label={"Add filter"}
                            onClick={() => add()}
                        />
                    </>
                )}
            </Forms.List>
            <Gap />
        </Forms.FieldGrid>
    );
};

EventFilterList.propTypes = {
    formId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    initialValue: PropTypes.array,
};

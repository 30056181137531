import { Drawer, Gap, Table, Tabs, Text } from "@/components/DesignSystem";
import { useSyncRowSelection } from "@/components/DesignSystem/Table/hooks/useSyncRowSelection.hook";
import styles from "@/components/Permissions/AccountAssetAdminSettings/accountAdminSettings.style.less";
import {
    assetsColumns,
    instanceColumns,
    partitionColumns,
} from "@/components/Permissions/AccountAssetAdminSettings/AssetAdminSettings.columns";
import { AssetRoleModal } from "@/components/Permissions/AccountAssetAdminSettings/AssetRoleModal.component";
import { AssetRolesTable } from "@/components/Permissions/AccountAssetAdminSettings/AssetRolesTable/AssetRolesTable.component";
import { EntityType } from "@/constants/CommonConstats";
import { T, t } from "@/translations";
import { produce } from "immer";
import isEmpty from "lodash/isEmpty";
import union from "lodash/union";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";

const TABS = {
    ASSETS: "assets",
};

const mapAsset = asset => ({
    entityId: asset.id,
    type: asset.entityType,
});

const handleOnCheckAll = (assets, checked, selectedRole, assetType) => {
    const unchangedAssets = selectedRole.assets.filter(
        a => a.type !== assetType,
    );

    return produce(selectedRole, draft => {
        draft.assets = union(
            assets.filter(a => checked.includes(a.id)).map(mapAsset),
            unchangedAssets,
        );
    });
};

const entityIdToNumber = role => {
    return produce(role, draft => {
        draft.assets = role.assets.map(a => ({
            ...a,
            entityId: parseInt(a.entityId, 10),
        }));
    });
};

export const AssetAdminSettingsComponent = ({
    data,
    onDrawerOpen,
    onDrawerClose,
    onDrawerSave,
    visibleModal,
    drawerVisible,
    onModalCancel,
    onModalOk,
    selectedRole,
    onDeleteAssetRole,
    onRoleUpdate,
}) => {
    const [updatedRecord, setUpdatedRecord] = useState(selectedRole);
    const [editModalVisible, setEditModalVisible] = useState(false);

    useEffect(() => {
        setUpdatedRecord(entityIdToNumber(selectedRole));
    }, [drawerVisible, selectedRole]);

    const onEditModalOpen = role => {
        setEditModalVisible(true);
        setUpdatedRecord(role);
    };

    const onEditModalCancel = () => setEditModalVisible(false);

    const onEditModalSave = role => {
        setEditModalVisible(false);
        onRoleUpdate(role);
    };

    const projectSelection = useSyncRowSelection(
        updatedRecord.assets
            .filter(p => p.type === EntityType.PROJECT)
            .map(p => p.entityId),
        selectedRowKeys => {
            setUpdatedRecord(
                handleOnCheckAll(
                    [data.project],
                    selectedRowKeys,
                    updatedRecord,
                    EntityType.PROJECT,
                ),
            );
        },
    );

    const partitionSelection = useSyncRowSelection(
        updatedRecord.assets
            .filter(p => p.type === EntityType.PARTITION)
            .map(p => p.entityId),
        selectedRowKeys => {
            setUpdatedRecord(
                handleOnCheckAll(
                    data.partitions,
                    selectedRowKeys,
                    updatedRecord,
                    EntityType.PARTITION,
                ),
            );
        },
    );

    const integrationSelection = useSyncRowSelection(
        updatedRecord.assets
            .filter(p => p.type === EntityType.INTEGRATION)
            .map(p => p.entityId),
        selectedRowKeys => {
            setUpdatedRecord(
                handleOnCheckAll(
                    data.integrations,
                    selectedRowKeys,
                    updatedRecord,
                    EntityType.INTEGRATION,
                ),
            );
        },
    );

    const expandedRowRender = useCallback(() => {
        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                }}
            >
                {!isEmpty(data.partitions) && (
                    <Table
                        rowKey="id"
                        columns={partitionColumns()}
                        dataSource={data.partitions}
                        rowSelection={partitionSelection}
                        pagination={false}
                        fixed
                        fixedHeight={325}
                    />
                )}
                {!isEmpty(data.integrations) && (
                    <Table
                        rowKey="id"
                        columns={instanceColumns()}
                        rowSelection={integrationSelection}
                        dataSource={data.integrations}
                        pagination={false}
                        fixed
                        fixedHeight={325}
                    />
                )}
            </div>
        );
    }, [
        partitionSelection,
        integrationSelection,
        data.integrations,
        data.partitions,
    ]);

    return (
        data && (
            <>
                <AssetRolesTable
                    onAssetRoleClick={onDrawerOpen}
                    onAssetRoleDelete={onDeleteAssetRole}
                    dataSource={data.assetRoles}
                    onEditModalOpen={onEditModalOpen}
                    onDrawerClose={onDrawerClose}
                />
                <Drawer
                    visible={drawerVisible}
                    onClose={onDrawerClose}
                    okButtonProps={{
                        onClick: () => onDrawerSave(updatedRecord),
                        children: <T id="general.save" />,
                    }}
                    cancelButtonProps={{ onClick: onDrawerClose }}
                    panel
                >
                    <Tabs defaultActiveKey={TABS.ASSETS}>
                        <Tabs.TabPane
                            tab={
                                <T id="account-admin-asset-role.asset-list.header" />
                            }
                            key={TABS.ASSETS}
                        >
                            <Text className={styles.description} type="small">
                                {updatedRecord.description}
                            </Text>
                            <Gap size="x-large" />
                            <Table
                                rowKey="id"
                                className={styles.accountTable}
                                columns={assetsColumns()}
                                dataSource={[data.project]}
                                pmExpandable={{
                                    expandedRowHeight: 670,
                                    expandedRowRender,
                                }}
                                pagination={false}
                                defaultExpandAllRows
                                rowSelection={projectSelection}
                            />
                        </Tabs.TabPane>
                    </Tabs>
                </Drawer>
                <AssetRoleModal
                    visible={visibleModal}
                    onCancel={onModalCancel}
                    onOk={onModalOk}
                />
                <AssetRoleModal
                    visible={editModalVisible}
                    onCancel={onEditModalCancel}
                    onOk={onEditModalSave}
                    role={updatedRecord}
                    okText={t("general.save")}
                    title={t("account-admin-asset-role-list.modal.title-edit")}
                />
            </>
        )
    );
};

AssetAdminSettingsComponent.propTypes = {
    data: PropTypes.object.isRequired,
    visibleModal: PropTypes.bool.isRequired,
    onModalCancel: PropTypes.func.isRequired,
    onDrawerOpen: PropTypes.func.isRequired,
    onModalOk: PropTypes.func.isRequired,
    onDeleteAssetRole: PropTypes.func.isRequired,
    onRoleUpdate: PropTypes.func.isRequired,
};

import WarningAlert from "@/components/Alerts/WarningAlert";
import {
    Button,
    ButtonMenu,
    Gap,
    H4,
    Modal,
    UnityLayout,
} from "@/components/DesignSystem";
import { ActionButton } from "@/components/DesignSystem/Table/components/ActionButton/ActionButton";
import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { useSyncRowSelection } from "@/components/DesignSystem/Table/hooks/useSyncRowSelection.hook";
import { useVisibility } from "@/components/hooks/useVisibility.hook";
import {
    useAddGroupUsersResource,
    useRemoveGroupUserResource,
} from "@/components/Permissions/Groups/loadables";
import { TableLocalFiltered } from "@/components/TableLocalFiltered";
import { useUserBasicInfo } from "@/security/hooks/useUserBasicInfo.hook";
import { t } from "@/translations";
import some from "lodash/fp/some";
import React, { useMemo, useState } from "react";

export const createGroupUsersColumns = ({ removeUserFromGroup } = {}) => [
    {
        type: fieldTypes.TEXT,
        label: t("group-detail.header.login"),
        name: "username",
        render: (text, record) => (
            <>
                {text}
                {removeUserFromGroup && (
                    <ActionButton
                        record={record}
                        items={[
                            {
                                title: t("group-detail.title.remove-user"),
                                confirmMessage: t(
                                    "group-detail.message.remove-user",
                                    {
                                        userName: record.fullName,
                                    },
                                ),
                                // onConfirm: () => removeUserFromGroup(record.id),
                                // TODO remove workaround below https://pricefx.atlassian.net/browse/PFIM-4294
                                onConfirm: () => {
                                    removeUserFromGroup(record.id);
                                },
                                color: "red",
                            },
                        ]}
                    />
                )}
            </>
        ),
    },
    {
        type: fieldTypes.TEXT,
        label: t("group-detail.header.id"),
        name: "id",
        width: 60,
        className: "pmTable__column--withActionButton",
    },

    {
        label: t("group-detail.header.full-name"),
        name: "fullName",
        filterable: fieldTypes.ENTITY_REFERENCE,
    },
    {
        label: t("group-detail.header.email"),
        name: "email",
        filterable: fieldTypes.ENTITY_REFERENCE,
    },
];

const AddGroupUserModal = ({
    visible,
    onClose,
    group,
    groupAllowedUsers,
    reload,
}) => {
    const [selected, setSelected] = useState([]);
    const rowSelection = useSyncRowSelection(selected, setSelected);
    const addUserToGroupsResource = useAddGroupUsersResource({
        groupName: group?.name,
        afterSuccess: () => {
            setSelected([]);
            reload();
        },
    });
    const handleSubmit = () => addUserToGroupsResource.mutate(selected);
    const userGroupsColumns = useMemo(() => createGroupUsersColumns(), []);

    return (
        <Modal visible={visible} onClose={onClose}>
            <UnityLayout>
                <UnityLayout.Header
                    size={3}
                    title={t("user-detail.groups.add.title")}
                />
                <UnityLayout.Content padding={[true, true]}>
                    <TableLocalFiltered
                        columns={userGroupsColumns}
                        dataSource={groupAllowedUsers}
                        pagination={false}
                        rowKey="id"
                        rowSelection={rowSelection}
                        loading={
                            addUserToGroupsResource.loadable.state === "loading"
                        }
                    />
                </UnityLayout.Content>
                <UnityLayout.Footer
                    actionButtons={
                        <ButtonMenu
                            buttons={[
                                {
                                    label: t("general.add"),
                                    onClick: handleSubmit,
                                    type: "primary",
                                    disabled:
                                        !selected.length ||
                                        addUserToGroupsResource.loadable
                                            .state === "loading",
                                },
                                {
                                    label: t("general.cancel"),
                                    onClick: onClose,
                                    type: "text",
                                },
                            ]}
                        />
                    }
                />
            </UnityLayout>
        </Modal>
    );
};

export const GroupUsers = ({
    group,
    groupUsers,
    groupAllowedUsers,
    canEdit,
    reload,
}) => {
    const basicInfo = useUserBasicInfo();
    const userGroups = basicInfo.userInfo.user.groups;

    const removeUserFromGroupResource = useRemoveGroupUserResource({
        groupName: group?.name,
        afterSuccess: reload,
    });

    const userGroupsColumns = useMemo(
        () =>
            createGroupUsersColumns({
                removeUserFromGroup: canEdit
                    ? removeUserFromGroupResource.mutate
                    : undefined,
            }),
        [canEdit, removeUserFromGroupResource.mutate],
    );

    const addGroupUserModal = useVisibility(false);

    return (
        <>
            <H4>{t("group-detail.nav-bar.assigned-users")}</H4>
            <WarningAlert
                message={t("group-detail.alert.assigned-users")}
                visible={some(({ name }) => name === group.name, userGroups)}
            />
            <Gap size="small" />
            <TableLocalFiltered
                ExportButton={() => null}
                padding={false}
                columns={userGroupsColumns}
                dataSource={groupUsers}
                pagination={false}
                rowKey="id"
                loading={
                    removeUserFromGroupResource.loadable.state === "loading"
                }
            />
            <Gap size="small" />
            {canEdit && (
                <Button
                    onClick={addGroupUserModal.show}
                    label={t("general.add-user")}
                />
            )}
            <AddGroupUserModal
                visible={addGroupUserModal.visible}
                onClose={addGroupUserModal.hide}
                group={group}
                groupAllowedUsers={groupAllowedUsers}
                reload={reload}
            />
        </>
    );
};

import {
    businessRoleChanges,
    permissionChanges,
} from "@/components/Permissions/AccountUserAdminSettings/RightsDrawer/PermissionsDrawer/applyRightsChanges";
import { useBusinessRoles } from "@/components/Permissions/AccountUserAdminSettings/RightsDrawer/PermissionsDrawer/hooks/useBusinessRoles";
import { usePermissions } from "@/components/Permissions/AccountUserAdminSettings/RightsDrawer/PermissionsDrawer/hooks/usePermissions.hook";
import {
    PermissionsDrawer,
    TABS,
} from "@/components/Permissions/AccountUserAdminSettings/RightsDrawer/PermissionsDrawer/PermissionsDrawer.component";
import {
    ADMIN_KIND,
    ASSET_KIND,
    ENTITY_KIND,
} from "@/components/Permissions/AccountUserAdminSettings/RightsDrawer/PermissionsDrawer/sources.utils";
import { logger } from "@/modules/logger";
import PropTypes from "prop-types";
import React, { useCallback } from "react";

const WF_PERMISSIONS = ["alerts.edit", "provisioning.partition"];

export const doAfterDrawerClose = fn => setTimeout(fn, 300);
export const PermissionsDrawerContainer = React.memo(
    ({
        title,
        description,
        onSave,
        onCancel,
        onClose,
        visible,
        selectedEntities,
        selectedAssets,
        adminKind,
        entityKind,
        assetKind,
        rights,
        workflowRights = [],
        groupedPermissions,
        businessRoles,
    }) => {
        const permissionsUi = usePermissions(
            adminKind,
            entityKind,
            assetKind,
            rights,
            workflowRights,
            selectedEntities,
            selectedAssets,
            groupedPermissions,
        );

        const businessRolesUi = useBusinessRoles(
            adminKind,
            entityKind,
            assetKind,
            rights,
            workflowRights,
            selectedEntities,
            selectedAssets,
            businessRoles,
        );

        logger.debug({
            logGroupKey: ["PERMISSIONS", "PermissionsDrawerContainer"],
            color: "deepskyblue",
            data: {
                props: {
                    title,
                    description,
                    onSave,
                    onCancel,
                    onClose,
                    visible,
                    selectedEntities,
                    selectedAssets,
                    adminKind,
                    entityKind,
                    assetKind,
                    rights,
                    workflowRights,
                    groupedPermissions,
                    businessRoles,
                },
                permissionsUi,
                businessRolesUi,
            },
        });
        const onSaveClick = useCallback(
            activeTab => {
                const rightsChanges =
                    activeTab === TABS.PERMISSIONS
                        ? permissionChanges(
                              permissionsUi.permissionChanges,
                              selectedEntities,
                              selectedAssets,
                              { adminKind, entityKind, assetKind },
                              workflowRights,
                          )
                        : businessRoleChanges(
                              businessRolesUi.businessRolesChanges,
                              selectedEntities,
                              selectedAssets,
                              { adminKind, entityKind, assetKind },
                              workflowRights,
                          );
                onSave(rightsChanges);
            },
            [
                onSave,
                selectedAssets,
                selectedEntities,
                permissionsUi.permissionChanges,
                businessRolesUi.businessRolesChanges,
                adminKind,
                entityKind,
                assetKind,
                workflowRights,
            ],
        );
        const reset = useCallback(() => {
            permissionsUi.reset();
            businessRolesUi.reset();
        }, [permissionsUi.reset, businessRolesUi.reset]);
        const onPermissionsCancel = useCallback(() => {
            reset();
            onCancel();
        }, [reset, onCancel]);
        const onPermissionsClose = useCallback(() => {
            reset();
            onClose();
        }, [reset, onClose]);
        const isWFPermission = useCallback(
            record =>
                assetKind === ASSET_KIND.SUPER_ASSET ||
                WF_PERMISSIONS.includes(record.name),
            [assetKind],
        );

        return (
            <PermissionsDrawer
                title={title}
                description={description}
                onPermissionChange={permissionsUi.onChange}
                permissionsTouched={permissionsUi.dirty}
                permissionsChanges={permissionsUi.permissionChanges}
                groupedPermissions={permissionsUi.groupedPermissions}
                onBusinessRoleChange={businessRolesUi.onChange}
                businessRolesTouched={businessRolesUi.dirty}
                businessRolesChanges={businessRolesUi.businessRolesChanges}
                businessRoles={businessRolesUi.businessRoles}
                onSave={onSaveClick}
                onCancel={onPermissionsCancel}
                onClose={onPermissionsClose}
                reset={reset}
                visible={visible}
                isWFPermission={isWFPermission}
            />
        );
    },
);

PermissionsDrawerContainer.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    onSave: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    adminKind: PropTypes.oneOf(Object.values(ADMIN_KIND)).isRequired,
    entityKind: PropTypes.oneOf(Object.values(ENTITY_KIND)).isRequired,
    assetKind: PropTypes.oneOf(Object.values(ASSET_KIND)).isRequired,
    selectedEntities: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
        PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    ]),
    selectedAssets: PropTypes.oneOfType([
        PropTypes.array.isRequired, // TODO: business roles / superAssets
        PropTypes.shape({
            project: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
            partition: PropTypes.arrayOf(PropTypes.number.isRequired)
                .isRequired,
            integration: PropTypes.arrayOf(PropTypes.number.isRequired)
                .isRequired,
        }).isRequired,
    ]).isRequired,
    rights: PropTypes.array.isRequired,
    workflowRights: PropTypes.array.isRequired,
    visible: PropTypes.bool,
    groupedPermissions: PropTypes.object.isRequired,
    businessRoles: PropTypes.arrayOf(PropTypes.object.isRequired),
};

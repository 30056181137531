import { useDic } from "@/components/Dic/useDic.hook";
import { AssetsTable } from "@/components/Permissions/UsersAssets/AssetsTable.component";
import { preferencesTypes } from "@/constants/preferencesTypes.constants";
import { useQueryLoadable } from "@/modules/loadable";
import { getData } from "@/services/utils";
import PropTypes from "prop-types";
import React, { useImperativeHandle } from "react";

const preferencesType =
    preferencesTypes.SUB_ASSETS_PARTITION_DATA_UPLOADS_TABLE;

export const SubAssetTableLocal = React.memo(
    React.forwardRef((props, ref) => {
        const {
            accountId,
            userIds,
            assetType,
            parentAssetType,
            assetId,
            defaultSort,
        } = props;
        const { permissionsAssetsService } = useDic();

        const query = useQueryLoadable(
            async () =>
                permissionsAssetsService
                    .fetchSubAssets({
                        isLocalFiltered: true,
                        accountId,
                        assetId,
                        assetType,
                        parentAssetType,
                        userIds,
                    })
                    .then(getData),
            [
                accountId,
                assetId,
                assetType,
                parentAssetType,
                permissionsAssetsService,
                userIds,
            ],
        );

        const dataSource = query.loadable.valueMaybe() || [];

        useImperativeHandle(ref, () => ({
            reload: query.reload,
        }));

        return (
            <AssetsTable
                preferencesType={preferencesType}
                assetType={assetType}
                defaultSort={defaultSort}
                datasetSlicing="local"
                dataSource={dataSource}
                {...props}
            />
        );
    }),
);

SubAssetTableLocal.propTypes = {
    accountId: PropTypes.number.isRequired,
    userIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    assetId: PropTypes.number.isRequired,
    assetType: PropTypes.string.isRequired,
    parentAssetType: PropTypes.string.isRequired,
    defaultSort: PropTypes.object.isRequired,
};

import { useDic } from "@/components/Dic/useDic.hook";
import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import {
    UserAbortError,
    useWithConflictModal,
} from "@/components/Mappers/ConflictModal";
import {
    hasValue,
    responseErrorMessage,
    useMutationLoadableWithNotification,
    useQueryLoadable,
} from "@/modules/loadable";
import { CONNECTION_TYPE_PRICEFX_CLIENT } from "@/services/connections.service";
import { getData } from "@/services/utils";
import { identity, noop } from "lodash/fp";

export const useConnectionsOptionsQuery = ({
    instanceId,
    canFetch = true,
    type = CONNECTION_TYPE_PRICEFX_CLIENT,
}) => {
    const { connectionsService } = useDic();

    return useQueryLoadable(
        async () =>
            canFetch
                ? connectionsService
                      .fetchConnectionsFilterByType(instanceId, type)
                      .then(({ data }) =>
                          data.map(item => ({
                              value: item.id,
                              label: item.name,
                              type: item.type,
                          })),
                      )
                : Promise.resolve(undefined),
        [canFetch, connectionsService, instanceId, type],
    );
};
export const useInitialValuesQuery = ({ filterId, isNewFilter }) => {
    const { filtersService } = useDic();
    return useQueryLoadable(
        async () =>
            isNewFilter
                ? undefined
                : filtersService.fetchFilter(filterId).then(getData),
        [filterId, isNewFilter, filtersService],
    );
};

export const useSaveFilterMutation = ({
    filterId,
    instanceId,
    isNewFilter,
    afterSuccess = noop,
    confirmDeployed = false,
}) => {
    const { filtersService } = useDic();
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);
    const withConflictModal = useWithConflictModal();
    const operation = isNewFilter
        ? filtersService.createFilter
        : filtersService.updateFilter;

    return useMutationLoadableWithNotification(
        async ({ values, withDeploy }) => {
            const filter = { ...values, instanceId, filterId };
            const { data } = await withConflictModal(forceCommit =>
                operation(filter, { withDeploy, forceCommit, confirmDeployed }),
            );
            afterSuccessCurrent({
                withDeploy,
                filterId: data.id,
                filterName: values.name,
            });
            return data;
        },
        [
            instanceId,
            filterId,
            withConflictModal,
            afterSuccessCurrent,
            operation,
            confirmDeployed,
        ],
        isNewFilter ? "filter.create" : "filter.edit",
        e => (e instanceof UserAbortError ? null : responseErrorMessage(e)),
    );
};

// COMPONENT
export const useFieldKeysQuery = ({
    objectType,
    objectId,
    entityType,
    entityName,
    canFetch,
    map = identity,
    includeAll = false,
}) => {
    const { metadataService } = useDic();

    return useQueryLoadable(
        async () =>
            canFetch
                ? metadataService
                      .fetchAttributesMetadata(
                          objectType,
                          objectId,
                          entityType,
                          entityName,
                          includeAll ? { includeAll } : undefined,
                      )
                      .then(getData)
                      .then(map)
                : Promise.resolve(undefined),
        [
            canFetch,
            metadataService,
            objectType,
            objectId,
            entityType,
            entityName,
            includeAll,
            map,
        ],
    );
};
useFieldKeysQuery.isIdle = fieldKeysQuery =>
    hasValue(fieldKeysQuery) &&
    fieldKeysQuery.loadable.valueMaybe() === undefined;

export const useFilterConfigurationsQuery = ({ instanceId, canFetch }) => {
    const { instanceService } = useDic();

    return useQueryLoadable(
        async () =>
            canFetch
                ? instanceService
                      .getFilterConfigurations(instanceId)
                      .then(({ data }) =>
                          data.map(value => ({ value, label: value })),
                      )
                : Promise.resolve(undefined),
        [instanceId, canFetch, instanceService],
    );
};

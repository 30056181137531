import { Drawer, Tabs } from "@/components/DesignSystem";
import { BusinessRolesTable } from "@/components/Permissions/AccountUserAdminSettings/RightsDrawer/PermissionsDrawer/BusinessRolesTable/BusinessRolesTable.component";
import { PermissionsTables } from "@/components/Permissions/AccountUserAdminSettings/RightsDrawer/PermissionsDrawer/PermissionsTable/PermissionsTables.component";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";

export const TABS = {
    PERMISSIONS: "PERMISSIONS",
    BUSINESS_ROLES: "BUSINESS_ROLES",
};

export const PermissionsDrawer = React.memo(function PermissionsDrawer({
    title,
    description,
    onPermissionChange,
    permissionsTouched,
    groupedPermissions,
    permissionsChanges,

    onBusinessRoleChange,
    businessRolesTouched,
    businessRoles,
    businessRolesChanges,

    hideBusinessRoles,

    onSave,
    onCancel,
    onClose = onCancel,
    reset = () => {},
    visible = true,
    isWFPermission,
}) {
    const [tab, setTab] = useState(TABS.PERMISSIONS);
    const saveDisabled =
        tab === TABS.PERMISSIONS ? !permissionsTouched : !businessRolesTouched;
    const onSaveWithActiveTab = useCallback(() => onSave(tab), [tab, onSave]);
    const setTabAndReset = useCallback(
        newTab => {
            reset();
            setTab(newTab);
        },
        [reset],
    );

    return (
        <Drawer
            title={title}
            description={description}
            cancelButtonProps={{ onClick: onCancel }}
            okButtonProps={{
                onClick: onSaveWithActiveTab,
                disabled: saveDisabled,
                children: t("general.save"),
            }}
            onClose={onClose}
            visible={visible}
            testId="permissions-drawer"
        >
            {visible && (
                <Tabs defaultActiveKey={tab} onChange={setTabAndReset}>
                    <Tabs.TabPane
                        tab={t("general.permissions")}
                        key={TABS.PERMISSIONS}
                    >
                        <PermissionsTables
                            onChange={onPermissionChange}
                            groupedPermissions={groupedPermissions}
                            permissionChanges={permissionsChanges}
                            isWFPermission={isWFPermission}
                        />
                    </Tabs.TabPane>
                    {!hideBusinessRoles && (
                        <Tabs.TabPane
                            tab={t("general.business-roles")}
                            key={TABS.BUSINESS_ROLES}
                        >
                            <BusinessRolesTable
                                onChange={onBusinessRoleChange}
                                businessRoles={businessRoles}
                                changes={businessRolesChanges}
                            />
                        </Tabs.TabPane>
                    )}
                </Tabs>
            )}
        </Drawer>
    );
});

PermissionsDrawer.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    businessRoles: PropTypes.arrayOf(PropTypes.object.isRequired),
    businessRolesChanges: PropTypes.object,
    businessRolesTouched: PropTypes.bool,
    onBusinessRoleChange: PropTypes.func,
    disabled: PropTypes.bool,
    grantedPermissions: PropTypes.object,
    groupedPermissions: PropTypes.object.isRequired,
    onCancel: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onPermissionChange: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    permissionsChanges: PropTypes.object.isRequired,
    permissionsTouched: PropTypes.bool.isRequired,
    reset: PropTypes.func.isRequired,
    isWFPermission: PropTypes.func,
    visible: PropTypes.bool,
    hideBusinessRoles: PropTypes.bool,
};

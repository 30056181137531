import {
    fieldTypes,
    HEADER_WITH_BREADCRUMB_WITH_TAB_HEIGHT,
} from "@/components/DesignSystem/Table/constants";
import { useSyncRowSelection } from "@/components/DesignSystem/Table/hooks/useSyncRowSelection.hook";
import { TableButton } from "@/components/TableButton";
import { TableLocalFiltered } from "@/components/TableLocalFiltered";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React, { useMemo } from "react";

export const SUPER_ASSET_TYPES = {
    ALL_ACCOUNTS: "ALL_ACCOUNTS",
    ALL_INTEGRATIONS: "ALL_INTEGRATIONS",
    ALL_PARTITIONS: "ALL_PARTITIONS",
};

const dataSource = [
    {
        assetType: SUPER_ASSET_TYPES.ALL_ACCOUNTS,
        label: t("super-assets-table.all-accounts"),
    },
    {
        assetType: SUPER_ASSET_TYPES.ALL_INTEGRATIONS,
        label: t("super-assets-table.all-integrations"),
    },
    {
        assetType: SUPER_ASSET_TYPES.ALL_PARTITIONS,
        label: t("super-assets-table.all-partitions"),
    },
];

const createColumns = onClick => [
    {
        name: "label",
        label: t("super-assets-table.asset-types-label"),
        type: fieldTypes.TEXT,
        render: (text, record) =>
            !onClick ? (
                text
            ) : (
                <TableButton
                    onClick={e => {
                        // without stopPropagation, Table registers click and adds record to selection
                        e.stopPropagation();
                        onClick(record);
                    }}
                >
                    {text}
                </TableButton>
            ),
    },
];

export const SuperAssetsTable = React.memo(function SuperAssetsTable({
    onSuperAssetClick,
    selectionChange,
    selectedSuperAssets,
    hasAnyRights,
    selectionContextMenu,
}) {
    const rowSelection = useSyncRowSelection(
        selectedSuperAssets,
        selectionChange,
    );

    const rowHighlights = useMemo(
        () =>
            ({ assetType }) =>
                hasAnyRights && hasAnyRights(assetType) ? "green" : false,
        [hasAnyRights],
    );

    const columns = useMemo(
        () => createColumns(onSuperAssetClick),
        [onSuperAssetClick],
    );

    return (
        <TableLocalFiltered
            fixed
            restHeight={HEADER_WITH_BREADCRUMB_WITH_TAB_HEIGHT}
            dataSource={dataSource}
            columns={columns}
            rowSelection={rowSelection}
            rowKey="assetType"
            rowHighlights={rowHighlights}
            selectionContextMenu={selectionContextMenu}
        />
    );
});

SuperAssetsTable.propTypes = {
    onSuperAssetClick: PropTypes.func.isRequired,
    selectionChange: PropTypes.func.isRequired,
    selectedSuperAssets: PropTypes.arrayOf(
        PropTypes.oneOf(Object.values(SUPER_ASSET_TYPES)).isRequired,
    ).isRequired,
    hasAnyRights: PropTypes.func.isRequired,
    selectionContextMenu: PropTypes.array,
};

import PropTypes from "prop-types";
import React from "react";

import { UnityLayout } from "@/components/DesignSystem";
import { PermissionsTable } from "@/components/Permissions/AccountUserAdminSettings/RightsDrawer/PermissionsDrawer/PermissionsTable/PermissionsTable.component";
import { logger } from "@/modules/logger";
import { t } from "@/translations";

export const PERMISSION_TYPES = {
    PROJECT: "PROJECT",
    PARTITION: "PARTITION",
    INTEGRATION: "INTEGRATION",
    DATA_LOAD: "DATA_LOAD",
    SIMPLE_SCHEDULER: "SIMPLE_SCHEDULER",
    WORKFLOW: "WORKFLOW",
};

const getPermissionTableTitle = permissionAssetType => {
    const assetTypeLowerCase = `${permissionAssetType}`.toLowerCase();

    return t(`permissions-table.${assetTypeLowerCase}`);
};

export const PermissionsTables = ({
    groupedPermissions,
    permissionChanges,
    onChange,
    isWFPermission,
}) => {
    logger.debug({
        logGroupKey: ["PERMISSIONS", "PermissionsTables"],
        color: "deepskyblue",
        data: {
            props: {
                groupedPermissions,
                permissionChanges,
                onChange,
                isWFPermission,
            },
        },
    });

    const isSinglePermissionType = Object.keys(groupedPermissions).length === 1;

    return (
        <UnityLayout>
            <UnityLayout.Content>
                {Object.entries(groupedPermissions).map(
                    ([assetType, permissions]) => (
                        <PermissionsTable
                            title={getPermissionTableTitle(assetType)}
                            onChange={onChange}
                            dataSource={permissions}
                            changes={permissionChanges[assetType]}
                            key={assetType}
                            testId={`permissions-table-${assetType}`}
                            isWFPermission={isWFPermission}
                            isSinglePermissionType={isSinglePermissionType}
                        />
                    ),
                )}
            </UnityLayout.Content>
        </UnityLayout>
    );
};

PermissionsTables.propTypes = {
    groupedPermissions: PropTypes.shape({
        PROJECT: PropTypes.arrayOf(PropTypes.object.isRequired),
        PARTITION: PropTypes.arrayOf(PropTypes.object.isRequired),
        INTEGRATION: PropTypes.arrayOf(PropTypes.object.isRequired),
    }).isRequired,
    permissionChanges: PropTypes.objectOf(PropTypes.object.isRequired)
        .isRequired,
    onChange: PropTypes.func.isRequired,
    isWFPermission: PropTypes.func,
};

import { useAsyncCall } from "@/components/hooks/useAsyncCall.hook";
import { t } from "@/translations";
import { instancesServices } from "../../services/instance.service";
import { useAlertEffect } from "../PageLayout/useAlertEffect.hook";
import PropTypes from "prop-types";

export const ConditionalImConfigErrorAlert = ({ instanceId }) => {
    const { response, error } = useAsyncCall(
        () => instancesServices.fetchInstance(instanceId),
        [instanceId],
    );

    useAlertEffect(response && !response.data.configurableSupported, () => ({
        type: "error",
        message: getMessage(response.data),
        closable: false,
    }));
    useAlertEffect(error, () => ({
        type: "error",
        message: "Instance not available",
        closable: false,
    }));

    return null;
};
function getMessage(imConfiguration) {
    if (!imConfiguration.configurable && imConfiguration.monitored) {
        return t("general.error.im-not-configurable");
    } else if (!imConfiguration.monitored) {
        return t("general.error.im-not-monitored");
    } else if (imConfiguration.imVersion) {
        return t("general.error.im-unsupported-version");
    }

    return t("general.error.im-configuration-not-supported");
}

ConditionalImConfigErrorAlert.propTypes = {
    instanceId: PropTypes.string.isRequired,
};

import { UnityBadge } from "@/components/DesignSystem";
import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { TableButton } from "@/components/TableButton";
import { assetType } from "@/constants/assets.constants";
import { t } from "@/translations";
import React from "react";

const renderBadge = hasPermissions => {
    if (hasPermissions === undefined) return null;

    return (
        <UnityBadge
            type={hasPermissions ? "success" : "neutral"}
            label={t(`assets-table.column.permissions.enum.${hasPermissions}`)}
        />
    );
};

export const persissionsColumn = {
    name: "permissions",
    label: t("assets-table.column.permissions"),
    type: fieldTypes.BOOLEAN,
    render: renderBadge,
    canSort: false,
};

const subAssetPermissionsColumn = {
    name: "subPermissions",
    label: t("assets-table.column.subAssetPermissions"),
    type: fieldTypes.BOOLEAN,
    render: renderBadge,
    canSort: false,
};

export const createColumns = onClick => ({
    [assetType.project]: [
        {
            name: "name",
            label: t("assets-table.project.name"),
            type: fieldTypes.TEXT,
            render: (text, record) =>
                !onClick ? (
                    text
                ) : (
                    <TableButton
                        onClick={e => {
                            // without stopPropagation, Table registers click and adds record to selection
                            e.stopPropagation();
                            onClick(assetType.project, record);
                        }}
                    >
                        {text}
                    </TableButton>
                ),
        },
        {
            name: "createdBy",
            label: t("assets-table.project.createdBy"),
            type: fieldTypes.TEXT,
        },
        {
            name: "type",
            label: t("assets-table.project.type"),
            type: fieldTypes.TEXT,
        },
        {
            name: "territory",
            label: t("assets-table.project.territory"),
            type: fieldTypes.TEXT,
        },
        {
            ...persissionsColumn,
            name: "accountPermissions",
            label: t("assets-table.column.accountPermissions"),
        },
        {
            ...subAssetPermissionsColumn,
            name: "permissions",
            label: t("assets-table.column.assetPermissions"),
        },
    ],
    [assetType.partition]: [
        {
            name: "name",
            label: t("assets-table.partition.name"),
            type: fieldTypes.TEXT,
            render: (text, record) =>
                !onClick ? (
                    text
                ) : (
                    <TableButton
                        onClick={e => {
                            e.stopPropagation();
                            onClick(assetType.partition, record);
                        }}
                    >
                        {text}
                    </TableButton>
                ),
        },
        {
            name: "serialNumber",
            label: t("assets-table.partition.serialNumber"),
            type: fieldTypes.TEXT,
        },
        {
            name: "clusterName",
            label: t("assets-table.partition.clusterName"),
            type: fieldTypes.TEXT,
        },
        persissionsColumn,
        subAssetPermissionsColumn,
    ],
    [assetType.integration]: [
        {
            name: "instanceName",
            label: t("assets-table.integration.instanceName"),
            type: fieldTypes.TEXT,
            render: (text, record) =>
                !onClick ? (
                    text
                ) : (
                    <TableButton
                        onClick={e => {
                            e.stopPropagation();
                            onClick(assetType.integration, record);
                        }}
                    >
                        {text}
                    </TableButton>
                ),
        },
        {
            name: "server",
            label: t("assets-table.integration.server"),
            type: fieldTypes.TEXT,
        },
        {
            name: "type",
            label: t("assets-table.integration.type"),
            type: fieldTypes.TEXT,
        },
        persissionsColumn,
        subAssetPermissionsColumn,
    ],
    [assetType.dataUpload]: [
        {
            name: "name",
            label: t("assets-table.data-upload.name"),
            type: fieldTypes.TEXT,
            render: (text, record) =>
                !onClick ? (
                    text
                ) : (
                    <TableButton
                        onClick={e => {
                            e.stopPropagation();
                            onClick(assetType.dataUpload, record);
                        }}
                    >
                        {text}
                    </TableButton>
                ),
        },
        {
            name: "partition.partitionName",
            label: t("assets-table.data-upload.partition"),
            type: fieldTypes.TEXT,
            render: (_, record) => record.partition,
        },
        {
            name: "entityType",
            label: t("assets-table.data-upload.type"),
            type: fieldTypes.TEXT,
        },
        persissionsColumn,
    ],
    [assetType.workflow]: [
        {
            name: "workflowName",
            label: t("assets-table.workflow.name"),
            type: fieldTypes.TEXT,
            render: (text, record) =>
                !onClick ? (
                    text
                ) : (
                    <TableButton
                        onClick={e => {
                            e.stopPropagation();
                            onClick(assetType.workflow, record);
                        }}
                    >
                        {text}
                    </TableButton>
                ),
        },
        {
            name: "description",
            label: t("assets-table.workflow.description"),
            type: fieldTypes.TEXT,
        },
        {
            name: "sourceType",
            label: t("assets-table.workflow.source-type"),
            type: fieldTypes.TEXT,
            render: (_, record) => record.singleSourceDto?.sourceType,
        },
        {
            name: "target",
            label: t("assets-table.workflow.target"),
            type: fieldTypes.TEXT,
            render: (_, record) => record.singleSourceDto?.label,
        },
        persissionsColumn,
    ],
    [assetType.scheduler]: [
        {
            name: "schedulerName",
            label: t("assets-table.scheduler.name"),
            type: fieldTypes.TEXT,
            render: (text, record) =>
                !onClick ? (
                    text
                ) : (
                    <TableButton
                        onClick={e => {
                            e.stopPropagation();
                            onClick(assetType.scheduler, record);
                        }}
                    >
                        {text}
                    </TableButton>
                ),
        },
        {
            name: "targetType",
            label: t("assets-table.scheduler.targetType"),
            type: fieldTypes.TEXT,
        },
        {
            name: "target",
            label: t("assets-table.workflow.target"),
            type: fieldTypes.TEXT,
        },

        persissionsColumn,
    ],
});

import { useHideBackButton } from "@/components/PageLayout/useHideBackButton.hook";
import { AccountPermissionsAdminSettingsComponent } from "@/components/Permissions/AccountPermissionAdminSettings/AccountPermissionsAdminSettings.component";
import { useFullWidthTableLayout } from "@/components/hooks/useContentLayout.hook";
import React from "react";

export function BusinessRolesAccountPage() {
    useFullWidthTableLayout();
    useHideBackButton();

    return <AccountPermissionsAdminSettingsComponent />;
}

import { useRoute } from "@/modules/router/index";
import { useContext } from "react";
import { getResource, RouteDataContext } from "../RouteDataContextProvider";

export const getNumberOfUsers = loadable =>
    loadable.valueMaybe()?.content?.length;
export const isMultipleUsers = loadable => getNumberOfUsers(loadable) > 1;

export const useRouteAdminUsersList = () => {
    const { route } = useRoute();
    const context = useContext(RouteDataContext);
    return {
        adminUsersListLoadable: getResource(
            "users",
            "userIds",
            route.params?.userIds,
            context.resources,
        ),
        adminUsersAssetType: route.params?.assetType,
    };
};

import { ButtonMenu, Modal, UnityLayout } from "@/components/DesignSystem";
import {
    FORM_TYPE,
    UserForm,
    useUserForm,
} from "@/components/Permissions/Users/UserForm";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React from "react";

export const NewUserModal = ({ visible, onClose, reloadUsers }) => {
    const { actionButtons, ...rest } = useUserForm({
        canFetch: visible,
        formType: FORM_TYPE.CREATE,
        onCancel: onClose,
        initialValues: undefined,
        afterSave: () => {
            reloadUsers();
            onClose();
        },
    });

    return (
        <Modal visible={visible} onClose={onClose}>
            <UnityLayout>
                <UnityLayout.Header
                    size={3}
                    title={t("user-detail.new.title")}
                />
                <UnityLayout.Content padding={[true, true]}>
                    <UserForm {...rest} inputWidth="max" />
                </UnityLayout.Content>
                <UnityLayout.Footer
                    actionButtons={<ButtonMenu buttons={actionButtons} />}
                />
            </UnityLayout>
        </Modal>
    );
};

NewUserModal.propTypes = {
    visible: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    reloadUsers: PropTypes.func.isRequired,
};

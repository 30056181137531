import { Forms, P } from "@/components/DesignSystem";
import { MAX_INT_INSTANCES } from "@/components/ImInstancesLimit/AccountInstancesLimitModal";
import { ProvisionedInstanceEnvironments } from "@/components/Integrations/NewProvisionedInstanceForm/ProvisionedInstanceEnvironments";
import { useInstanceLimitQuery } from "@/components/Integrations/NewProvisionedInstanceForm/useInstanceLimit.hook";
import { IntegrationArchitectureFields } from "@/components/Integrations/components/IntegrationArchitecture/IntegrationArchitectureFields";
import { useIntegrationArchitectureInfoVisibility } from "@/components/Integrations/components/IntegrationArchitecture/useIntegrationArchitectureInfoVisibility.hook";
import {
    useIntegrationProvidersQuery,
    useIntegrationTagsQuery,
} from "@/components/Integrations/loadables";
import { getLoadableSelectProps } from "@/components/Packages/PackageTableDefinitionPanel/components/ObjectTypeSelector/EntityNameSelector";
import { CLOUD_PROVIDERS } from "@/constants/cloudProviders.constants";
import { LoadableRenderer, useMapLoadableMemoized } from "@/modules/loadable";
import { useLoadableHasValueChangedEffect } from "@/modules/loadable/useLoadableHasValueChangedEffect.hook";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import { t } from "@/translations";
import { isNil } from "lodash";
import { head, map } from "lodash/fp";
import PropTypes from "prop-types";
import React, { useCallback, useMemo, useRef } from "react";

const { success, error } = Forms;

const DEFAULT_PROVIDER = CLOUD_PROVIDERS.GCP;
const PROVISIONED_NAME_AWS_VALIDATION_PATTERN = new RegExp(/^[a-z0-9-]{3,34}$/);

const apiLimitReached = ({ maxIntegrationInstances, existingInstances }) => {
    return existingInstances >= (maxIntegrationInstances ?? MAX_INT_INSTANCES);
};

export const isProvisionedAwsNameValid = value =>
    PROVISIONED_NAME_AWS_VALIDATION_PATTERN.test(value)
        ? success()
        : error(t("instance-form.error.instance-name-aws.invalid"));

const { Fields, validators, pmValidators } = Forms;

export const NewProvisionedInstanceFormComponent = ({
    formId,
    getBag,
    setValues,
}) => {
    const { accountId } = useAccountAppParams();
    const architectureVisibility = useIntegrationArchitectureInfoVisibility();

    const cloudProvider = Forms.useFieldValue({
        formId,
        name: "cloudProvider",
    });
    const version = Forms.useFieldValue({
        formId,
        name: "version",
    });
    const versionManuallyChangedRef = useRef(false);
    const onVersionChange = useCallback(() => {
        versionManuallyChangedRef.current = true;
    }, []);

    const integrationProvidersResource = useIntegrationProvidersQuery({
        accountId,
    });
    const imInstancesLimitQuery = useInstanceLimitQuery();
    const integrationTagsResource = useIntegrationTagsQuery({
        provider: cloudProvider,
    });

    useLoadableHasValueChangedEffect(
        integrationProvidersResource.loadable,
        async providers => {
            if (providers.length === 1) {
                const firstAvailableProvider = head(providers);

                if (firstAvailableProvider) {
                    setValues({ cloudProvider: firstAvailableProvider.label });
                }
            } else {
                const provider = providers.find(
                    provider => provider.label === DEFAULT_PROVIDER,
                );

                if (provider) {
                    setValues({ cloudProvider: provider.label });
                }
            }
        },
        [],
    );

    useLoadableHasValueChangedEffect(
        integrationTagsResource.loadable,
        async versions => {
            const { values } = await getBag();
            if (isNil(values.version) || !versionManuallyChangedRef.current) {
                const firstAvailableVersion = head(versions);

                if (firstAvailableVersion) {
                    setValues({ version: firstAvailableVersion.name });
                }
            }
        },
        [],
    );

    const versionsOptionsLoadable = useMapLoadableMemoized(
        integrationTagsResource.loadable,
        map(({ name }) => ({
            label: name,
            value: name,
        })),
    );

    const integrationProvidersOptions = useMapLoadableMemoized(
        integrationProvidersResource.loadable,
        map(({ label }) => ({
            label: `${label}`.toUpperCase(),
            value: label,
        })),
    );

    const providerRegionsOptions = useMemo(() => {
        const providers = integrationProvidersResource.loadable.valueMaybe();

        if (providers) {
            const provider = providers.find(
                provider => provider.label === cloudProvider,
            );
            if (provider) {
                return provider.regions.map(region => ({
                    label: region.label,
                    value: region.label,
                }));
            }
        }
        return [];
    }, [cloudProvider, integrationProvidersResource.loadable]);

    // environments initialValue depends on imInstancesLimitQuery
    return (
        <LoadableRenderer
            loadable={imInstancesLimitQuery.loadable}
            hasValue={({ existingInstances, maxIntegrationInstances }) => (
                <>
                    <Fields.Input
                        required
                        autoFocus
                        name="instanceName"
                        addonBefore="pim-"
                        placeholder={t(
                            "instance-form.placeholder.integration-name",
                        )}
                        tooltip={t("instance-form.tooltip.integration-name")}
                        label={t("instance-form.label.integration-name")}
                        validator={validators.failOnFirst([
                            pmValidators.isRequired,
                            isProvisionedAwsNameValid,
                        ])}
                    />
                    <Fields.Select
                        required
                        name="cloudProvider"
                        label={t("instance-form.label.cloud-provider")}
                        showSearch
                        validator={pmValidators.isRequired}
                        {...getLoadableSelectProps(integrationProvidersOptions)}
                        onChange={() => {
                            setValues({
                                region: undefined,
                            });
                        }}
                    />
                    {cloudProvider && (
                        <>
                            <Fields.Select
                                required
                                name="version"
                                label={t("instance-form.label.version")}
                                showSearch
                                onChange={onVersionChange}
                                validator={Forms.validators.failOnFirst([
                                    pmValidators.isRequired,
                                    pmValidators.createLoadableOptionsValidator(
                                        {
                                            loadable: versionsOptionsLoadable,
                                        },
                                    ),
                                ])}
                                {...getLoadableSelectProps(
                                    versionsOptionsLoadable,
                                )}
                            />
                            <Fields.Select
                                required
                                name="region"
                                label={t("instance-form.label.region")}
                                showSearch
                                validator={Forms.validators.failOnFirst([
                                    pmValidators.isRequired,
                                    pmValidators.createAllowedValuesValidator(
                                        providerRegionsOptions.map(
                                            ({ value }) => value,
                                        ) ?? [],
                                        t("general.error.unsupported-value"),
                                    ),
                                ])}
                                options={providerRegionsOptions}
                            />
                        </>
                    )}

                    {version && (
                        <ProvisionedInstanceEnvironments
                            name="environments"
                            initialValue={
                                apiLimitReached({
                                    maxIntegrationInstances,
                                    existingInstances,
                                })
                                    ? []
                                    : [{}]
                            }
                            existingInstances={
                                apiLimitReached({
                                    existingInstances,
                                    maxIntegrationInstances,
                                })
                                    ? existingInstances
                                    : existingInstances + 1
                            }
                            maxIntegrationInstances={
                                maxIntegrationInstances ?? MAX_INT_INSTANCES
                            }
                            newEnvVersion={version}
                            isNewInstance={true}
                        />
                    )}

                    {architectureVisibility.isDisplayed && (
                        <>
                            <P>
                                {t(
                                    "instance-form.collapse.architecture-information.perex",
                                )}
                            </P>
                            <IntegrationArchitectureFields />
                        </>
                    )}
                </>
            )}
        />
    );
};

NewProvisionedInstanceFormComponent.propTypes = {
    formId: PropTypes.number.isRequired,
    getBag: PropTypes.func.isRequired,
    setValues: PropTypes.func.isRequired,
    integrationTagsResource: PropTypes.object.isRequired,
};

import FieldGridNotPadded from "@/components/DataUploads/SftpManagement/FieldGridNotPadded.component";
import { Forms } from "@/components/DesignSystem";
import { orEmpty } from "@/components/DesignSystem/Forms/validators";
import { SelectWithDependencies } from "@/components/MassListeners/SelectWithDependencies";
import { t } from "@/translations";
import React from "react";
import {
    useELActionEntitiesOptionsQuery,
    useELActionTypesOptionsQuery,
} from "./loadables";

export const FALLBACK_ACTIONS = {
    SKIP: "SKIP",
    TRIGGER: "TRIGGER",
};

const FALLBACK_ACTION_OPTIONS = [
    {
        label: t("mass-listener.form.fallbackAction.skip"),
        value: FALLBACK_ACTIONS.SKIP,
    },
    {
        label: t("mass-listener.form.fallbackAction.trigger"),
        value: FALLBACK_ACTIONS.TRIGGER,
    },
];

export const FallbackActionFields = ({
    accountId,
    setValues,
    prefix,
    entityId,
    actionType,
    type,
    listenerId,
}) => {
    const enabledEnabled = !!type && !!entityId && !!actionType;
    const getFullName = sub => `${prefix}.${sub}`;
    const enabledValue = Forms.useFieldValue({
        formId: Forms.useFormId(),
        name: getFullName("enabled"),
    });
    const fallbackActionValue = Forms.useFieldValue({
        formId: Forms.useFormId(),
        name: getFullName("fallbackAction"),
    });

    return (
        <>
            <Forms.Fields.Checkbox
                name={getFullName("enabled")}
                label={t("mass-listener.form.fallbackOptions.enabled.label")}
                disabled={!enabledEnabled}
            />
            {enabledValue && (
                <>
                    <FieldGridNotPadded>
                        <Forms.FieldGrid
                            breakpoint={200}
                            layout={[{ width: "195px" }, { width: "195px" }]}
                        >
                            <Forms.FieldGrid.Row>
                                <Forms.Fields.InputNumber
                                    name={getFullName(`numberOfAttempts`)}
                                    label={t(
                                        "event-wf.listener-form.numberOfAttempts.label",
                                    )}
                                    validator={Forms.validators.failOnFirst([
                                        Forms.pmValidators.isRequired,
                                        Forms.pmValidators.min(1),
                                        Forms.pmValidators.max(2147483647),
                                    ])}
                                    initialValue={1}
                                    tooltip={t(
                                        "event-wf.listener-form.numberOfAttempts.tooltip",
                                    )}
                                />
                                <Forms.Fields.InputNumber
                                    name={getFullName(`fallbackTimeout`)}
                                    label={t(
                                        "event-wf.listener-form.fallbackTimeout.label",
                                    )}
                                    validator={orEmpty(
                                        Forms.pmValidators.min(0),
                                    )}
                                    addonAfter={"min"}
                                    tooltip={t(
                                        "event-wf.listener-form.fallbackTimeout.tooltip",
                                    )}
                                />
                            </Forms.FieldGrid.Row>
                        </Forms.FieldGrid>
                    </FieldGridNotPadded>
                    <Forms.Fields.Radio
                        required
                        name={getFullName(`fallbackAction`)}
                        label={t("event-wf.listener-form.fallbackAction.label")}
                        tooltip={t(
                            "event-wf.listener-form.fallbackAction.tooltip",
                        )}
                        options={FALLBACK_ACTION_OPTIONS}
                        validator={Forms.pmValidators.isRequired}
                        initialValue={FALLBACK_ACTIONS.SKIP}
                    />
                    {fallbackActionValue === FALLBACK_ACTIONS.TRIGGER && (
                        <>
                            <SelectWithDependencies
                                required
                                name={getFullName(`customAction.targetType`)}
                                label={t(
                                    "mass-listener.form.action-type.label",
                                )}
                                setValues={setValues}
                                useOptionsQuery={useELActionTypesOptionsQuery}
                                deps={{ accountId, type }}
                            />
                            <SelectWithDependencies
                                required
                                name={getFullName(`customAction.targetId`)}
                                label={t("mass-listener.form.action-id.label")}
                                setValues={setValues}
                                useOptionsQuery={
                                    useELActionEntitiesOptionsQuery
                                }
                                deps={{
                                    accountId,
                                    type,
                                    entityId,
                                    actionType,
                                    listenerId,
                                }}
                                optionalDepKeys={["listenerId"]}
                                clearOnError
                            />
                        </>
                    )}
                </>
            )}
        </>
    );
};

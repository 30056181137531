import { useDic } from "@/components/Dic/useDic.hook";
import { useFeatureFlag } from "@/security/hooks/useFeatureFlag.hook";
import React, { useEffect } from "react";

export const needsFeatureFlag =
    ({ featureFlag, otherwiseFunc }) =>
    Component =>
    ({ ...props }) => {
        const isEnabled = useFeatureFlag(featureFlag);
        const {
            locationRouterService,
            accountAppLocations,
            administrationAppLocations,
        } = useDic();

        useEffect(() => {
            if (!isEnabled && otherwiseFunc) {
                otherwiseFunc({
                    locationRouterService,
                    accountAppLocations,
                    administrationAppLocations,
                });
            }
        }, [
            accountAppLocations,
            administrationAppLocations,
            isEnabled,
            locationRouterService,
        ]);

        if (isEnabled) {
            return <Component {...props} />;
        }
    };

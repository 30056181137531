import { useDic } from "@/components/Dic/useDic.hook";
import { useFetchPage } from "@/components/PagableTable/useFetchPage.hook";
import { AssetsTable } from "@/components/Permissions/UsersAssets/AssetsTable.component";
import { assetType } from "@/constants/assets.constants";
import { preferencesTypes } from "@/constants/preferencesTypes.constants";
import PropTypes from "prop-types";
import React, { useImperativeHandle } from "react";

const preferencesType = preferencesTypes.USER_ASSETS_DATA_UPLOADS_TABLE;

export const DataUploadsTable = React.memo(
    React.forwardRef((props, ref) => {
        const { accountId, userIds } = props;
        const { permissionsAssetsService } = useDic();
        const [tableProps, { reload }] = useFetchPage(
            (page, size, sort, filter) =>
                permissionsAssetsService.fetchDataUploads({
                    accountId,
                    page,
                    size,
                    sort,
                    filter,
                    userIds,
                }),
            [],
        );

        useImperativeHandle(ref, () => ({
            reload,
        }));

        return (
            <AssetsTable
                preferencesType={preferencesType}
                {...tableProps}
                assetType={assetType.dataUpload}
                defaultSort={{
                    fieldName: "name",
                    sortDir: "ascending",
                }}
                {...props}
            />
        );
    }),
);

DataUploadsTable.propTypes = {
    accountId: PropTypes.number.isRequired,
    userIds: PropTypes.arrayOf(PropTypes.number).isRequired,
};

import { useDic } from "@/components/Dic/useDic.hook";
import { AssetAdminSettingsComponent } from "@/components/Permissions/AccountAssetAdminSettings/AssetAdminSettings.component";
import { t } from "@/translations";
import { replace } from "@/utils/commons.utils";
import { getErrorMessage } from "@/utils/state/error.utils";
import { produce } from "immer";
import remove from "lodash/remove";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useReducer } from "react";

const INIT_STATE = {
    assetRolesData: null,
    selectedRole: { assets: [] },
    drawerVisible: false,
};

const assetReducer = (state, action) => {
    return {
        ...state,
        ...action,
    };
};

export const AssetAdminSettingsContainer = ({
    setVisibleModal,
    visibleModal,
    accountId,
}) => {
    const [state, dispatch] = useReducer(assetReducer, INIT_STATE);

    const { assetRoleApiService, messageService } = useDic();

    useEffect(() => {
        fetchAssetRoles(false);
    }, []);

    const fetchAssetRoles = useCallback(drawerVisible => {
        assetRoleApiService.fetchAssetRoles(accountId).then(res => {
            dispatch({ assetRolesData: res, drawerVisible });
        });
    }, []);

    const onModalOk = assetRole => {
        assetRoleApiService
            .crateAssetRole(assetRole, accountId)
            .then(res => {
                dispatch({
                    selectedRole: res.data,
                    drawerVisible: true,
                });
                fetchAssetRoles(true);
                setVisibleModal(false);
            })
            .catch(e => {
                setVisibleModal(false);
                messageService.error({
                    content: getErrorMessage(e.response.data),
                });
            });
    };

    const onDrawerOpen = record => {
        const role =
            record &&
            state.assetRolesData.assetRoles.find(role => role.id === record.id);
        dispatch({ selectedRole: role, drawerVisible: true });
    };

    const onDrawerClose = () => {
        dispatch({ drawerVisible: false });
    };

    const onDrawerSave = updatedSelectedRole => {
        assetRoleApiService
            .updateAssetRole(updatedSelectedRole, accountId)
            .then(res => {
                dispatch({
                    selectedRole: res.data,
                    drawerVisible: false,
                    assetRolesData: {
                        ...state.assetRolesData,
                        assetRoles: replace(
                            state.assetRolesData.assetRoles,
                            res.data,
                            "name",
                        ),
                    },
                });
            })
            .catch(e => {
                messageService.error({
                    content: getErrorMessage(e.response.data),
                });
            });
    };

    const onRoleUpdate = updatedSelectedRole => {
        assetRoleApiService
            .updateAssetRole(updatedSelectedRole, accountId)
            .then(res => {
                dispatch({
                    selectedRole: res.data,
                    drawerVisible: false,
                });
                fetchAssetRoles();
            })
            .catch(e => {
                messageService.error({
                    content: getErrorMessage(e.response.data),
                });
            });
    };

    const onDeleteAssetRole = assetRoleId => {
        assetRoleApiService
            .deleteAssetRole(accountId, assetRoleId)
            .then(res => {
                dispatch({
                    assetRolesData: {
                        ...state.assetRolesData,
                        assetRoles: produce(
                            state.assetRolesData.assetRoles,
                            draft => {
                                remove(draft, r => r.id === assetRoleId);
                            },
                        ),
                    },
                });
                messageService.success({
                    content: t("account-admin-asset-role-list-delete.message"),
                });
            })
            .catch(e =>
                messageService.error({
                    content: getErrorMessage(e.response.data),
                }),
            );
    };

    return (
        state.assetRolesData && (
            <AssetAdminSettingsComponent
                data={state.assetRolesData}
                onModalCancel={() => setVisibleModal(false)}
                onModalOk={onModalOk}
                onDrawerOpen={onDrawerOpen}
                onDrawerClose={onDrawerClose}
                onDrawerSave={onDrawerSave}
                visibleModal={visibleModal}
                drawerVisible={state.drawerVisible}
                selectedRole={state.selectedRole}
                onDeleteAssetRole={onDeleteAssetRole}
                onRoleUpdate={onRoleUpdate}
            />
        )
    );
};

AssetAdminSettingsContainer.propTypes = {
    accountId: PropTypes.number.isRequired,
    visibleModal: PropTypes.bool.isRequired,
    setVisibleModal: PropTypes.func.isRequired,
};

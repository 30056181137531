import { uniqAssetRolePermissions } from "@/components/Permissions/AccountUserAdminSettings/RightsDrawer/PermissionsDrawer/assetRole.utils";
import { mapPermissions } from "@/components/Permissions/AccountUserAdminSettings/RightsDrawer/PermissionsDrawer/hooks/mapPermissionEntities";
import {
    hasAnyPermissionChanges,
    nextPermissionChangesState,
} from "@/components/Permissions/AccountUserAdminSettings/RightsDrawer/PermissionsDrawer/rights.utils";
import {
    ADMIN_KIND,
    ASSET_KIND,
    ENTITY_KIND,
    PERMISSION_KIND,
    SUPER_ASSET_TYPE_TO_ASSET_TYPE,
    addSource,
    expandSource,
    getAssetRecordFilter,
    getEntityRecordFilter,
} from "@/components/Permissions/AccountUserAdminSettings/RightsDrawer/PermissionsDrawer/sources.utils";
import "lodash.product";
import filter from "lodash/fp/filter";
import flatMap from "lodash/fp/flatMap";
import get from "lodash/fp/get";
import getOr from "lodash/fp/getOr";
import groupBy from "lodash/fp/groupBy";
import identity from "lodash/fp/identity";
import keys from "lodash/fp/keys";
import map from "lodash/fp/map";
import mapValues from "lodash/fp/mapValues";
import overEvery from "lodash/fp/overEvery";
import pick from "lodash/fp/pick";
import pickBy from "lodash/fp/pickBy";
import pipe from "lodash/fp/pipe";
import toUpper from "lodash/fp/toUpper";
import uniq from "lodash/fp/uniq";
import useWith from "lodash/fp/useWith";
import { useCallback, useEffect, useMemo, useState } from "react";

export const hasAnyPermission = () => false; // TODO

// prettier-ignore
const getAssetTypes = {
    [ASSET_KIND.ASSET]: pipe(pickBy(get("length")), keys, map(toUpper)),
    [ASSET_KIND.ASSET_ROLE]: pipe(flatMap(get("assets")), map(get("type")), uniq),
    [ASSET_KIND.SUPER_ASSET]: map(saType => get(saType, SUPER_ASSET_TYPE_TO_ASSET_TYPE)), // cannot use lodash/fp flip as it is variadic, not binary as in ramda
}

// prettier-ignore
const significantSelection = {
    [ASSET_KIND.ASSET]: (selection, type) => pipe(getOr([], type.toLowerCase()), map(assetId => ({ assetId, assetType: type })))(selection),
    [ASSET_KIND.ASSET_ROLE]: (selection, type) => selection.map(({ id }) => id), // TODO: consider
    [ASSET_KIND.SUPER_ASSET]: (selection, type) => selection.filter(sa => SUPER_ASSET_TYPE_TO_ASSET_TYPE[sa] === type),
}

export const usePermissions = (
    adminKind = ADMIN_KIND.PROJECT,
    entityKind = ENTITY_KIND.USER,
    assetKind = ASSET_KIND.ASSET,

    flatRights,
    workflowRights,
    selectedEntities,
    selectedAssets,
    permissions,
) => {
    const editableSources = useMemo(
        () =>
            expandSource({
                adminKind,
                entityKind,
                assetKind,
                permissionKind: PERMISSION_KIND.PERMISSION,
            }),
        [adminKind, entityKind, assetKind],
    );
    const [permissionChanges, setPermissionChanges] = useState({});
    // prettier-ignore
    const permissionsForSelectedAssets = useMemo(
        () => pipe(
            useWith(pick, [ // eslint-disable-line
                getAssetTypes[assetKind],
                identity,
            ]),
            assetKind === ASSET_KIND.ASSET_ROLE ? uniqAssetRolePermissions : identity,
        )(selectedAssets, permissions),
        [selectedAssets, permissions, assetKind],
    );
    const rightsMap = useMemo(
        () =>
            pipe(
                map(addSource),
                groupBy("assetType"),
                mapValues(groupBy("permissionName")), // TODO: filter records for selected entities/assets?
            )(flatRights),
        [flatRights],
    );
    // TODO: abstract
    const wfRightsMap = useMemo(
        () =>
            pipe(
                map(addSource),
                groupBy("assetType"),
                mapValues(groupBy("permissionName")),
            )(workflowRights),
        [workflowRights],
    );
    const entityMatch = useCallback(
        getEntityRecordFilter({ entityKind, adminKind }),
        [entityKind, adminKind],
    );
    const assetMatch = useCallback(getAssetRecordFilter(assetKind), [
        assetKind,
    ]);
    const filterSelectionProductRecords = useCallback(
        (entityId, assetEntity, permission, records) =>
            filter(
                overEvery([entityMatch(entityId), assetMatch(assetEntity)]),
                records,
            ),
        [entityMatch, assetMatch],
    );
    const getPermissionSignificantSelection = useCallback(
        ({ type }) => significantSelection[assetKind](selectedAssets, type),
        [selectedAssets, assetKind],
    );

    const groupedPermissions = useMemo(
        () =>
            mapPermissions(
                editableSources,
                rightsMap,
                wfRightsMap,
                selectedEntities,
                permissionsForSelectedAssets,
                filterSelectionProductRecords,
                getPermissionSignificantSelection,
            ),
        [
            editableSources,
            rightsMap,
            wfRightsMap,
            selectedEntities,
            permissionsForSelectedAssets,
            filterSelectionProductRecords,
            getPermissionSignificantSelection,
        ],
    );
    const onChange = useCallback(
        permission => {
            const newPermissionChanges = nextPermissionChangesState(
                permissionChanges,
                permission,
            );

            setPermissionChanges(newPermissionChanges);
        },
        [permissionChanges],
    );
    const reset = useCallback(() => setPermissionChanges({}), []);
    useEffect(() => reset(), [groupedPermissions, reset]);

    return {
        onChange,
        dirty: hasAnyPermissionChanges(permissionChanges),
        reset,
        groupedPermissions,
        permissionChanges,
    };
};

import { Alert, Gap } from "@/components/DesignSystem";
import { Option, Select } from "@/components/DesignSystem/Select";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React, { useCallback } from "react";
import styles from "../../Packages/PackageTableDefinitionPanel/components/styles.less";
import { InputType } from "../../Packages/PackageTableDefinitionPanel/constants";
import { validateComposed } from "../FieldCustomizationTabs.component";
import { TABS_VALUE_TYPES } from "../constants";

const withValidation = (input, allowedFields) => ({
    ...input,
    error: validateComposed(input.input, allowedFields),
});

const getStringFieldsOptions = fields =>
    fields.map(name => (
        <Option title={name} key={name} value={name}>
            {name}
        </Option>
    ));

export const ComposedTab = ({ input, data, onChange, error }) => {
    const onSelectChange = useCallback(
        values => {
            onChange(
                withValidation(
                    {
                        inputType: InputType.COMPOSED,
                        input: values,
                        tab: TABS_VALUE_TYPES.COMPOSED,
                    },
                    data,
                ),
            );
        },
        [onChange, data],
    );

    return (
        <div data-test="tab-composed">
            <Select
                placeholder={t("packages.select.placeholder")}
                showSearch
                allowClear
                onChange={onSelectChange}
                className={styles.itemSelect}
                value={input}
                mode="multiple"
            >
                {getStringFieldsOptions(data)}
            </Select>
            <Gap size="small" />
            {error && <Alert type="error" message={error} />}
        </div>
    );
};

ComposedTab.propTypes = {
    input: PropTypes.arrayOf(PropTypes.string),
    data: PropTypes.arrayOf(PropTypes.string).isRequired,
    onChange: PropTypes.func.isRequired,
    error: PropTypes.string,
};

import { H5 } from "@/components/DesignSystem/heading";
import { t } from "@/translations";
import isEmpty from "lodash/fp/isEmpty";
import pipe from "lodash/fp/pipe";
import set from "lodash/fp/set";
import React from "react";
import { MapperCascader } from "../../Mappers/MapperTableWithCustomization/MapperCascader";
import {
    nextConverterState,
    selectedMetaToConverterState,
} from "../../Mappers/MapperTableWithCustomization/MapperGridForm/utils";
import { InputType } from "../../Packages/PackageTableDefinitionPanel/constants";
import { TABS_VALUE_TYPES } from "../constants";

const withValidation = input => ({
    ...input,
    isValid: !isEmpty(input.input),
});

const withConverterState = (prev, next) =>
    set("converterState", nextConverterState(prev, next));

export const FieldTab = ({
    input,
    inputOptions,
    onChange,
    converterState = {},
}) => {
    const onCascaderChange = (input, selected = []) => {
        pipe(
            withValidation,
            withConverterState(
                converterState,
                selectedMetaToConverterState(selected),
            ),
            onChange,
        )({
            input,
            inputType: InputType.BODY,
            tab: TABS_VALUE_TYPES.FIELD,
        });
    };
    return (
        <div data-test="tab-field">
            <H5>{t("formula-editor.tab.field.input")}</H5>
            <MapperCascader
                value={input}
                options={inputOptions}
                onChange={onCascaderChange}
                allowClear={false}
                style={{ width: "100%" }}
            />
        </div>
    );
};

import { HeaderTabs } from "@/components/PageLayout/components/HeaderTabs.component";
import { useAssetsSelection } from "@/components/Permissions/AccountUserAdminSettings/RightsDrawer/hooks/useAssetsSelection.hook";
import { useGroupedPermissionsResource } from "@/components/Permissions/AccountUserAdminSettings/RightsDrawer/loadables";
import { PermissionsDrawerContainer } from "@/components/Permissions/AccountUserAdminSettings/RightsDrawer/PermissionsDrawer/PermissionsDrawer.container";
import {
    ADMIN_KIND,
    ASSET_KIND,
} from "@/components/Permissions/AccountUserAdminSettings/RightsDrawer/PermissionsDrawer/sources.utils";
import { createProjectIdSubsetFilter } from "@/components/Permissions/UsersAssets/AssetsTable.component";
import { SubAssetTable } from "@/components/Permissions/UsersAssets/sub-assets/SubAssetTable.component";
import { SubAssetTableLocal } from "@/components/Permissions/UsersAssets/sub-assets/SubAssetTableLocal.component";
import { assetType as assetTypeConstant } from "@/constants/assets.constants";
import {
    LoadableRenderer,
    useComposeLoadablesMemoized,
} from "@/modules/loadable";
import { logger } from "@/modules/logger";
import { t } from "@/translations";
import { size } from "lodash/fp";
import React, {
    forwardRef,
    useCallback,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
} from "react";

const addTabPrefix = tab => `sub-${tab}`;
const removeTabPrefix = tab => tab.replace(/^sub-/, "");
export const TABS_BY_ASSET = {
    [assetTypeConstant.partition]: {
        DATA_UPLOADS: assetTypeConstant.dataUpload,
        WORKFLOWS: assetTypeConstant.workflow,
        SCHEDULERS: assetTypeConstant.scheduler,
    },
    [assetTypeConstant.integration]: {
        WORKFLOWS: assetTypeConstant.workflow,
        SCHEDULERS: assetTypeConstant.scheduler,
    },
};

const assetKind = ASSET_KIND.ASSET;

export const VIEWS = {
    DEFAULT: null,
    PERMISSIONS: "PERMISSIONS",
};

export const getName = tab => {
    switch (tab) {
        case assetTypeConstant.dataUpload:
            return t("global.data-uploads");
        case assetTypeConstant.workflow:
            return t("global.workflows");
        case assetTypeConstant.scheduler:
            return t("global.schedulers");
        default:
            return "";
    }
};

const getDescription = (selectedItems, tab) =>
    size(selectedItems) > 1
        ? `${size(selectedItems)} selected ${getName(tab).toLowerCase()}`
        : `${size(selectedItems)} selected ${getName(tab).toLowerCase()}`;

export const SubAssetsTable = React.memo(
    forwardRef(
        (
            {
                accountId,
                assetId,
                assetType: parentAssetType,
                entityKind,
                selectedEntities,
                rightsResource,
                businessRolesResource,
                workflowRightsResource,
                saveRightsResource,
                // onClose,
                // visible = true,
                // displaySuperAssets = false,
            },
            ref,
        ) => {
            const TABS = TABS_BY_ASSET[parentAssetType];
            const defaultTab = Object.values(TABS)[0];
            const [tab, setTab] = useState(defaultTab);
            const [view, setView] = useState(VIEWS.DEFAULT);
            const showPermissions = useCallback(
                () => setView(VIEWS.PERMISSIONS),
                [setView],
            );

            const currentChildRef = useRef();

            useImperativeHandle(ref, () => ({
                hidePermissionsDrawer() {
                    setView(VIEWS.DEFAULT);
                },
                reload: () => currentChildRef.current.reload(),
            }));

            const {
                selectedAssets,
                onAssetsSelection,
                onAssetClick,
                noAssetsSelected,
                resetAssets,
            } = useAssetsSelection({ afterClick: showPermissions });

            const onPermissionsDrawerClose = useCallback(
                () => setView(VIEWS.DEFAULT),
                [setView],
            );
            useEffect(() => {
                resetAssets();
            }, [tab, resetAssets]);
            const onCloseAll = useCallback(() => {
                setView(VIEWS.DEFAULT);
                // onClose();
            }, []);
            // useEffect(() => () => setView(VIEWS.DEFAULT), [visible]);

            const groupedPermissionsResource = useGroupedPermissionsResource();
            // const assetRolesResource = useAdminAssetRolesResource();
            const loadables = useComposeLoadablesMemoized([
                groupedPermissionsResource.loadable,
                businessRolesResource.loadable,
                // assetRolesResource.loadable,
                rightsResource.loadable,
                workflowRightsResource.loadable,
            ]);

            const selectionContextMenu = useMemo(
                () => [
                    {
                        label: t("general.set-permissions"),
                        onClick: showPermissions,
                    },
                ],
                [showPermissions],
            );

            logger.debug({
                logGroupKey: ["PERMISSIONS", "PartitionSubAssetsComponent"],
                color: "deepskyblue",
                data: {
                    selectedAssets,
                },
            });

            const tableProps = {
                subsetFilter: createProjectIdSubsetFilter({
                    accountId,
                }),
            };

            const selectedAsset = removeTabPrefix(tab);

            return (
                <>
                    <HeaderTabs
                        defaultActiveKey={addTabPrefix(defaultTab)}
                        destroyInactiveTabPane
                        onChange={setTab}
                    >
                        {Object.values(TABS).map(assetType => {
                            const isLocalFiltered = [
                                assetTypeConstant.scheduler,
                                assetTypeConstant.workflow,
                            ].includes(assetType);
                            const Component = isLocalFiltered
                                ? SubAssetTableLocal
                                : SubAssetTable;
                            return (
                                <HeaderTabs.TabPane
                                    tab={getName(assetType)}
                                    key={addTabPrefix(assetType)}
                                >
                                    <Component
                                        ref={currentChildRef}
                                        accountId={accountId}
                                        userIds={selectedEntities}
                                        assetId={assetId}
                                        assetType={assetType}
                                        parentAssetType={parentAssetType}
                                        partitionId={assetId}
                                        onAssetClick={onAssetClick}
                                        selectionChange={onAssetsSelection}
                                        selectionContextMenu={
                                            selectionContextMenu
                                        }
                                        selectedAssets={selectedAssets}
                                        tableProps={tableProps}
                                        defaultSort={{
                                            fieldName: "name",
                                            sortDir: "ascending",
                                        }}
                                    />
                                </HeaderTabs.TabPane>
                            );
                        })}
                        {/* <HeaderTabs.TabPane
                            tab={t("global.workflows")}
                            key={TABS.WORKFLOWS}
                        >
                            <PartitionWorkflowsTable
                                accountId={accountId}
                                partitionId={assetId}
                                onAssetClick={onAssetClick}
                                selectionChange={onAssetsSelection}
                                selectionContextMenu={selectionContextMenu}
                                selectedAssets={selectedAssets}
                                tableProps={tableProps}
                            />
                        </HeaderTabs.TabPane>
                        <HeaderTabs.TabPane
                            tab={t("global.schedulers")}
                            key={TABS.SCHEDULERS}
                        >
                            <PartitionSchedulersTable
                                accountId={accountId}
                                partitionId={assetId}
                                onAssetClick={onAssetClick}
                                selectionChange={onAssetsSelection}
                                selectionContextMenu={selectionContextMenu}
                                selectedAssets={selectedAssets}
                                tableProps={tableProps}
                            />
                        </HeaderTabs.TabPane> */}
                    </HeaderTabs>
                    <LoadableRenderer
                        loadable={loadables}
                        loading={() => null}
                        hasValue={([
                            groupedPermissions,
                            businessRoles,
                            // assetRoles,
                            rights,
                            workflowRights,
                            // saveRights, // TODO
                        ]) => (
                            <>
                                <PermissionsDrawerContainer
                                    title="Asset Permissions"
                                    description={getDescription(
                                        selectedAssets[selectedAsset],
                                        selectedAsset,
                                        parentAssetType,
                                    )}
                                    selectedAssets={selectedAssets}
                                    selectedEntities={selectedEntities}
                                    adminKind={ADMIN_KIND.GENERAL}
                                    entityKind={entityKind}
                                    assetKind={assetKind}
                                    rights={rights}
                                    workflowRights={workflowRights}
                                    groupedPermissions={groupedPermissions}
                                    onCancel={onCloseAll}
                                    onClose={onPermissionsDrawerClose}
                                    onSave={saveRightsResource.mutate}
                                    visible={Boolean(
                                        view === VIEWS.PERMISSIONS,
                                    )}
                                    businessRoles={businessRoles}
                                />
                            </>
                        )}
                    />
                </>
            );
        },
    ),
);

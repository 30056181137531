import { useMemo } from "react";
import { useTableExampleDataToOptions } from "../Mappers/MapperTableWithCustomization/hooks/useTableExampleDataToOptions.hook";
import {
    convertToNumbersForFormula,
    getHeadersOfStringType,
} from "../Packages/PackageTableDefinitionPanel/components/PackageMandatoryFields/PackageMandatoryFields.utils";

export const useParseTableExampleData = ({
    inputOptions: passedInputOptions,
    tableExampleData,
}) => {
    const inputOptions = useTableExampleDataToOptions(
        tableExampleData,
        passedInputOptions,
    );
    const numberFields = useMemo(
        () => convertToNumbersForFormula(tableExampleData),
        [tableExampleData],
    );
    const stringFields = useMemo(
        () => getHeadersOfStringType(tableExampleData),
        [tableExampleData],
    );
    return {
        inputOptions,
        numberFields,
        stringFields,
    };
};

import { Tabs } from "@/components/DesignSystem";
import { useBreadcrumbButton } from "@/components/hooks/useBreadcrumbButton.hook";
import { useSetPageTitle } from "@/components/PageLayout/useSetPageTitle.hook";
import { AssetAdminSettingsContainer } from "@/components/Permissions/AccountAssetAdminSettings/AssetAdminSettings.container";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import { t } from "@/translations";
import React, { useState } from "react";

const TabPane = Tabs.TabPane;

export const TABS = {
    ASSET_ROLES: "asset-roles",
};

export const AccountAssetAdminSettingsComponent = () => {
    const { accountId, activeTab } = useAccountAppParams();
    const [tab, setTab] = useState(activeTab);
    const [visibleInviteModal, setVisibleInviteModal] = useState(false);

    useBreadcrumbButton(
        {
            label: "account-admin-asset-role-list.button.create-asset-role",
            onClick: () => setVisibleInviteModal(true),
        },
        [setVisibleInviteModal],
    );

    useSetPageTitle(t("account-admin-asset-role-list.asset-roles"));

    return (
        <Tabs defaultActiveKey={tab} onChange={setTab}>
            <TabPane
                tab={t("account-admin-asset-role-list.asset-roles")}
                key={TABS.ASSET_ROLES}
            >
                <AssetAdminSettingsContainer
                    accountId={accountId}
                    visibleModal={visibleInviteModal}
                    setVisibleModal={setVisibleInviteModal}
                />
            </TabPane>
        </Tabs>
    );
};

AccountAssetAdminSettingsComponent.propTypes = {};

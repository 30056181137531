import {
    ButtonMenu,
    Forms,
    Gap,
    Modal,
    Text,
    UnityLayout,
} from "@/components/DesignSystem";
import {
    usePostUserSsoUsernameResource,
    useUserSsoUsernameResource,
} from "@/components/Permissions/Users/loadables";
import { isLoading, LoadableRenderer } from "@/modules/loadable";
import { t } from "@/translations";
import { getErrorMessage } from "@/utils/state/error.utils";
import PropTypes from "prop-types";
import React, { useEffect } from "react";

export const SsoUsernameModal = ({ modalProviderProps, record }) => {
    const { id: userId } = record ?? {};
    const userSsoUsernameResource = useUserSsoUsernameResource({
        userId,
        canFetch: modalProviderProps.visible,
    });

    const postUserSsoUsernameResource = usePostUserSsoUsernameResource({
        afterSave: modalProviderProps.onClose,
    });

    const submitDisabled =
        userSsoUsernameResource.loadable.state !== "hasValue" ||
        isLoading(postUserSsoUsernameResource);

    const onSsoLoginSubmit = ({ values }) => {
        postUserSsoUsernameResource.mutate({
            userId,
            ssoLogin: values.ssoLogin,
        });
    };

    const { useForm } = Forms;
    const { formId, handleSubmit, setValues } = useForm({
        onSubmit: onSsoLoginSubmit,
    });

    return (
        <Modal {...modalProviderProps}>
            <UnityLayout>
                <UnityLayout.Header
                    title={t("user-detail.modal.sso-username.title")}
                />
                <UnityLayout.Content padding={[false, true]}>
                    <LoadableRenderer
                        loadable={userSsoUsernameResource.loadable}
                        hasValue={value => (
                            <>
                                <Text>
                                    {t("user-detail.modal.sso-username.text")}
                                    <Gap size="small" />
                                    <SsoUsernameForm
                                        formId={formId}
                                        handleSubmit={handleSubmit}
                                        ssoLogin={value.ssoLogin}
                                        setValues={setValues}
                                    />
                                </Text>
                            </>
                        )}
                        hasError={e => (
                            <LoadableRenderer.Error>
                                {getErrorMessage(e)}
                            </LoadableRenderer.Error>
                        )}
                    />
                </UnityLayout.Content>
                <UnityLayout.Footer
                    actionButtons={
                        <ButtonMenu
                            buttons={[
                                {
                                    label: t("general.save"),
                                    type: "primary",
                                    disabled: submitDisabled,
                                    formId,
                                },
                                {
                                    label: t("general.cancel"),
                                    onClick: modalProviderProps.onClose,
                                    type: "text",
                                },
                            ]}
                        />
                    }
                />
            </UnityLayout>
        </Modal>
    );
};

const SsoUsernameForm = ({ ssoLogin, formId, handleSubmit, setValues }) => {
    const { Form, Fields } = Forms;

    useEffect(() => {
        setValues({ ssoLogin });
    }, [setValues, ssoLogin]);

    return (
        <>
            <Form formId={formId} onSubmit={handleSubmit}>
                <Forms.FieldGroup width="max" inputWidth="max">
                    <Fields.Input
                        name="ssoLogin"
                        label={t("general.username")}
                    />
                </Forms.FieldGroup>
            </Form>
        </>
    );
};

SsoUsernameModal.propTypes = {
    modalProviderProps: PropTypes.object,
    record: PropTypes.object,
};

import { assetType } from "@/constants/assets.constants";
import flatten from "lodash/flatten";
import { useCallback, useEffect, useState } from "react";

export const emptySelectedAssets = () =>
    Object.values(assetType).reduce(
        (acc, type) => ({
            ...acc,
            [type]: [],
        }),
        {},
    );

const validateSelection = (selection, type) => {
    if (!Object.keys(emptySelectedAssets()).includes(type))
        throw new Error("Unknown asset type selected: " + type);
    if (selection.some(id => typeof id !== "number"))
        throw new Error(
            "Selection must be number or string indicies: " +
                JSON.stringify(selection),
        );
};

export const useAssetsSelection = ({ afterClick, reinitSelected }) => {
    // TODO: useStateReinitialize
    const [selectedAssets, setSelectedAssets] = useState(
        reinitSelected || emptySelectedAssets(),
    );
    useEffect(() => {
        setSelectedAssets(current => reinitSelected || current);
    }, [reinitSelected]);
    const onAssetClick = useCallback(
        (assetType, record) => {
            validateSelection([record.id], assetType);
            setSelectedAssets({
                ...emptySelectedAssets(),
                [assetType]: [record.id],
            });
            afterClick?.();
        },
        [afterClick],
    );
    const onAssetsSelection = useCallback(
        (selection, type) => {
            validateSelection(selection, type);
            setSelectedAssets({
                ...selectedAssets,
                [type]: selection,
            });
        },
        [selectedAssets, setSelectedAssets],
    );
    const noAssetsSelected = !flatten(Object.values(selectedAssets)).length;
    const resetAssets = useCallback(
        () => setSelectedAssets(emptySelectedAssets()),
        [],
    );

    return {
        selectedAssets,
        onAssetsSelection,
        onAssetClick,
        noAssetsSelected,
        resetAssets,
    };
};

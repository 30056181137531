import { useMemo } from "react";

export const useGetTableExampleDataMap = ({ tableExampleData }) => {
    const tableExampleDataMap = useMemo(() => {
        const map = new Map();

        (tableExampleData?.columns ?? []).forEach(row => {
            map.set(row.name, row);
        });

        return map;
    }, [tableExampleData]);
    return tableExampleDataMap;
};

import { ActionButton } from "@/components/DesignSystem/Table/components/ActionButton/ActionButton";
import { HEADER_WITH_BREADCRUMB_WITH_TAB_HEIGHT } from "@/components/DesignSystem/Table/constants";
import { columns } from "@/components/Permissions/AccountAssetAdminSettings/AssetRolesTable/AssetRolesTable.columns";
import { TableWithPreferencesManagement } from "@/components/TableWithPreferences/TableWithPreferencesManagement.container";
import { preferencesTypes } from "@/constants/preferencesTypes.constants";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React, { useCallback } from "react";

const preferencesType = preferencesTypes.ASSET_ROLES_LIST_TABLE;

export const AssetRolesTable = ({
    dataSource,
    onAssetRoleClick,
    onAssetRoleDelete,
    projectId,
    onEditModalOpen,
    onDrawerClose,
}) => {
    const actionMenu = useCallback(
        record => (
            <ActionButton
                record={record}
                items={[
                    {
                        title: t("general.tooltip.edit"),
                        onClick: onEditModalOpen,
                    },
                    {
                        title: t(
                            "account-admin-asset-role-list-delete-action.title",
                        ),
                        confirmMessage: t(
                            "account-admin-asset-role-list-delete-action.message",
                            {
                                name: record.name,
                            },
                        ),
                        onConfirm: record => onAssetRoleDelete(record.id),
                        color: "red",
                    },
                ]}
            />
        ),
        [onAssetRoleDelete, onEditModalOpen],
    );

    return (
        dataSource && (
            <TableWithPreferencesManagement
                actionMenu={onAssetRoleDelete && actionMenu}
                columns={columns(onAssetRoleClick, projectId)}
                dataSource={dataSource}
                fixed
                onChangeFilter={onDrawerClose}
                onChangePaging={onDrawerClose}
                restHeight={HEADER_WITH_BREADCRUMB_WITH_TAB_HEIGHT}
                rowKey="id"
                datasetSlicing="local"
                preferencesType={preferencesType}
            />
        )
    );
};

AssetRolesTable.propTypes = {
    dataSource: PropTypes.arrayOf(
        PropTypes.shape({ name: PropTypes.string.isRequired }),
    ).isRequired,
    onAssetRoleClick: PropTypes.func.isRequired,
    onAssetRoleDelete: PropTypes.func.isRequired,
    onEditModalOpen: PropTypes.func.isRequired,
    onDrawerClose: PropTypes.func.isRequired,
};

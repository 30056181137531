import { Drawer, Tabs } from "@/components/DesignSystem";
import { ActionButton } from "@/components/DesignSystem/Table/components/ActionButton/ActionButton";
import { HEADER_WITH_BREADCRUMB_WITH_TAB_HEIGHT } from "@/components/DesignSystem/Table/constants";
import { BusinessRoleModal } from "@/components/Permissions/AccountPermissionAdminSettings/BusinessRoleModal.component";
import { businessRoleColumns } from "@/components/Permissions/AccountPermissionAdminSettings/PermissionAdminSettings.columns";
import { PermissionsTable } from "@/components/Permissions/AccountUserAdminSettings/PermissionTable/PermissionsTable.container";
import { TableWithPreferencesManagement } from "@/components/TableWithPreferences/TableWithPreferencesManagement.container";
import { preferencesTypes } from "@/constants/preferencesTypes.constants";
import { T, t } from "@/translations";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";

const TABS = {
    PERMISSIONS: "permissions",
};

const preferencesType = preferencesTypes.BUSINESS_ROLES_LIST_TABLE;

export const PermissionAdminSettingsComponent = ({
    data,
    grantedPermissions,
    onDrawerOpen,
    onDrawerClose,
    onDrawerSave,
    visibleModal,
    drawerVisible,
    onModalCancel,
    onModalOk,
    selectedRole,
    onDeleteBusinessRole,
    onPermissionGrantedChange,
    onRoleUpdate,
}) => {
    const [updatedRecord, setUpdatedRecord] = useState(selectedRole);
    const [editModalVisible, setEditModalVisible] = useState(false);

    useEffect(() => {
        setUpdatedRecord(selectedRole);
    }, [selectedRole]);

    const onCancel = () => {
        onDrawerClose();
    };

    const onEditModalOpen = role => {
        setEditModalVisible(true);
        setUpdatedRecord(role);
    };

    const onEditModalCancel = () => setEditModalVisible(false);

    const onEditModalSave = role => {
        setEditModalVisible(false);
        onRoleUpdate(role);
    };

    const actionMenu = useCallback(
        record => (
            <ActionButton
                record={record}
                items={[
                    {
                        title: t("general.tooltip.edit"),
                        onClick: onEditModalOpen,
                    },
                    {
                        title: t(
                            "account-admin-business-role-list-delete-action.title",
                        ),
                        confirmMessage: t(
                            "account-admin-business-role-list-delete-action.message",
                            {
                                name: record.name,
                            },
                        ),
                        onConfirm: record => onDeleteBusinessRole(record.id),
                        color: "red",
                    },
                ]}
            />
        ),
        [onDeleteBusinessRole],
    );

    return (
        data && (
            <>
                <TableWithPreferencesManagement
                    actionMenu={actionMenu}
                    columns={businessRoleColumns(onDrawerOpen)}
                    dataSource={data}
                    fixed
                    onChangeFilter={onDrawerClose}
                    onChangePaging={onDrawerClose}
                    restHeight={HEADER_WITH_BREADCRUMB_WITH_TAB_HEIGHT}
                    rowKey="id"
                    datasetSlicing="local"
                    preferencesType={preferencesType}
                />
                <Drawer
                    visible={drawerVisible}
                    onClose={onCancel}
                    okButtonProps={{
                        onClick: () => onDrawerSave(updatedRecord),
                        children: <T id="general.save" />,
                    }}
                    cancelButtonProps={{ onClick: onCancel }}
                    panel
                >
                    <Tabs defaultActiveKey={TABS.PERMISSIONS}>
                        <Tabs.TabPane
                            tab={<T id="general.permissions" />}
                            key={TABS.PERMISSIONS}
                        >
                            <PermissionsTable
                                onPermissionGrantedChange={
                                    onPermissionGrantedChange
                                }
                                grantedPermissions={grantedPermissions}
                            />
                        </Tabs.TabPane>
                    </Tabs>
                </Drawer>
                <BusinessRoleModal
                    visible={visibleModal}
                    onCancel={onModalCancel}
                    onOk={onModalOk}
                />
                <BusinessRoleModal
                    visible={editModalVisible}
                    onCancel={onEditModalCancel}
                    onOk={onEditModalSave}
                    role={updatedRecord}
                    okText={t("general.save")}
                    title={t(
                        "account-admin-business-role-list.modal.title-edit",
                    )}
                />
            </>
        )
    );
};

PermissionAdminSettingsComponent.propTypes = {
    data: PropTypes.array.isRequired,
    visibleModal: PropTypes.bool.isRequired,
    onModalCancel: PropTypes.func.isRequired,
    onDrawerOpen: PropTypes.func.isRequired,
    onModalOk: PropTypes.func.isRequired,
    onDeleteBusinessRole: PropTypes.func.isRequired,
};

import PropTypes from "prop-types";
import React, { useCallback } from "react";

import { GlobalPermissionsDrawer } from "@/components/Permissions/Users/GlobalPermissions/GlobalPermissionsDrawer";
import {
    useGlobalPermissions,
    useGroupGlobalPermissions,
    useSaveGroupGlobalPermissions,
} from "@/components/Permissions/Users/GlobalPermissions/loadableResources";
import { t } from "@/translations";

export function GroupGlobalPermissionsDrawerContainer({
    groupId,
    visible,
    onCancel,
    onSaved,
}) {
    const globalPermissionsResource = useGlobalPermissions();
    const groupGlobalPermissionsResource = useGroupGlobalPermissions(
        groupId,
        visible,
    );
    const onPermissionsSaved = useCallback(() => {
        groupGlobalPermissionsResource.reload();
        onSaved();
    }, [onSaved, groupGlobalPermissionsResource.reload]);
    const savePermissionsResource = useSaveGroupGlobalPermissions(
        groupId,
        onPermissionsSaved,
    );

    return (
        <GlobalPermissionsDrawer
            permissionsListResource={globalPermissionsResource}
            editablePermissionsResource={groupGlobalPermissionsResource}
            savePermissionsResource={savePermissionsResource}
            visible={visible}
            onCancel={onCancel}
            title={t("general.global-permissions")}
        />
    );
}

GroupGlobalPermissionsDrawerContainer.propTypes = {
    groupId: PropTypes.string,
    visible: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    onSaved: PropTypes.func.isRequired,
};

import {
    fieldTypes,
    HEADER_WITH_BREADCRUMB_WITH_TAB_HEIGHT,
} from "@/components/DesignSystem/Table/constants";
import { useSyncRowSelection } from "@/components/DesignSystem/Table/hooks/useSyncRowSelection.hook";
import { useDic } from "@/components/Dic/useDic.hook";
import { persissionsColumn } from "@/components/Permissions/UsersAssets/AssetsTable.columns";
import { TableButton } from "@/components/TableButton";
import { TableWithPreferencesManagement } from "@/components/TableWithPreferences/TableWithPreferencesManagement.container";
import { preferencesTypes } from "@/constants/preferencesTypes.constants";
import { useQueryLoadable } from "@/modules/loadable";
import { getData } from "@/services/utils";
import { t } from "@/translations";
import { isEmpty } from "lodash/fp";
import PropTypes from "prop-types";
import React, { useCallback, useImperativeHandle } from "react";

const preferencesType = preferencesTypes.USER_ASSETS_ASSETS_GROUPS_TABLE;
export const useFetchAssetGroupsQuery = ({ accountId, userIds }) => {
    const { permissionsAssetsService } = useDic();

    return useQueryLoadable(
        async () =>
            permissionsAssetsService
                .fetchAssetGroups({
                    accountId,
                    userIds,
                })
                .then(getData),
        [accountId, permissionsAssetsService, userIds],
    );
};
export const AssetGroupsTable = React.memo(
    React.forwardRef((props, ref) => {
        const {
            accountId,
            userIds,

            selectedAssetRoleIds,
            selectionChange,
            onAssetRoleClick,
            selectionContextMenu,
        } = props;

        const { messageService } = useDic();

        const assetGroupsQuery = useFetchAssetGroupsQuery({
            accountId,
            userIds,
        });

        const onClick = useCallback(
            record => {
                if (isEmpty(record.assets)) {
                    return messageService.warn({
                        content: t(
                            "asset-roles-table.message.warn.no-asset-in-asset-role",
                            { roleName: record.name },
                        ),
                    });
                }
                onAssetRoleClick(record);
            },
            [onAssetRoleClick, messageService],
        );

        useImperativeHandle(ref, () => ({
            reload: assetGroupsQuery.reload,
        }));

        const dataSource = assetGroupsQuery.loadable.valueMaybe() || [];

        const rowSelection = useSyncRowSelection(
            selectedAssetRoleIds,
            selectionChange,
            [dataSource],
            record => isEmpty(record?.assets),
        );

        const createColumns = useCallback(
            onClick => [
                {
                    name: "name",
                    label: t("assets-table.assetGroup.name"),
                    type: fieldTypes.TEXT,
                    render: (text, record) =>
                        !onClick ? (
                            text
                        ) : (
                            <TableButton
                                onClick={e => {
                                    e.stopPropagation();
                                    onClick(record);
                                }}
                            >
                                {text}
                            </TableButton>
                        ),
                },
                {
                    name: "description",
                    label: t("assets-table.assetGroup.description"),
                    type: fieldTypes.TEXT,
                },
                persissionsColumn,
            ],
            [],
        );

        return (
            <TableWithPreferencesManagement
                fixed
                restHeight={HEADER_WITH_BREADCRUMB_WITH_TAB_HEIGHT}
                rowKey="id"
                datasetSlicing="local"
                dataSource={dataSource}
                preferencesType={preferencesType}
                columns={createColumns(onClick)}
                defaultSort={{
                    fieldName: "name",
                    sortDir: "ascending",
                }}
                rowSelection={rowSelection}
                selectionContextMenu={selectionContextMenu}
                {...props}
            />
        );
    }),
);

AssetGroupsTable.propTypes = {
    accountId: PropTypes.number.isRequired,
    userIds: PropTypes.arrayOf(PropTypes.number).isRequired,
};

import { ButtonMenu, Modal, UnityLayout } from "@/components/DesignSystem";
import { EditForm } from "@/components/Permissions/AccountAssetAdminSettings/EditForm.component";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

const INIT_STATE = {
    name: undefined,
    description: undefined,
    assets: [],
};

export const AssetRoleModal = ({
    visible,
    onOk,
    onCancel,
    role,
    okText,
    title,
}) => {
    const [assetRole, setAssetRole] = useState(INIT_STATE);

    useEffect(() => {
        setAssetRole(role || INIT_STATE);
    }, [visible]);

    const onUpdateAssetRole = (value, fieldName) =>
        setAssetRole({ ...assetRole, [fieldName]: value });

    const onModalSave = () => onOk(assetRole);

    return (
        <Modal visible={visible} onClose={onCancel}>
            <UnityLayout>
                <UnityLayout.Header
                    size={3}
                    title={
                        title || t("account-admin-asset-role-list.modal.title")
                    }
                />
                <UnityLayout.Content padding={[false, true]}>
                    <EditForm
                        onUpdateRole={onUpdateAssetRole}
                        role={assetRole}
                        nameLabel={t(
                            "account-admin-asset-role-list.modal.label-name",
                        )}
                        descriptionLabel={t(
                            "account-admin-asset-role-list.modal.label-description",
                        )}
                    />
                </UnityLayout.Content>
                <UnityLayout.Footer
                    actionButtons={
                        <ButtonMenu
                            buttons={[
                                {
                                    label: okText || t("general.create"),
                                    onClick: onModalSave,
                                    type: "primary",
                                    disabled: !assetRole.name,
                                },
                                {
                                    label: t("general.cancel"),
                                    onClick: onCancel,
                                    type: "text",
                                },
                            ]}
                        />
                    }
                />
            </UnityLayout>
        </Modal>
    );
};

AssetRoleModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    onOk: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    role: PropTypes.object,
    okText: PropTypes.node,
    title: PropTypes.string,
};

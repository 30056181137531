import { FormulaTab } from "@/components/CalculatedFieldTable/FormulaTab/FormulaTab.component";
import { Gap } from "@/components/DesignSystem/Gap";
import { Tabs } from "@/components/DesignSystem/Tabs/Tabs";
import { t } from "@/translations";
import pick from "lodash/fp/pick";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useReducer } from "react";
import { InputType } from "../Packages/PackageTableDefinitionPanel/constants";
import { ComposedTab } from "./ComposedTab/ComposedTab.component";
import { conformFormulaToMapping } from "./conformFormulaToMapping";
import { TABS_BY_INPUT_TYPE, TABS_VALUE_TYPES } from "./constants";
import { ExpertTab } from "./ExpertTab/ExpertTab.component";
import { FieldTab } from "./FieldTab/FieldTab.component";
import { calculate, calculateScope } from "./FormulaTab/FormulaTab.utils";
import { GroovyTab } from "./GroovyTab/GroovyTab.component";
import { useVisibleTabs } from "./useVisibleTabs";

const { TabPane } = Tabs;

const formulaDerivedState = ({ input, formulaMapping }) => {
    const scope = calculateScope(formulaMapping);
    const [result, error] = calculate(input, scope);
    return { input, result, error, formulaMapping };
};

export const initFormula = ({
    input,
    formulaMapping,
    previousFormulaMapping,
}) => {
    const conformed = conformFormulaToMapping({
        input,
        formulaMapping,
        previousFormulaMapping,
    });

    return {
        input: conformed.input,
        error: conformed.error,
        formulaMapping: conformed.formulaMapping,
        missingMapping: conformed.missingMapping,
        previousMapping: conformed.previousMapping,
    };
};

export const validateComposed = (input, stringFields) =>
    input
        ?.filter(fieldName => !stringFields?.includes(fieldName))
        ?.map(missing => `Missing field ${missing}`)
        ?.join(". ");

const initComposed = ({ input, stringFields }) => {
    const error = validateComposed(input, stringFields);
    return {
        error,
    };
};

const init = ({ visibleTabs, initialValues, numberFields, stringFields }) => {
    const tab =
        TABS_BY_INPUT_TYPE[initialValues?.inputType] || TABS_VALUE_TYPES.FIELD;

    if (!initialValues || !visibleTabs.includes(tab))
        return {
            activeTab: visibleTabs[0],
            stateByTabs: {},
        };

    const tabState = {
        ...pick(
            [
                "input",
                "inputType",
                "converterExpression",
                "converterExpressionReadOnly",
            ],
            initialValues,
        ),
        ...(initialValues.inputType === InputType.FORMULA
            ? initFormula({
                  input: initialValues.input,
                  previousFormulaMapping: initialValues.formulaMapping,
                  formulaMapping: numberFields,
              })
            : {}),
        ...(initialValues.inputType === InputType.COMPOSED
            ? initComposed({ input: initialValues.input, stringFields })
            : {}),
    };

    return {
        activeTab: tab,
        stateByTabs: {
            [tab]: tabState,
        },
    };
};

const reducer = (state, action) => {
    switch (action.type) {
        case "init":
            return init(action.payload);

        case "set_active_tab":
            return { ...state, activeTab: action.payload };

        case "set_tab_state": {
            const { tab } = action.payload;
            return {
                ...state,
                activeTab: tab,
                stateByTabs: {
                    ...state.stateByTabs,
                    [tab]: action.payload,
                },
            };
        }

        case "set_formula_tab_state": {
            const tab = TABS_VALUE_TYPES.FORMULA;
            const { input, formulaMapping } = action.payload;

            return {
                ...state,
                activeTab: tab,
                stateByTabs: {
                    ...state.stateByTabs,
                    [tab]: {
                        inputType: InputType.FORMULA,
                        ...formulaDerivedState({ input, formulaMapping }),
                    },
                },
            };
        }

        case "set_active_tab_converter_state": // todo
            return {
                ...state,
                stateByTabs: {
                    ...state.stateByTabs,
                    [state.activeTab]: {
                        ...state.stateByTabs[state.activeTab],
                        converterExpression: action.payload.converterExpression,
                        converterExpressionReadOnly:
                            action.payload.converterExpressionReadOnly,
                    },
                },
            };

        default:
            throw new Error("Unknown action type:" + action.type);
    }
};

export const useFieldCustomizationTabsState = ({
    hideTabs,
    initialValues,
    inputOptions,
    numberFields,
    stringFields,
}) => {
    const visibleTabs = useVisibleTabs({
        numberFields,
        stringFields,
        inputOptions,
        initialValues,
        hideTabs,
    });
    const [state, dispatch] = useReducer(
        reducer,
        { visibleTabs, initialValues, numberFields, stringFields },
        init,
    );
    useEffect(() => {
        if (initialValues)
            dispatch({
                type: "init",
                payload: {
                    visibleTabs,
                    initialValues,
                    numberFields,
                    stringFields,
                },
            });
    }, [visibleTabs, initialValues, numberFields, stringFields]);

    const handleChange = useCallback(
        ({
            input,
            inputType,
            tab,
            error,
            isValid = !error,
            converterState, // TODO
        }) => {
            dispatch({
                type: "set_tab_state",
                payload: {
                    input,
                    inputType,
                    tab,
                    isValid,
                    error,
                    converterExpression: converterState?.value,
                    converterExpressionReadOnly: converterState?.readOnly,
                },
            });
        },
        [],
    );

    const onFormulaChange = useCallback(({ input, formulaMapping }) => {
        dispatch({
            type: "set_formula_tab_state",
            payload: {
                input,
                formulaMapping,
            },
        });
    }, []);

    const setActiveTab = useCallback(activeTab => {
        dispatch({ type: "set_active_tab", payload: activeTab });
    }, []);

    const activeTabState = useMemo(
        () => state.stateByTabs?.[state.activeTab] ?? {},
        [state],
    );

    const setActiveTabConverterState = useCallback(
        converterState =>
            dispatch({
                type: "set_active_tab_converter_state",
                payload: {
                    converterExpression: converterState?.value,
                    converterExpressionReadOnly: converterState?.readOnly,
                },
            }),
        [],
    );

    return {
        activeTabState,
        setActiveTabConverterState,

        stateByTabs: state.stateByTabs,
        onFormulaChange,
        handleChange,
        activeTab: state.activeTab,
        setActiveTab,
        visibleTabs,
        inputOptions,
        numberFields,
        stringFields,
    };
};

export const FieldCustomizationTabsComponent = ({
    activeTabState,
    numberFields,
    stringFields,
    stateByTabs,
    inputOptions = [],
    handleChange,
    onFormulaChange,
    activeTab,
    setActiveTab,
    visibleTabs,
    isPreviewDisabled = false,
}) => {
    if (!visibleTabs.length) return null;
    if (activeTab && !visibleTabs.includes(activeTab)) {
        console.error(`State mismatch, active tab is not visible`, {
            activeTab,
            visibleTabs,
            numberFields,
            stringFields,
            stateByTabs,
        });
        activeTab = undefined;
    }

    return (
        <Tabs activeKey={activeTab} onChange={setActiveTab} fullSize={false}>
            {visibleTabs.includes(TABS_VALUE_TYPES.FIELD) && (
                <TabPane
                    tab={t("formula-editor.tab.converter")}
                    key={TABS_VALUE_TYPES.FIELD}
                >
                    <Gap size="small" />
                    <FieldTab
                        input={stateByTabs[TABS_VALUE_TYPES.FIELD]?.input}
                        inputOptions={inputOptions}
                        onChange={handleChange}
                        converterState={{
                            value: stateByTabs[TABS_VALUE_TYPES.FIELD]
                                ?.converterExpression,
                            readOnly:
                                stateByTabs[TABS_VALUE_TYPES.FIELD]
                                    ?.converterExpressionReadOnly,
                        }}
                    />
                </TabPane>
            )}
            {visibleTabs.includes(TABS_VALUE_TYPES.FORMULA) && (
                <TabPane
                    tab={t("formula-editor.tab.formula")}
                    key={TABS_VALUE_TYPES.FORMULA}
                >
                    <Gap size="small" />
                    <FormulaTab
                        activeTabState={activeTabState}
                        input={stateByTabs[TABS_VALUE_TYPES.FORMULA]?.input}
                        formula={stateByTabs[TABS_VALUE_TYPES.FORMULA]?.input}
                        formulaMapping={
                            stateByTabs[TABS_VALUE_TYPES.FORMULA]
                                ?.formulaMapping || numberFields
                        }
                        onChange={onFormulaChange}
                        isPreviewDisabled={isPreviewDisabled}
                    />
                </TabPane>
            )}
            {visibleTabs.includes(TABS_VALUE_TYPES.COMPOSED) && (
                <TabPane
                    tab={t("formula-editor.tab.multiple-field")}
                    key={TABS_VALUE_TYPES.COMPOSED}
                >
                    <Gap size="small" />
                    <ComposedTab
                        input={stateByTabs[TABS_VALUE_TYPES.COMPOSED]?.input}
                        error={stateByTabs[TABS_VALUE_TYPES.COMPOSED]?.error}
                        data={stringFields}
                        onChange={handleChange}
                    />
                </TabPane>
            )}
            {visibleTabs.includes(TABS_VALUE_TYPES.GROOVY) && (
                <TabPane
                    tab={t("formula-editor.tab.groovy")}
                    key={TABS_VALUE_TYPES.GROOVY}
                    data-test="groovy-tab"
                >
                    <Gap size="small" />
                    <GroovyTab
                        input={stateByTabs[TABS_VALUE_TYPES.GROOVY]?.input}
                        onChange={handleChange}
                    />
                </TabPane>
            )}
            {visibleTabs.includes(TABS_VALUE_TYPES.EXPERT_OPTION) && (
                <TabPane
                    tab={t("formula-editor.tab.expert-options")}
                    key={TABS_VALUE_TYPES.EXPERT_OPTION}
                    data-test={`field-customization-tab-${TABS_VALUE_TYPES.EXPERT_OPTION}`}
                >
                    <Gap size="small" />
                    <ExpertTab
                        input={
                            stateByTabs[TABS_VALUE_TYPES.EXPERT_OPTION]?.input
                        }
                        inputType={
                            stateByTabs[TABS_VALUE_TYPES.EXPERT_OPTION]
                                ?.inputType
                        }
                        onChange={handleChange}
                    />
                </TabPane>
            )}
        </Tabs>
    );
};

FieldCustomizationTabsComponent.propTypes = {
    inputOptions: PropTypes.array,
    numberFields: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string,
            sourceField: PropTypes.string,
            example: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }),
    ),
    stringFields: PropTypes.arrayOf(PropTypes.string),
    handleChange: PropTypes.func.isRequired,
    onFormulaChange: PropTypes.func.isRequired,
    stateByTabs: PropTypes.object.isRequired,
    activeTab: PropTypes.oneOf(Object.values(TABS_VALUE_TYPES)),
    setActiveTab: PropTypes.func.isRequired,
    visibleTabs: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
};

import FormattedDateTime from "@/components/DateTime/FormattedDateTime";
import { SwitchAsync } from "@/components/DesignSystem";
import { ActionButton } from "@/components/DesignSystem/Table/components/ActionButton/ActionButton";
import {
    HEADER_HEIGHT_WITH_BREADCRUMB,
    fieldTypes,
} from "@/components/DesignSystem/Table/constants";
import { useDic } from "@/components/Dic/useDic.hook";
import {
    EO_LOGS_ENTITY_TYPE,
    EO_LOGS_TABS,
} from "@/components/EventLogs/EventLogs.page";
import {
    REPEAT_OPTIONS,
    getTimingName,
} from "@/components/EventSchedulers/TimingFields";
import {
    useChangeEventSchedulerStatusMutation,
    useDeleteEventSchedulerMutation,
    useEventSchedulersQuery,
} from "@/components/EventSchedulers/loadables";
import { useHasEditPermission } from "@/components/EventWorkflows/useHasEditPermission.hook";
import { useHideBackButton } from "@/components/PageLayout/useHideBackButton.hook";
import { useRefreshAction } from "@/components/PageLayout/useRefreshAction.hook";
import { TableWithPreferencesManagement } from "@/components/TableWithPreferences/TableWithPreferencesManagement.container";
import { useBreadcrumbButtons } from "@/components/hooks/useBreadcrumbButtons.hook";
import { useFullWidthTableLayout } from "@/components/hooks/useContentLayout.hook";
import { preferencesTypes } from "@/constants/preferencesTypes.constants";
import { getEventSchedulerTrackName as getTrackName } from "@/mixpanel/buttonNames";
import { isLoading } from "@/modules/loadable";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import { EO_EDIT_PERMISSION } from "@/security/permission.utils";
import { t } from "@/translations";
import { flatten } from "flat";
import { always, eq, map, mergeWith, pipe, prop, reduce } from "lodash/fp";
import moment from "moment-timezone";
import React, { useCallback, useContext, useMemo, useReducer } from "react";
import { AlertCellWithPopover } from "../AlertCellWithPopover/AlertCellWithPopover";
import { ApprovalWorkflowsEnabledContext } from "../WorkFlow/ApprovalWorkflowsEnabledProvider";

const addAlert = (
    row,
    {
        prop,
        getVisible = always(false),
        getType = always("YELLOW"),
        getTooltip = always(undefined),
    } = {},
) => {
    if (prop) {
        const isVisible = getVisible(row);
        const newAlert = isVisible
            ? {
                  [prop]: getTooltip(row),
                  [`${prop}_ALERT_TYPE`]: getType(row),
              }
            : {
                  // when visibility change, alerts need to be reset
                  [prop]: undefined,
                  [`${prop}_ALERT_TYPE`]: undefined,
              };

        return {
            ...row,
            alerts: mergeWith(
                (prevValue, newValue) => {
                    return newValue || prevValue; // last alert in array wins
                    // return prevValue || newValue; // first alert in array wins
                },
                row.alerts,
                newAlert,
            ),
        };
    } else return row;
};

const addAlerts =
    (alertDefs = []) =>
    row =>
        alertDefs.reduce(addAlert, row);

export const addLocalFilteredAlerts = alertDefs => map(addAlerts(alertDefs));

export const countAlerts = dataSourceWithAlerts => {
    const { rowsWithWarningCount, rowsWithErrorCount } = pipe(
        map(({ alerts = {} }) => {
            const visibleRowAlertTypes = Object.entries(alerts)
                .map(([k, v]) => (k.endsWith("_ALERT_TYPE") ? v : null))
                .filter(Boolean);

            return {
                rowWarningsCount:
                    visibleRowAlertTypes.filter(eq("YELLOW")).length || 0,
                rowErrorsCount:
                    visibleRowAlertTypes.filter(eq("RED")).length || 0,
            };
        }),
        reduce(
            (acc, item) => ({
                rowsWithWarningCount:
                    acc.rowsWithWarningCount + (item.rowWarningsCount ? 1 : 0),
                rowsWithErrorCount:
                    acc.rowsWithErrorCount + (item.rowErrorsCount ? 1 : 0),
            }),
            { rowsWithWarningCount: 0, rowsWithErrorCount: 0 },
        ),
    )(dataSourceWithAlerts);

    return { rowsWithErrorCount, rowsWithWarningCount };
};

export const createColumns = ({
    accountId,
    canEdit,
    approvalWorkflowEnabled,
    reloadAll,
} = {}) => [
    {
        type: fieldTypes.TEXT,
        label: t("event-scheduler.list.columns.name"),
        name: "name",
    },
    {
        type: fieldTypes.TEXT,
        label: t("event-scheduler.list.columns.destinationName"),
        name: "destinationName",
    },
    {
        type: fieldTypes.TEXT,
        label: t("event-scheduler.list.columns.destinationTargetLabel"),
        name: "destinationTargetLabel",
        // name: ActionFields.fieldNames.destinationTargetId,
    },
    {
        type: fieldTypes.OPTION,
        options: [
            { label: "Active", value: true },
            { label: "Inactive", value: false },
        ],
        label: t("event-scheduler.list.columns.enabled"),
        name: "enabled",
        render: (enabled, record) => {
            return (
                <SwitchAsync
                    initialValue={enabled}
                    useMutation={useChangeEventSchedulerStatusMutation}
                    from={({ checked }) => ({
                        accountId,
                        schedulerId: record.id,
                        enabled: checked,
                    })}
                    hookParams={{
                        afterSuccess: approvalWorkflowEnabled
                            ? reloadAll
                            : undefined,
                    }}
                    isWorkflowApprovalMaybe={approvalWorkflowEnabled}
                    textOn={t("general.active")}
                    textOff={t("general.inactive")}
                    disabled={
                        !canEdit ||
                        !record.canEdit ||
                        record.approvalWorkflowRunning
                    }
                />
            );
        },
    },
    {
        type: fieldTypes.DATETIME,
        label: t("event-scheduler.list.columns.startTime"),
        name: getTimingName("startTime"),
        render: utcStr => <FormattedDateTime utcStr={utcStr} />,
    },
    {
        type: fieldTypes.OPTION,
        options: REPEAT_OPTIONS,
        label: t("event-scheduler.list.columns.type"),
        name: getTimingName("type"),
    },
    {
        type: fieldTypes.DATETIME,
        label: t("event-scheduler.list.columns.endTime"),
        name: getTimingName("endTime"),
        render: utcStr => <FormattedDateTime utcStr={utcStr} />,
    },
    {
        type: fieldTypes.DATETIME,
        label: t("event-scheduler.list.columns.lastExecution"),
        name: "lastExecution",
        render: utcStr => <FormattedDateTime utcStr={utcStr} />,
    },
    {
        type: fieldTypes.DATETIME,
        label: t("event-scheduler.list.columns.nextExecution"),
        name: "nextExecution",
        render: utcStr => <FormattedDateTime utcStr={utcStr} />,
    },
    {
        type: fieldTypes.DATETIME,
        label: t("event-scheduler.list.columns.createdAt"),
        name: "createdAt",
        visible: false,
        render: utcStr => <FormattedDateTime utcStr={utcStr} />,
    },
    {
        type: fieldTypes.TEXT,
        label: t("event-scheduler.list.columns.createdBy"),
        name: "createdBy",
        visible: false,
    },
    {
        type: fieldTypes.DATETIME,
        label: t("event-scheduler.list.columns.updatedAt"),
        name: "updatedAt",
        visible: false,
        render: utcStr => <FormattedDateTime utcStr={utcStr} />,
    },
    {
        type: fieldTypes.TEXT,
        label: t("event-scheduler.list.columns.updatedBy"),
        name: "updatedBy",
        visible: false,
    },
    {
        type: fieldTypes.TEXT,
        label: t("general.notes"),
        name: "notes",
        width: 108,
        canFilter: false,
        // canHide: false,
        render: (_notes, record) => (
            <>
                {record.approvalWorkflowRunning && (
                    <AlertCellWithPopover
                        popoverTitle={t(
                            "general.workflow-approval-pending.title",
                        )}
                        popoverContent={
                            <>
                                {t(
                                    "general.workflow-approval-pending.description",
                                    {
                                        entity: t("general.scheduler"),
                                    },
                                )}
                            </>
                        }
                        content={t("general.workflow-approval-pending.title")}
                    />
                )}
            </>
        ),
    },
];

const preferencesType = preferencesTypes.EVENT_SCHEDULER_LIST_TABLE;
const zonedToMaybeUtcISO = (date, timezone) =>
    date ? moment.tz(date, timezone).toISOString() : null;

const EDIT_PERMISSIONS = [EO_EDIT_PERMISSION];

export const EventSchedulersPage = () => {
    const { accountId } = useAccountAppParams();
    const [reloadToken, reloadAll] = useReducer(s => ++s, 0);

    const approvalWorkflowEnabled = useContext(
        ApprovalWorkflowsEnabledContext,
    ).EVENT_ORCHESTRATION_APPROVAL;

    useFullWidthTableLayout();
    useRefreshAction(reloadAll);
    useHideBackButton();

    const {
        locationRouterService,
        accountAppLocations: {
            eventSchedulerNewLocation,
            eventSchedulerEditLocation,
            eventSchedulerShowLocation,
            eventLogsLocation,
        },
    } = useDic();
    const canEdit = useHasEditPermission({
        permissions: EDIT_PERMISSIONS,
    });

    const columns = useMemo(
        () =>
            createColumns({
                accountId,
                canEdit,
                approvalWorkflowEnabled,
                reloadAll,
            }),
        [accountId, approvalWorkflowEnabled, canEdit],
    );

    useBreadcrumbButtons(
        () => [
            {
                permissions: EDIT_PERMISSIONS,
                label: "location.accountApp.eventOrchestration.eventSchedulers.create",
                onClick: () =>
                    locationRouterService.navigate(eventSchedulerNewLocation),
                track: { name: getTrackName("New") },
            },
        ],
        [eventSchedulerNewLocation, locationRouterService],
    );

    const onEdit = useCallback(
        record =>
            locationRouterService.navigate(eventSchedulerEditLocation, {
                schedulerId: record?.id,
            }),
        [eventSchedulerEditLocation, locationRouterService],
    );
    const onShowDefinition = useCallback(
        record =>
            locationRouterService.navigate(eventSchedulerShowLocation, {
                schedulerId: record?.id,
            }),
        [eventSchedulerShowLocation, locationRouterService],
    );
    const onShowRunHistory = useCallback(
        ({ id }) =>
            locationRouterService.navigate(eventLogsLocation, {
                tab: EO_LOGS_TABS.RUN_HISTORY,
                entityType: EO_LOGS_ENTITY_TYPE.SCHEDULER,
                entityId: id,
            }),
        [eventLogsLocation, locationRouterService],
    );

    const listQuery = useEventSchedulersQuery({
        accountId,
        reloadToken,
    });
    const deleteMutation = useDeleteEventSchedulerMutation({
        accountId,
        afterSuccess: reloadAll,
    });
    const dataSource = useMemo(
        () =>
            pipe(
                map(item => {
                    const flat = flatten(item, { maxDepth: 2 });
                    return {
                        ...flat,
                        [getTimingName("startTime")]: zonedToMaybeUtcISO(
                            flat[getTimingName("startTime")] ||
                                flat[getTimingName("eventTime")],
                            flat[getTimingName("eventTimeZone")],
                        ),
                        [getTimingName("endTime")]: zonedToMaybeUtcISO(
                            flat[getTimingName("endTime")],
                            flat[getTimingName("eventTimeZone")],
                        ),
                        nextExecution: zonedToMaybeUtcISO(
                            flat.nextExecution,
                            flat[getTimingName("eventTimeZone")],
                        ),
                    };
                }),
                addLocalFilteredAlerts([
                    {
                        prop: "destinationName",
                        getVisible: ({ destinationExists }) =>
                            !destinationExists,
                        getType: always("YELLOW"),
                        getTooltip: ({ destinationName }) =>
                            t("general.does-not-exist", {
                                item: destinationName,
                            }),
                    },
                    {
                        prop: "notes",
                        getVisible: prop("approvalWorkflowRunning"),
                        getType: always("YELLOW"),
                        getTooltip: always("Approval workflow is running"),
                    },
                ]),
            )(listQuery.loadable.valueMaybe() || []),
        [listQuery.loadable],
    );
    const loading = isLoading(listQuery);

    const actionMenu = useCallback(
        record => (
            <ActionButton
                record={record}
                items={[
                    {
                        visible:
                            canEdit &&
                            record.canEdit &&
                            !record.approvalWorkflowRunning,
                        title: t("general.tooltip.edit"),
                        onClick: onEdit,
                        track: { name: getTrackName("Edit") },
                    },
                    {
                        title: t("general.show-definition"),
                        onClick: onShowDefinition,
                        track: { name: getTrackName("ShowDefinition") },
                    },
                    {
                        title: t("general.show-run-history"),
                        onClick: onShowRunHistory,
                        track: { name: getTrackName("ShowRunHistory") },
                    },
                    {
                        visible: canEdit && record.canEdit,
                        title: t("general.modal.delete.title", {
                            type: t("general.scheduler"),
                        }),
                        confirm: {
                            message: `${t("general.modal.delete.message", {
                                name: record.name,
                                type: t("general.scheduler"),
                            })}`,
                            onConfirm: record =>
                                deleteMutation.mutate({
                                    schedulerId: record.id,
                                }),
                        },
                        color: "red",
                        track: { name: getTrackName("Delete") },
                    },
                ]}
            />
        ),
        [canEdit, deleteMutation, onEdit, onShowDefinition, onShowRunHistory],
    );

    return (
        <TableWithPreferencesManagement
            actionMenu={actionMenu}
            columns={columns}
            loading={loading}
            dataSource={dataSource}
            fixed
            restHeight={HEADER_HEIGHT_WITH_BREADCRUMB}
            rowKey="id"
            datasetSlicing="local"
            preferencesType={preferencesType}
            exportUrl={`/api/accounts/${accountId}/event-schedulers/export`}
        />
    );
};

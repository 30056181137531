import {
    DRAWER_FOOTER_HEIGHT,
    DRAWER_HEADER_HEIGHT,
    HEADER_WITH_PAGE_TITLE,
    TAB_HEIGHT,
} from "@/components/DesignSystem/Table/constants";
import { UserPermissionTable } from "@/components/Permissions/AccountUserAdminSettings/PermissionTable/UserPermissionTable.component";
import { useGroupedPermissionsResource } from "@/components/Permissions/AccountUserAdminSettings/RightsDrawer/loadables";
import { LoadableRenderer } from "@/modules/loadable";
import React from "react";

export const PermissionsTable = props => {
    const grouppedPermissionsResource = useGroupedPermissionsResource();

    return (
        <LoadableRenderer
            loadable={grouppedPermissionsResource.loadable}
            hasValue={groupedPermissions => (
                <UserPermissionTable
                    {...props}
                    fixed
                    restHeight={
                        HEADER_WITH_PAGE_TITLE +
                        DRAWER_HEADER_HEIGHT +
                        TAB_HEIGHT +
                        DRAWER_FOOTER_HEIGHT
                    }
                    groupedPermissions={groupedPermissions}
                />
            )}
        />
    );
};

import { Drawer, Tabs } from "@/components/DesignSystem";
import {
    DRAWER_FOOTER_HEIGHT,
    DRAWER_HEADER_HEIGHT,
    HEADER_WITH_PAGE_TITLE,
    TAB_HEIGHT,
} from "@/components/DesignSystem/Table/constants";
import {
    enhanceRoleWithPermissions,
    getGrantedPermissionsSet,
} from "@/components/Permissions/AccountPermissionAdminSettings/PermissionAdminSettings.container";
import { PermissionsTable } from "@/components/Permissions/AccountUserAdminSettings/PermissionTable/PermissionsTable.container";
import { T } from "@/translations";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useState } from "react";

const symmetricDifference = (setA, setB) => {
    let difference = new Set(setA);
    for (let elem of setB) {
        if (difference.has(elem)) difference.delete(elem);
        else difference.add(elem);
    }
    return difference;
};

const TABS = {
    PERMISSIONS: "permissions",
};

export const BusinessRolePermissionsDrawer = ({
    visible,
    businessRole,
    onSave,
    onClose,
    readOnly = false,
}) => {
    const brPermissions = useMemo(
        () => (!visible ? new Set() : getGrantedPermissionsSet(businessRole)),
        [visible, businessRole],
    );
    const [grantedPermissions, setGrantedPermissions] = useState(brPermissions);
    useEffect(() => {
        setGrantedPermissions(brPermissions);
    }, [brPermissions]);

    const noChanges = useMemo(
        () => symmetricDifference(brPermissions, grantedPermissions).size === 0,
        [brPermissions, grantedPermissions],
    );

    const onSaveClick = useCallback(() => {
        const newRole = enhanceRoleWithPermissions(
            businessRole,
            grantedPermissions,
        );
        onSave(newRole);
    }, [businessRole, grantedPermissions, onSave]);

    const onPermissionGrantedChange = useCallback(
        (permission, isGranted) => {
            let set = new Set(grantedPermissions);
            if (isGranted) set.add(permission.name);
            else set.delete(permission.name);

            setGrantedPermissions(set);
        },
        [grantedPermissions],
    );

    return (
        <Drawer
            visible={visible}
            onClose={onClose}
            okButtonProps={{
                onClick: onSaveClick,
                children: <T id="general.save" />,
                disabled: noChanges || readOnly,
            }}
            cancelButtonProps={{ onClick: onClose }}
            panel
        >
            <Tabs defaultActiveKey={TABS.PERMISSIONS}>
                <Tabs.TabPane
                    tab={<T id="general.permissions" />}
                    key={TABS.PERMISSIONS}
                >
                    <PermissionsTable
                        onPermissionGrantedChange={onPermissionGrantedChange}
                        grantedPermissions={grantedPermissions}
                        readOnly={readOnly}
                        fixed
                        restHeight={
                            HEADER_WITH_PAGE_TITLE +
                            DRAWER_HEADER_HEIGHT +
                            TAB_HEIGHT +
                            DRAWER_FOOTER_HEIGHT
                        }
                    />
                </Tabs.TabPane>
            </Tabs>
        </Drawer>
    );
};

BusinessRolePermissionsDrawer.propTypes = {
    visible: PropTypes.bool,
    readOnly: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    businessRole: PropTypes.shape({
        id: PropTypes.any,
        name: PropTypes.string,
        description: PropTypes.string,
        permissions: PropTypes.arrayOf(
            PropTypes.shape({ name: PropTypes.string.isRequired }).isRequired,
        ).isRequired,
    }),
};
